import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core';
import {
  AdditionalResultCards,
  CustomBreakPoints,
  DraftJsBlock,
  DraftJsBlockType,
  DraftJsEntityMap,
  Result,
  ResultPanelProps,
  ResultResourceItemProps,
  ResultType,
  Task,
} from '../../../common/types';
import ResultPanelItemHeader from './result-panel-item-header';
import ResultPanelItemDefault from './result-panel-item-default';
import ResultsPanelItemList from './result-panel-item-list';
import { ResultPanelCodeBlock } from './result-panel-code-block';
import ResultVideo from './result-video';
import ResultResources from './result-resources';
import TaskCard from '../../../components/UI/cards/task-card';
import {
  isImageBlock,
  isKeyBlock,
  isResourceBlock,
  isTaskBlock,
  isVideoBlock,
  isVideoExternalBlock,
} from '../../../common/draftJsUtils';
import ResultImage from './result-image';
import { ResultPanelHeader } from './result-panel-header';
import ResultResourceItem from './result-resoure-item';

const useStyles = makeStyles((theme) => ({
  resultPanelDef: {
    padding: '1.5rem 2.5rem 3.5rem 2.5rem',
    // height: '100%',
    fontSize: '1rem',
    color: '#717171',
    position: 'relative',
  },
  resultPanelResults: {
    height: '100%',
    maxWidth: '75rem',
    textAlign: 'left',
    display: 'flex',
    color: '#717171',
    fontSize: '1.05rem',
    padding: '1.5rem 0 3.5rem 0',
    width: '1200px',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      width: '100%',
      paddingTop: '35px',
      flexDirection: 'column',
    },
  },

  resultPanelContentLeft: {
    width: '800px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up(CustomBreakPoints.mobile)]: {
      marginRight: '40px',
    },
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      width: '100%',
      alignItems: 'center',
    },
  },
  resultPanelContentRight: {
    width: '360px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up(CustomBreakPoints.mobile)]: {
      position: 'sticky',
      top: '24px',
      alignSelf: 'flex-start',
    },
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      width: '100%',
      alignItems: 'center',
      marginTop: '1rem',
      borderTop: '10px solid #f1f1f1',
    },
  },
  resultPanelContentLeftContent: {
    paddingLeft: '2rem',
  },
  articleBreadCrumb: {
    fontSize: '.9rem',
    color: '#0095FF',
    lineHeight: '22px',
    display: 'table-cell',
  },
  articleCompletionTime: {
    paddingTop: '13px',
    fontSize: '1rem',
    color: '#2E2E2E',
    fontWeight: 'bold',
    lineHeight: '22px',
  },
  resultCard: {
    flexDirection: 'column',
    [theme.breakpoints.up(CustomBreakPoints.mobile)]: {
      padding: '2.5rem 4.5rem 3rem 4.5rem',
      border: '1px solid #CBCBCB',
      borderRadius: '5px',
      display: 'inline-flex',
      backgroundColor: '#fbfbfb',
    },
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      backgroundColor: '#fff',
      width: '90%',
      display: 'flex',
    },
  },
}));

const combineBlocks = (
  blocks: DraftJsBlock[]
): (DraftJsBlock | DraftJsBlock[])[] => {
  return blocks.reduce((combined, cur) => {
    if (
      cur.type === DraftJsBlockType.ORDERED_LIST ||
      cur.type === DraftJsBlockType.UNORDERED_LIST
    ) {
      const prev = combined[combined.length - 1];
      if (Array.isArray(prev)) {
        prev.push(cur);
      } else {
        combined.push([cur]);
      }
    } else {
      combined.push(cur);
    }
    return combined;
  }, [] as (DraftJsBlock | DraftJsBlock[])[]);
};

const getTaskBlock = (
  block: DraftJsBlock,
  additionalCards: AdditionalResultCards | undefined
) => {
  const task = additionalCards
    ? additionalCards[block.entityRanges[0].key]
    : null;
  if (task) {
    return (
      <div style={{ paddingBottom: '1.1rem' }} key={block.key}>
        <TaskCard task={task as Task} type={ResultType.TASK} small />
      </div>
    );
  }
  return null;
};

const getResourceBlock = (
  block: DraftJsBlock,
  additionalCards: AdditionalResultCards | undefined
) => {
  const resource = additionalCards
    ? additionalCards[block.entityRanges[0].key]
    : null;
  if (resource) {
    return (
      <div style={{ paddingBottom: '1.1rem' }} key={block.key}>
        <ResultResourceItem
          key={block.key}
          {...(resource as ResultResourceItemProps)}
        />
      </div>
    );
  }
  return null;
};

const getKeyBlockElement = (
  block: DraftJsBlock,
  entityMap: DraftJsEntityMap,
  video: string[],
  additionalCards: AdditionalResultCards | undefined
) => {
  if (isVideoBlock(block.text)) {
    return video && video.length > 0 ? <ResultVideo video={video[0]} /> : null;
  } else if (isTaskBlock(block.text)) {
    return getTaskBlock(block, additionalCards);
  } else if (isResourceBlock(block.text)) {
    return getResourceBlock(block, additionalCards);
  } else if (isImageBlock(block.text)) {
    return (
      <ResultImage
        key={block.key}
        video={entityMap[block.entityRanges[0].key].data.url}
      />
    );
  } else if (isVideoExternalBlock(block.text)) {
    return (
      <ResultVideo
        key={block.key}
        videoExternal={entityMap[block.entityRanges[0].key].data.url}
      />
    );
  }
  return null;
};

const getItemOrKeyBlock = (
  block: DraftJsBlock,
  entityMap: DraftJsEntityMap,
  video: string[],
  additionalCards: AdditionalResultCards | undefined
) => {
  if (isKeyBlock(block.text)) {
    return getKeyBlockElement(block, entityMap, video, additionalCards);
  }
  return (
    <ResultPanelItemDefault
      key={block.key}
      block={block}
      entityMap={entityMap}
    />
  );
};

const resultPanelItemBuilder = (
  block: DraftJsBlock | DraftJsBlock[],
  result: Result,
  additionalCards: AdditionalResultCards | undefined
) => {
  const entityMap = result.layout.entityMap;

  if (Array.isArray(block)) {
    return (
      <ResultsPanelItemList
        key={`${block[0].key}root`}
        blocks={block}
        entityMap={entityMap}
      />
    );
  } else if (
    block.type === DraftJsBlockType.H1 ||
    block.type === DraftJsBlockType.H2 ||
    block.type === DraftJsBlockType.H3 ||
    block.type === DraftJsBlockType.H4 ||
    block.type === DraftJsBlockType.H5 ||
    block.type === DraftJsBlockType.H6
  ) {
    return (
      <ResultPanelItemHeader
        key={block.key}
        block={block}
        entityMap={entityMap}
      />
    );
  } else if (block.type === DraftJsBlockType.CODE_BLOCK) {
    return (
      <ResultPanelCodeBlock
        key={block.key}
        block={block}
        entityMap={entityMap}
      />
    );
  } else if (
    block.type === DraftJsBlockType.UNSTYLED &&
    block.text &&
    block.text.trim() != ''
  ) {
    return getItemOrKeyBlock(block, entityMap, result.videos, additionalCards);
  }
  return null;
};

const buildExternalLink = (text: string, url: string) => {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      {text}
    </a>
  );
};

const ResultPanel: FunctionComponent<ResultPanelProps> = ({
  result,
  hideRightPanel,
  additionalCards,
}) => {
  const classes = useStyles();
  const blocks = [...result.layout.blocks];

  const combinedBlocks = combineBlocks(blocks).map((b) =>
    resultPanelItemBuilder(b, result, additionalCards)
  );

  if (hideRightPanel) {
    return <div className={classes.resultPanelDef}>{combinedBlocks}</div>;
  }

  return (
    <div className={classes.resultPanelResults}>
      <div className={classes.resultPanelContentLeft}>
        <div className={classes.resultCard}>
          <ResultPanelHeader text={result.title} />
          <div>{combinedBlocks}</div>
        </div>
      </div>
      <div className={classes.resultPanelContentRight}>
        <ResultResources result={result} />
      </div>
    </div>
  );
};

export default ResultPanel;
