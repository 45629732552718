import React, { FunctionComponent } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import {
  ActionItemHeaderButtonProps,
  ActionItemHeaderButtonType,
  CustomBreakPoints,
} from '../../../common/types';

const useStyles = makeStyles((theme) => ({
  button: {
    border: '1px solid #CBCBCB',
    background: '#fff',
    borderRadius: 3,
    boxShadow: 'none',
    color: '#545b64',
    height: 40,
    padding: '0 30px',
    '&:hover': {
      background: '#CBCBCB',
    },
    textTransform: 'none',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      height: 30,
      padding: '0 10px',
    },
  },
  buttonMobile: {
    border: 'none',
    background: 'non',
    borderRadius: 3,
    boxShadow: 'none',
    color: '#fff',
    height: 40,
    padding: '0 10px',
    textTransform: 'none',
  },
  buttonCompleted: {
    backgroundColor: '#4bd141',
    color: '#fff',
  },
  buttonBlue: {
    backgroundColor: '#0095FF',
    color: '#fff',
  },
}));

const ActionItemButton: FunctionComponent<ActionItemHeaderButtonProps> = ({
  text,
  onClick,
  type,
  showCheck,
  showArrow,
  showArrowEnd,
}) => {
  const classes = useStyles();

  let buttonStyle = classes.button;
  if (type === ActionItemHeaderButtonType.COMPLETED) {
    buttonStyle = buttonStyle + ' ' + classes.buttonCompleted;
  }
  if (type === ActionItemHeaderButtonType.BLUE) {
    buttonStyle = buttonStyle + ' ' + classes.buttonBlue;
  }
  if (type === ActionItemHeaderButtonType.MOBILE) {
    buttonStyle = classes.buttonMobile;
  }

  let startIcon: JSX.Element | null = null;
  let endIcon: JSX.Element | null = null;
  if (showCheck) {
    startIcon = <i className={'fa fa-check'} style={{ fontSize: '14px' }} />;
  } else if (showArrow) {
    startIcon = (
      <i className={'fa fa-long-arrow-left'} style={{ fontSize: '14px' }} />
    );
  } else if (showArrowEnd) {
    endIcon = (
      <i className={'fa fa-long-arrow-right'} style={{ fontSize: '14px' }} />
    );
  }

  return (
    <Button
      className={buttonStyle}
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
    >
      {text}
    </Button>
  );
};

export default ActionItemButton;
