import React, { FunctionComponent } from 'react';
import InputTextForm from '../input/input-text-form';
import { FormFieldsParams } from '../../../common/types';

const FormFields: FunctionComponent<FormFieldsParams> = ({
  form,
  onBlur,
  onChange,
}) => {
  return (
    <>
      {Object.keys(form).map((ff) => (
        <div key={ff}>
          <InputTextForm
            onBlur={onBlur}
            onChange={onChange}
            formField={form[ff]}
            name={ff}
            htmlAttributes={form[ff].htmlAttributes}
          />
        </div>
      ))}
    </>
  );
};

export default FormFields;
