import React, { Fragment } from 'react';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core';
import Moto from '@material-ui/icons/LockOpen';
import ModalButton from './modal-button';

const styles = (theme: Theme) =>
  createStyles({
    modalShade: {
      position: 'fixed',
      top: '0px',
      left: '0px',
      width: '100%',
      height: '100%',
      backgroundColor: '#2E2E2E',
      opacity: 0.2,
      zIndex: 50,
    },
    modalBody: {
      margin: '0 auto',
      position: 'absolute',
      top: '-300px',
      left: '50%',
      marginLeft: '-176px',
      width: '352px',
      height: '',
      backgroundColor: '#F7F7F7',
      zIndex: 51,
      borderRadius: '5px',
      '-webkit-box-shadow': '0px 0px 16px 0px rgba(114,253,104,0.96)',
      '-moz-box-shadow': '0px 3px 6px 0px rgba(0,0,0,0.16)',
      'box-shadow': '0px 3px 6px 0px rgba(0,0,0,0.16)',
      color: 'white',
      animation: '$change .20s linear forwards',
    },
    '@keyframes change': {
      '100%': {
        transform: 'translateY(350px)',
      },
    },
    modalContentHolder: {
      top: '65px',
      left: '0px',
      width: '100%',
      height: 'calc(100% - 65px)',
      //overflow: 'hidden',
      //overflowY: 'scroll',
    },

    modalContent: {
      width: '100%',
      height: '100%',
    },
    modalClose: {
      position: 'absolute',
      zIndex: 52,
      right: '57px',
      top: '50px',
      width: '25px',
      height: '25px',
      backgroundColor: '#2E2E2E',
      color: '#AFAFAF',
      fontWeight: 'bold',
      fontSize: '20px',
      textAlign: 'center',
      borderRadius: '2px',
      cursor: 'pointer',
      '-webkit-transition': '-webkit-transform .4s ease-in-out',
      transition: 'transform .4s ease-in-out',
      '&:hover': {
        opacity: 0.6,
      },
    },
    visible: {
      visibility: 'visible',
      opacity: 1,
      transition: 'opacity .1s linear',
    },
    hidden: {
      visibility: 'hidden',
      opacity: 0,
      transition: 'visibility 0s .5s, opacity .5s linear',
    },
    blur: {
      '-webkit-filter': 'blur(10px)',
      '-moz-filter': 'blur(10px)',
      '-o-filter': 'blur(10px)',
      '-ms-filter': 'blur(10px)',
      filter:
        "url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='svgMask'><feGaussianBlur stdDeviation='10' /></filter></svg>#svgMask\")",
    },
    topIconContainer: {
      textAlign: 'center',
      marginTop: '-30px',
    },
    topIconHolder: {
      backgroundColor: '#fff',
      border: '9px solid #F7F7F7',
      borderRadius: '50%',
      height: '78px',
      width: '78px',
      margin: '0 auto',
    },
    icon: {
      color: '#AFAFAF',
      marginTop: '6px',
      fontSize: '40px',
    },
    iconBottom: {
      color: '#fff',
      marginTop: '2.2px',
      fontSize: '20px',
    },
    mHolder: {
      padding: '20px',
    },
    mTitle: {
      fontSize: '20px',
      marginTop: '-10px',
      fontWeight: 600,
      color: '#AFAFAF',
    },
    mBody: {
      paddingTop: '10px',
      fontSize: '13px',
      color: '#AFAFAF',
    },
    tableIndicator: {
      margin: '0 auto',
      paddingTop: '30px',
      paddingBottom: '15px',
    },
    unStylelink: {
      textDecoration: 'none',
      cursor: 'pointer',
      color: '#AFAFAF',
      fontWeight: 600,
    },
    iconBox: {
      background: '#a0a0a0',
      padding: '3px 2px',
      border: '0px solid #b9b9b9',
      borderRadius: '2px',
      fontWeight: 'bold',
      fontSize: '28px',
      marginLeft: '1px',
      marginRight: '1px',
      marginTop: '0px',
    },
    tableFrame: {
      background: 'white',
      minWidth: '100%',
      borderRadius: '2px',
    },
    linkContainer: {
      overflowY: 'scroll',
      maxHeight: '300px',
    },
  });

interface Props extends WithStyles<typeof styles> {
  onClick: any;
  data: any;
  onNavigate: Function;
}

const DialogueVault: React.FunctionComponent<Props> = (props) => {
  const { classes, onClick, data, onNavigate } = props;
  const ButtonText = data.isLocked ? 'Locked' : 'Close & Continue';

  const buildVaultLinks = (item, index) => {
    //return (<td></td>);
    //const colorClass = item.isLocked? classes.numRed : classes.numGreen;
    //if(index + 1 === data.children.length){
    return (
      <tr>
        <td>
          <div className={classes.iconBox}>
            <i className="fa">{item.icon}</i>
          </div>
        </td>
        <td style={{ width: '%100' }}>
          <ModalButton
            data={{}}
            onClick={() => {
              onNavigate(item.code);
            }}
            type="inherit"
            text={item.name}
          />
        </td>
      </tr>
    );
    //}
  };

  return (
    <Fragment>
      <div className={classes.modalShade} onClick={onClick} />
      <div className={classes.modalBody}>
        <div className={classes.modalContentHolder}>
          <div className={classes.topIconContainer}>
            <div className={classes.topIconHolder}>
              <Moto className={classes.icon} fontSize="large" />
            </div>
            <div className={classes.mHolder}>
              <div className={classes.mTitle}>The Legal Vault is Open!</div>
              <div className={classes.mBody}>
                This is a special area of our application you now get access to
                after completing all of our other modules. Use this area to
                manage ongoing legal concerns as your business grows and
                iterates, <br />
                <br />
                <b>Feel free to explore them in any order!</b>
              </div>

              <div style={{ minHeight: '30px' }} />
              <div className={classes.linkContainer}>
                <table className={classes.tableFrame}>
                  <tbody>
                    {data.children &&
                      data.children.map((item, index) =>
                        buildVaultLinks(item, index)
                      )}
                  </tbody>
                </table>
              </div>

              {data.isLocked && (
                <div className={classes.mBody} style={{ marginTop: '5px' }}>
                  &nbsp;
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default withStyles(styles)(DialogueVault);
