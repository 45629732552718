import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core';
import {
  ActionItemHeaderButtonType,
  CustomBreakPoints,
  TaskCardProps,
} from '../../../common/types';
import { useNestedRoute } from '../../../hoc/location-hooks';
import { Link } from 'react-router-dom';
import ActionItemButton from '../buttons/action-item-button';
import CompletionTimeOverlay from './completion-time-overlay';

const useStyles = makeStyles((theme) => ({
  taskLink: {
    textDecoration: 'none',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      width: '100%',
    },
  },
  task: {
    border: '2px solid #DBDBDB',
    borderRadius: '5px',
    boxShadow: '0px 3px 6px #00000029',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#717171',
    '&:hover': {
      boxShadow: '0px 6px 9px #00000050',
    },
    [theme.breakpoints.up(CustomBreakPoints.mobile)]: {
      width: '800px',
      height: '162px',
    },
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      width: '100%',
      height: '90px',
    },
  },
  taskSmall: {
    width: '654px',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      width: '100%',
      height: '90px',
    },
  },
  taskLeft: {
    width: '260px',
    padding: '20px',
    display: 'flex',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      width: '38%',
      paddingLeft: '10px',
      paddingRight: '5px',
    },
  },
  taskRight: {
    width: '540px',
    paddingRight: '20px',
    overflow: 'hidden',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      width: '62%',
      paddingLeft: '3px',
      paddingRight: '7px',
    },
  },
  taskRightSmall: {
    width: '394px',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      width: '62%',
      paddingLeft: '3px',
      paddingRight: '7px',
    },
  },
  imageWrapper: {
    position: 'relative',
    [theme.breakpoints.up(CustomBreakPoints.mobile)]: {
      width: '220px',
      height: '124px',
    },
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      width: '100%',
      paddingTop: '56.25%',
    },
  },
  image: {
    top: 0,
    right: 0,
    width: '100%',
    height: '100%',
    position: 'absolute',
    borderRadius: '12px',
  },
  imageCompleted: {
    opacity: 0.4,
    filter: 'alpha(opacity=40)',
  },
  compTimeCorner: {
    borderBottomRightRadius: '12px',
  },
  title: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    [theme.breakpoints.up(CustomBreakPoints.mobile)]: {
      marginBottom: '10px',
    },
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      fontSize: '.9rem',
    },
  },
  titleSmall: {
    fontSize: '1rem',
  },
  mobileDescription: {
    fontSize: '.9rem',
    color: '#717171',
    marginBottom: '5px',
    [theme.breakpoints.up(CustomBreakPoints.mobile)]: {
      display: 'none',
    },
  },
  description: {
    color: '#717171',
    fontSize: '1rem',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      display: 'none',
      fontSize: '.8rem',
    },
  },
  descriptionSmall: {
    fontSize: '.8rem',
  },
}));

const TaskCard: FunctionComponent<TaskCardProps> = ({
  task,
  type,
  titleColor,
  completed,
  small,
}) => {
  const classes = useStyles();
  const urlRoot = useNestedRoute();

  const titleColorRef = titleColor || '#347CB1';

  return (
    <Link to={`${urlRoot}/${type}/${task.code}`} className={classes.taskLink}>
      <div
        className={`${classes.task + (small ? ' ' + classes.taskSmall : '')}`}
      >
        <div className={classes.taskLeft}>
          <div className={classes.imageWrapper}>
            <img
              className={`${
                classes.image + (completed ? ' ' + classes.imageCompleted : '')
              }`}
              src={task.image}
              alt={task.code}
            />
            {!completed && (
              <CompletionTimeOverlay
                time={task.completionTime}
                className={classes.compTimeCorner}
              />
            )}
          </div>
        </div>
        <div
          className={`${
            classes.taskRight + (small ? ' ' + classes.taskRightSmall : '')
          }`}
        >
          <div className={classes.mobileDescription}>Task</div>
          <div
            className={`${
              classes.title + (small ? ' ' + classes.titleSmall : '')
            }`}
            style={{ color: titleColorRef }}
          >
            {task.title}
          </div>
          <div
            className={`${
              classes.description +
              (small ? ' ' + classes.descriptionSmall : '')
            }`}
          >
            {!completed ? (
              task.description
            ) : (
              <ActionItemButton
                text="Completed"
                type={ActionItemHeaderButtonType.COMPLETED}
                showCheck
              />
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default TaskCard;
