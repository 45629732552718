import React, { useEffect } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { useStateValue } from './state';
import HeaderNew from '../containers/layout/header_new';
import { makeStyles } from '@material-ui/core';
import useDashboardApi from '../apis/dashboard-apis';
import { CustomBreakPoints } from '../common/types';

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    width: '100%',
  },
  rowInner: {
    minWidth: '900px',
    width: '1200px',
    margin: '0 auto',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      minWidth: '0',
      width: '100%',
    },
  },
}));

const AuthenticatedLayout: React.FunctionComponent<RouteComponentProps> = (
  props
) => {
  const { isAuthenticated, user, screenDimensions, dashboardsList } =
    useStateValue();
  const { fetchDashboardsAll } = useDashboardApi();
  const classes = useStyles();

  useEffect(() => {
    if (isAuthenticated && dashboardsList.length === 0) {
      fetchDashboardsAll();
    }
  }, []);

  const isMobile: boolean = screenDimensions
    ? screenDimensions.isMobile
    : false;
  const screenReductionPixels = isMobile ? '40' : '60';

  if (isAuthenticated) {
    if (user && !user.emailVerified) {
      return <Redirect to="/register" />;
    } else if (dashboardsList.length > 0) {
      return (
        <div
          style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        >
          <div className={classes.row} style={{ backgroundColor: '#347CB1' }}>
            <div className={classes.rowInner}>
              <HeaderNew />
            </div>
          </div>
          <main
            style={{
              height: 'calc(100% - ' + screenReductionPixels + 'px)',
              background: '#ffffff',
            }}
          >
            {props.children}
          </main>
        </div>
      );
    } else {
      return null;
    }
  } else {
    return (
      <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    );
  }
};

export default AuthenticatedLayout;
