import axiosInst, { axiosWrapper } from '../common/axios';
import { GlobalAction, GlobalActionType } from '../common/types';
import { Dispatch } from 'react';

export const fetchOrganizationByCode = (
  code: string,
  globalDispatch: Dispatch<GlobalAction>
) => {
  axiosWrapper<any>(
    axiosInst.get<any>(`org/code/${code}`),
    (resp) => {
      globalDispatch({
        type: GlobalActionType.SET_ORG,
        payload: { org: resp.data },
      });
    },
    () => {},
    null,
    globalDispatch
  );
};
export const fetchOrganizationByDomain = (
  domain: string,
  globalDispatch: Dispatch<GlobalAction>
) => {
  axiosWrapper<any>(
    axiosInst.get<any>(`org/domain/${domain}`),
    (resp) => {
      globalDispatch({
        type: GlobalActionType.SET_ORG,
        payload: { org: resp.data },
      });
    },
    () => {},
    null,
    globalDispatch
  );
};
