import React from 'react';
import { Elements as ElementsInner } from 'react-stripe-elements';
import StripeHookProvider from './stripe-hook-provider';

const StripeElements = ({ children }) => {
  return (
    <ElementsInner>
      <StripeHookProvider>{children}</StripeHookProvider>
    </ElementsInner>
  );
};

export default StripeElements;
