import React, { Fragment, useState } from 'react';
import {
  Checkbox,
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

const styles = (theme: Theme) =>
  createStyles({
    registerRoot: {
      height: '100%',
      display: 'flex',
    },
    leftColumn: {
      width: '30%',
      height: '100%',
      display: 'flex',
      backgroundColor: '#0076ca',
      alignItems: 'center',
    },
    rightColumn: {
      width: '70%',
      height: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'bottom',
      backgroundSize: 'contain',
      backgroundImage: "url('/images/4_back.png')",
      color: '#2E2E2E',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    sideImage: {
      width: '100%',
      maxWidth: '340px',
      marginTop: '65px',
    },
    logoImage: {
      width: '60px',
      height: '60px',
    },
    modalShade: {
      position: 'fixed',
      top: '0px',
      left: '0px',
      width: '100%',
      height: '100%',
      backgroundColor: 'white',
      opacity: 1,
      zIndex: 1050,
    },

    topIconContainer: {
      textAlign: 'center',
      marginTop: '30px',
    },

    mHolder: {
      padding: '20px',
    },
    mTitle: {
      fontSize: '28px',
      marginTop: '-40px',
      fontWeight: 600,
      color: 'black',
      textAlign: 'center',
      fontFamily: "'Roboto', 'sans-serif'",
    },
    mBody: {
      paddingTop: '10px',
      paddingBottom: '18px',
      paddingLeft: '0px',
      paddingRight: '0px',
      fontSize: '14px',
      color: '#666A72',
      textAlign: 'center',
      marginTop: '20px',
    },

    unStylelink: {
      textDecoration: 'none',
      cursor: 'pointer',
      color: 'white',
      fontWeight: 600,
    },
    blueButt: {
      clear: 'both',
      display: 'block',
      cursor: 'pointer',
      width: '328px',
      height: '40px',
      color: 'white',
      border: '4px solid #0094ff',
      borderRadius: '4px',
      background: '#0094ff',
      margin: '0 auto',
    },
    blueButtText: {
      height: '30px',
      marginTop: '8px',
      fontSize: '14px',
    },
    greyButt: {
      display: 'block',
      cursor: 'pointer',
      width: '200px',
      height: '42px',
      color: 'white',
      border: '2px solid #c8c8c8',
      borderRadius: '4px',
      background: 'white',
      margin: '0 auto',
      float: 'left',
    },
    greyButtText: {
      color: '#c8c8c8',
      height: '30px',
      marginTop: '7px',
      fontWeight: 'bold',
      fontSize: '19px',
    },

    linkText: {
      textDecoration: 'underline',
      color: '#0095ff',
      fontWeight: 600,
      cursor: 'pointer',
      marginTop: '30px',
    },
    optionHolder: {
      paddingLeft: '60px',
      paddingRight: '60px',
    },
    option: {
      minWidth: '240px',
      minHeight: '180px',
      margin: '10px',
      borderRadius: '10px',
      float: 'left',
      cursor: 'pointer',
    },
    image: {
      height: '153px',
      marginTop: '0px',
      marginBottom: '10px',
    },
    selectionTitle: {
      color: 'black',
      fontSize: '23px',
      fontWeight: 'bold',
    },
    selectionTitleUnder: {
      marginTop: '9px',
      fontSize: '14px',
      textAlign: 'center',
      color: '#666A72',
      lineHeight: '17px',
    },
    buttDisabled: {
      opacity: 0.2,
      cursor: 'default',
    },
    close: {
      float: 'right',
      marginRight: '-35px',
      marginTop: '-15px',
      color: '#656565',
      fontWeight: 'bold',
      cursor: 'pointer',
    },
    siteTermsContent: {
      height: '350px',
      maxHeight: '350px',
      overflowY: 'scroll',
      maxWidth: '840px',
      textAlign: 'left',
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
      paddingLeft: '25px',
      paddingRight: '25px',
      borderRadius: '25px',
      color: '2e2e2e',
    },
  });

interface Props extends WithStyles<typeof styles> {
  acceptTerms: any;
}

const TermsModalContentStyled: React.FunctionComponent<
  RouteComponentProps & Props
> = (props) => {
  const [dashboardSelection, setDashboardSelection] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const { classes, acceptTerms } = props;

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  return (
    <Fragment>
      <div className={classes.modalShade}>
        <div className={classes.registerRoot}>
          <div className={classes.leftColumn}>
            <img
              src="/images/sideLogoLarge.png"
              className={classes.sideImage}
            />
          </div>
          <div className={classes.rightColumn}>
            <div style={{ marginBottom: '.5rem' }}>
              <img src="/images/icon_blue.png" className={classes.logoImage} />
            </div>
            <div className={classes.topIconContainer}>
              <div className={classes.mHolder}>
                <div className={classes.mTitle}>Site Terms of Use</div>
              </div>
              <div className={classes.optionHolder}>
                <div className={classes.siteTermsContent}>
                  <div>
                    <p>
                      Welcome to Wayfarer.io, a website located at{' '}
                      <a href="https://www.thewayfarer.io">
                        www.thewayfarer.io
                      </a>{' '}
                      (the “Site”) and operated by Legal GPS, Inc. (“us”, “our”,
                      “we” and "Legal GPS"). Legal GPS provides the Site and
                      services provided through the Site (“Services”) including
                      videos and resources that provide legal information and
                      education for startup companies. These Terms of Use
                      (“Agreement”) set forth the legally binding terms for your
                      use of the Services. By accessing or using the Services,
                      you are accepting this Agreement and you represent and
                      warrant that you have the right, authority, and capacity
                      to enter into this Agreement. If you do not agree with all
                      of the provisions of this Agreement, do not access and/or
                      use the Services. You may not access or use the Services
                      or accept the Agreement if you are not at least 18 years
                      old.
                    </p>
                    <p className="site-terms-bold">Privacy Policy</p>
                    <p>
                      The Company respects the privacy of its Service users.
                      Please refer to the Company’s{' '}
                      <a href="https://www.legalgps.com/privacy.html">
                        Privacy Policy
                      </a>{' '}
                      which explains how we collect, use, and disclose
                      information that pertains to your privacy. When you access
                      or use the Service, you signify your agreement to this
                      Privacy Policy.
                    </p>
                    <p className="site-terms-bold">About Legal GPS</p>
                    <p>
                      Our Site offers an education and learning platform for our
                      community relating to common legal issues for
                      entrepreneurs. Legal GPS is not a law firm and does not
                      offer legal advice to customers or visitors of our Site.
                    </p>
                    <p className="site-terms-bold">
                      Legal GPS is Not a Law Firm
                    </p>
                    <p>
                      Legal GPS does not offer legal representation.Legal GPS
                      does not offer any legal advice, legal opinions,
                      recommendations, referrals, or counseling. The legal
                      information contained in our videos, resources,
                      questionnaires, or form contract templates are not
                      intended to be construed as formal legal advice, nor does
                      the use of any of these signify the development of an
                      attorney-client relationship. Rather, its purpose is to
                      foster a general understanding of the law. You are
                      strongly encouraged to consult a licensed attorney in your
                      state for competent legal advice on how to interpret and
                      apply this information to your particular situation.
                    </p>
                    <p className="site-terms-bold">Form Templates</p>
                    <p>
                      The purpose of our form templates is only to disseminate
                      useful information. Therefore, Legal GPS takes no legal
                      responsibility connected to its use and make no
                      representations that any of our forms are appropriate for
                      your situation. Any modifications for this template should
                      not be made without proper legal advice from a licensed
                      attorney.
                    </p>
                    <p className="site-terms-bold">Accounts</p>
                    <p>
                      Account Creation. In order to use certain features of the
                      Services, you must register for an account with us (“your
                      Account”) and provide certain information about yourself
                      as prompted by the registration form. You represent and
                      warrant that: (a) all required registration information
                      you submit is truthful and accurate; (b) you will maintain
                      the accuracy of such information. You may delete your
                      Account at any time, for any reason, by following the
                      instructions on the Site. We may suspend or terminate your
                      Account in accordance with the Terms and Termination.
                    </p>
                    <p>
                      Account Responsibilities. You are responsible for
                      maintaining the confidentiality of your Account login
                      information and are fully responsible for all activities
                      that occur under your Account. You agree to immediately
                      notify us of any unauthorized use, or suspected
                      unauthorized use, of your Account or any other breach of
                      security. We cannot and will not be liable for any loss or
                      damage arising from your failure to comply with the above
                      requirements.
                    </p>
                    <p className="site-terms-bold">Payment Terms</p>
                    <p>
                      Subscription and Billing. Certain aspects of the Services
                      may be provided for a fee, subscription or other charge.
                      Currently, Legal GPS offers subscription based services to
                      users. If you purchase a subscription from Legal GPS, this
                      subscription will automatically renew at the end of each
                      billing cycle, typically every thirty (30) days, at which
                      point you will automatically be charged on your
                      anniversary date for the next billing cycle. Legal GPS may
                      add new services for additional fees and charges, add or
                      amend fees and charges for existing services, at any time
                      in its sole discretion. Any change to our pricing or
                      payment terms will become effective in the billing cycle
                      following notice of such change to you as provided in this
                      Agreement. Payment Information; Taxes. You are responsible
                      for all fees, including taxes, associated with your use of
                      the Service. You are responsible for providing us with a
                      valid means of payment. All information that you provide
                      in connection with a purchase or transaction or other
                      monetary transaction interaction with the Services must be
                      accurate, complete, and current. You must keep current
                      payment information on file with Legal GPS. You agree to
                      pay all charges incurred by users of your credit card,
                      debit card, or other payment method used in connection
                      with a purchase or transaction or other monetary
                      transaction interaction with the Services at the prices in
                      effect when such charges are incurred.
                    </p>
                    <p>
                      Payment Authorization. By agreeing to these terms, you are
                      giving Legal GPS permission to charge your on-file credit
                      card, PayPal account, or other approved methods of payment
                      for fees that you authorize Legal GPS to satisfy.
                    </p>
                    <p>
                      Notification of Payment. Legal GPS will email you a
                      receipt when your card has been charged. If your card
                      cannot be charged, your access to Services may be
                      suspended and you will need to update your card
                      information in order to resume use.
                    </p>
                    <p>
                      Chargebacks. If you have a dispute concerning any payment
                      transaction, please contact our billing department. If at
                      any time you contact your bank or credit card company to
                      reject the charge of any payable Fees (“Chargeback”), this
                      act will be considered a breach of your payment
                      obligations, and we reserve the right to automatically
                      terminate your use of the Services. We reserve the right
                      to dispute any Chargeback and take all reasonable action
                      to authorize the transaction. In the event of a
                      Chargeback, we may terminate our Service. In order to
                      resume use of the Services, you must re-subscribe for the
                      Services and pay all applicable fees for the Service as
                      well as any fees incurred by us or our payment processor
                      as a result of the Chargeback.
                    </p>
                    <p className="site-terms-bold">Payment Terms</p>
                    <p>
                      You may cancel your subscription or any paid Service at
                      any time by emailing us at hello@legalgps.com. You will
                      not be prorated for any unused Services during the billing
                      cycle in which you cancel your Services and your Services
                      will be active until the end of the then-current billing
                      cycle.
                    </p>
                    <p className="site-terms-bold">
                      Reliance on Information Posted
                    </p>
                    <p>
                      You acknowledge that the information contained in this
                      Site is solely for general information purposes. We make
                      no representations or warranties of any kind, either
                      express or implied about the accuracy, completeness, or
                      usefulness of this information. Any reliance you place on
                      such information is strictly at your own risk. This Site
                      may include content provided by third parties, including
                      materials provided by other users and third-party
                      licensors. We are not responsible or liable to you or any
                      third party for the content or accuracy of materials
                      provided by us or any third parties.
                    </p>
                    <p className="site-terms-bold">Rights and Licenses</p>
                    <p>
                      License to Use Site. We grant you a non-transferable,
                      non-exclusive, right to access and use the Services for
                      your personal use.
                    </p>
                    <p>
                      Certain Restrictions. The rights granted to you in this
                      Agreement are subject to the following restrictions: (a)
                      you will not license, sell, rent, lease, transfer, assign,
                      distribute, host, or otherwise commercially exploit the
                      Services; (b) you will not modify, make derivative works
                      of, disassemble, reverse compile or reverse engineer any
                      part of the Services; (c) you will not access the Services
                      in order to build a similar or competitive service; and
                      (d) except as expressly stated in these terms, no part of
                      the Services may be copied, reproduced, distributed,
                      republished, downloaded, displayed, posted or transmitted
                      in any form or by any means. Any future release, update,
                      or other addition to functionality of the Services will be
                      subject to the terms of this Agreement. All copyright and
                      other proprietary notices on any Services content must be
                      retained on all copies. You further agree that you will
                      not seek to hire or otherwise engage sellers for future
                      Services, or solicit buyers to purchase your services
                      unless you sell/purchase the Services directly from us.
                    </p>
                    <p>
                      Modification. We reserve the right, at any time, to
                      modify, suspend, or discontinue the Services with or
                      without notice. You agree that we will not be liable to
                      you or to any third party for any modification,
                      suspension, or discontinuance of the Services, except and
                      if otherwise expressly set forth in these Terms.
                    </p>
                    <p>
                      No Support or Maintenance. You acknowledge and agree that
                      we will have no obligation to provide you with any support
                      or maintenance in connection with the Services. Ownership
                      of the Services. Excluding your User Content (defined
                      below), you acknowledge that all the intellectual property
                      rights, including copyrights, patents, trademarks, and
                      trade secrets, in the Services, including the Site, are
                      owned by us or our licensors. The provision of the
                      Services does not transfer to you or any third party any
                      rights, title or interest in or to the intellectual
                      property rights. We reserve all rights not granted in this
                      Agreement.
                    </p>
                    <p className="site-terms-bold">User Content</p>
                    <p className="mb-0">
                      User Content. “User Content” means any and all information
                      and content that a user submits to or posts on: (a) the
                      Services and (b) on social networking sites where we have
                      a page or presence. You will own your User Content, with
                      the understanding that you agree that we may use and
                      reproduce the User Content you make available on our
                      social networking sites and on the Services. You are
                      solely responsible for the User Content that you post,
                      upload, link to or otherwise make available via the
                      Service. We reserve the right to remove any User Content
                      from the Service at our discretion. The following rules
                      pertain to User Content. By transmitting and submitting
                      any User Content while using the Service, you agree as
                      follows:
                    </p>
                    <div>
                      <ul>
                        <li>
                          You are solely responsible for your account and the
                          activity that occurs while signed in to or while using
                          your account;
                        </li>
                        <li>
                          You will not submit content that is copyrighted or
                          subject to third party proprietary rights, including
                          privacy, publicity, trade secret, etc., unless you are
                          the owner of such rights or have the appropriate
                          permission from their rightful owner to specifically
                          submit such content;
                        </li>
                        <li>
                          You will abide by our Acceptable Use Policy below; and
                        </li>
                        <li>
                          You affirm we have the right to determine whether any
                          of your User Content submissions are appropriate and
                          comply with these Terms of Service, remove any and/or
                          all of your submissions, and terminate your account
                          with or without prior notice.
                        </li>
                      </ul>
                    </div>
                    <p>
                      You understand and agree that any liability, loss or
                      damage that occurs as a result of the use of any User
                      Content that you make available or access through your use
                      of the Service is solely your responsibility. We are not
                      responsible for any public display or misuse of your User
                      Content. We do not, and cannot, pre-screen or monitor all
                      User Content. However, at our discretion, we, or
                      technology we employ, may monitor and/or record your
                      interactions with the Service and take any action with
                      respect to your User Content we deem reasonable, including
                      disclosing your identity to law enforcement or third
                      parties who reasonably believe you have infringed upon
                      their intellectual property rights.
                    </p>
                    <p className="mb-0">
                      License. You grant, and you represent and warrant that you
                      have the right to grant, to us an irrevocable,
                      nonexclusive, royalty-free and fully paid, worldwide
                      license to reproduce, distribute, publicly display and
                      perform, prepare derivative works of, incorporate into
                      other works, and otherwise use your User Content, and to
                      grant sublicenses of the foregoing, solely for the
                      purposes of including your User Content in the Site and
                      Services. You agree to irrevocably waive (and cause to be
                      waived) any claims and assertions of moral rights or
                      attribution with respect to your User Content. Acceptable
                      Use Policy. Your permission to use the Services is
                      conditioned upon the following Use Restrictions and
                      Conduct Restrictions: You agree that you will not under
                      any circumstances:
                    </p>
                    <div>
                      <ul>
                        <li>
                          post any information that is abusive, threatening,
                          obscene, defamatory, libelous, or racially, sexually,
                          religiously, or otherwise objectionable and offensive;
                        </li>
                        <li>
                          use the service for any unlawful purpose or for the
                          promotion of illegal activities;
                        </li>
                        <li>
                          attempt to, or harass, abuse or harm another person or
                          group;
                        </li>
                        <li>use another user’s account without permission;</li>
                        <li>
                          provide false or inaccurate information when
                          registering an account;
                        </li>
                        <li>
                          interfere or attempt to interfere with the proper
                          functioning of the Service;
                        </li>
                        <li>
                          make any automated use of the system, or take any
                          action that we deem to impose or to potentially impose
                          an unreasonable or disproportionately large load on
                          our servers or network infrastructure;
                        </li>
                        <li>
                          use the Site or any of its contents to advertise or
                          solicit, for any commercial purpose or to compete,
                          directly or indirectly, with our Service;
                        </li>
                        <li>
                          bypass any robot exclusion headers or other measures
                          we take to restrict access to the Service or use any
                          software, technology, or device to scrape, spider, or
                          crawl the Service or harvest or manipulate data; or
                        </li>
                        <li>
                          publish or link to malicious content intended to
                          damage or disrupt another user’s browser or computer.
                        </li>
                      </ul>
                    </div>
                    <p>
                      Feedback. If you provide us any feedback or suggestions
                      regarding the Services (“Feedback”), you assign to us all
                      rights in the Feedback and agree that we will have the
                      right to use the Feedback and related information in any
                      manner we deem appropriate. We will treat any Feedback you
                      provide to us as non-confidential and non-proprietary. You
                      agree that you will not submit to us any information or
                      ideas that you consider to be confidential or proprietary.
                    </p>
                    <p>
                      Indemnity. You agree to indemnify and hold us (and our
                      officers, employees, and agents) harmless, including costs
                      and attorneys’ fees, from any claim or demand made by any
                      third party due to or arising out of (a) your use of the
                      Services, (b) your User Content, (c) your violation of
                      this Agreement; or (d) your violation of applicable laws
                      or regulations. We reserve the right, at your expense, to
                      assume the exclusive defense and control of any matter for
                      which you are required to indemnify us and you agree to
                      cooperate with our defense of these claims. You agree not
                      to settle any matter without our prior written consent. We
                      will use reasonable efforts to notify you of any such
                      claim, action or proceeding upon becoming aware of it.
                    </p>
                    <p className="site-terms-bold">
                      Links to Other Sites and/or Materials
                    </p>
                    <p>
                      Third Party Sites, Ads and Ad Networks. As part of the
                      Service, we may provide you with convenient links to third
                      party website(s) (“Third Party Sites”) as well as content
                      or items belonging to or originating from third parties
                      (the “Third Party Applications, Software or Content”).
                      Users may also include links to their website or other
                      Third Party Sites on their listings. These links are
                      provided as a courtesy to Service subscribers. We have no
                      control over Third Party Sites and Third Party
                      Applications, Software or Content or the promotions,
                      materials, information, goods or services available on
                      these Third Party Sites or Third Party Applications,
                      Software or Content. If you decide to leave the Site and
                      access the Third Party Sites or to use or install any
                      Third Party Applications, Software or Content, you do so
                      at your own risk and you should be aware that our terms
                      and policies no longer govern. You should review the
                      applicable terms and policies, including privacy and data
                      gathering practices, of any site to which you navigate
                      from the Site or relating to any applications you use or
                      install from the site.
                    </p>
                    <p>
                      Links to Our Site. You are permitted to link to our Site
                      for non-commercial purposes, provided that you do so in a
                      way that is fair and legal and does not damage our
                      reputation. You may not link to our Site in such a way as
                      to suggest any form of association, approval, or
                      endorsement on our part without our express written
                      consent. You may not deep-link to any page of this site
                      for any purpose whatsoever unless the link is expressly
                      authorized in writing by us. We reserve the right to
                      withdraw permission for any link.
                    </p>
                    <p>
                      Release. You release and forever discharge us (and our
                      officers, employees, agents, successors, and assigns)
                      from, and waive and relinquish, each and every past,
                      present and future dispute, claim, controversy, demand,
                      right, obligation, liability, action and cause of action
                      of every kind and nature (including personal injury,
                      death, and property damage), that has arisen or arises
                      directly or indirectly out of, or relates directly or
                      indirectly to, any interactions with, or act or omission
                      of, other Service users or Third Party Sites & Ads. IF YOU
                      ARE A CALIFORNIA RESIDENT, YOU WAIVE CALIFORNIA CIVIL CODE
                      SECTION 1542 IN CONNECTION WITH THE ABOVE-MENTIONED
                      CONDITIONS, WHICH STATES: “A GENERAL RELEASE DOES NOT
                      EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR
                      SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF
                      EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST
                      HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE
                      DEBTOR.”
                    </p>
                    <p className="site-terms-bold">Disclaimers</p>
                    <p>
                      THE SERVICES, INCLUDING THE SITE, ARE PROVIDED “AS-IS” AND
                      “AS AVAILABLE” AND WE EXPRESSLY DISCLAIM ANY WARRANTIES
                      AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
                      INCLUDING THE WARRANTIES OR CONDITIONS OF MERCHANTABILITY,
                      FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT,
                      ACCURACY, OR NON-INFRINGEMENT. WE MAKE NO WARRANTY THAT
                      THE SERVICES: (a) WILL MEET YOUR REQUIREMENTS; (b) WILL BE
                      AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR
                      ERROR-FREE BASIS; (c) WILL BE ACCURATE, RELIABLE, FREE OF
                      VIRUSES OR OTHER HARMFUL CODE, COMPLETE, LEGAL, OR SAFE OR
                      (d) THAT THE SERVICES WILL BE TO YOUR SATISFACTION. SOME
                      JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED
                      WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.
                    </p>
                    <p className="site-terms-bold">Limitation on Liability</p>
                    <p>
                      IN NO EVENT WILL WE BE LIABLE TO YOU OR ANY THIRD PARTY
                      FOR ANY LOST PROFIT OR ANY INDIRECT, CONSEQUENTIAL,
                      EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES ARISING
                      FROM OR RELATING TO THIS AGREEMENT OR YOUR USE OF, OR
                      INABILITY TO USE, THE SERVICES, EVEN IF WE HAVE BEEN
                      ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. ACCESS TO, AND
                      USE OF, THE SERVICES ARE AT YOUR OWN DISCRETION AND RISK,
                      AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR
                      COMPUTER SYSTEM OR LOSS OF DATA RESULTING THEREFROM. IN NO
                      EVENT WILL LEGAL GPS, INC’S TOTAL LIABILITY TO YOU FOR ALL
                      DAMAGES, LOSSES, AND CAUSES OF ACTION (WHETHER IN
                      CONTRACT, TORT (INCLUDING, BUT NOT LIMITED TO,
                      NEGLIGENCE), OR OTHERWISE EXCEED THE AMOUNTS YOU’VE PAID
                      LEGAL GPS, INC. IN THE PRIOR 12 MONTHS (IF ANY). THE
                      EXISTENCE OF MORE THAN ONE CLAIM WILL NOT ENLARGE THIS
                      LIMIT.
                    </p>
                    <p>
                      SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR
                      EXCLUSION OF LIABILITY FOR INCIDENTAL OF CONSEQUENTIAL
                      DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT
                      APPLY TO YOU.
                    </p>
                    <p className="site-terms-bold">Term and Termination</p>
                    <p>
                      Subject to this Section, this Agreement will remain in
                      full force and effect while you use the Services. We may
                      (a) suspend your rights to use the Site and/or Services
                      (including your Account) or (b) terminate this Agreement,
                      at any time for any reason at our sole discretion,
                      including for any use of the Services in violation of this
                      Agreement. Upon termination of this Agreement, your
                      Account and right to access and use the Services will
                      terminate immediately. You understand that any termination
                      of your Account involves deletion of your User Content
                      from our live databases. We will not have any liability
                      whatsoever to you for any termination of this Agreement,
                      including for termination of your Account or deletion of
                      your User Content. Upon termination of this Agreement, all
                      of the provisions will terminate except those that by
                      their nature should survive.
                    </p>
                    <p className="site-terms-bold">Copyright Policy</p>
                    <p className="mb-0">
                      We respect the intellectual property of others and ask
                      that users of our Site and Services do the same. In
                      connection with our Site and Services and in accordance
                      with the Digital Millennium Copyright Act’s (“DMCA”), we
                      have adopted and implemented a policy respecting copyright
                      laws that provide for the removal of any infringing
                      materials and for the termination, in appropriate
                      circumstances, of users of our online Services who are
                      repeat infringers of intellectual property rights,
                      including copyrights. If you believe that one of our users
                      is, through the use of our Services, unlawfully infringing
                      the copyright(s) in a work, and wish to have the allegedly
                      infringing material removed, the following information in
                      the form of a written notification (pursuant to 17 U.S.C.
                      § 512(c)) must be provided to our designated Copyright
                      Agent:
                    </p>
                    <div>
                      <ul>
                        <li>your physical or electronic signature;</li>
                        <li>
                          identification of the copyrighted work(s) that you
                          claim to have been infringed;
                        </li>
                        <li>
                          identification of the material on our Services that
                          you claim is infringing and that you request us to
                          remove;
                        </li>
                        <li>
                          sufficient information to permit us to locate such
                          material;
                        </li>
                        <li>
                          your address, telephone number, and e-mail address;
                        </li>
                        <li>
                          a statement that you have a good faith belief that use
                          of the objectionable material is not authorized by the
                          copyright owner, its agent, or under the law; and
                        </li>
                        <li>
                          a statement that the information in the notification
                          is accurate, and under penalty of perjury, that you
                          are either the owner of the copyright that has
                          allegedly been infringed or that you are authorized to
                          act on behalf of the copyright owner.
                        </li>
                      </ul>
                    </div>
                    <p>
                      Please note that, pursuant to 17 U.S.C. § 512(f), any
                      misrepresentation of material fact (falsities) in a
                      written notification automatically subjects the
                      complaining party to liability for any damages, costs and
                      attorney’s fees incurred by us in connection with the
                      written notification and allegation of copyright
                      infringement. Our designated Copyright Agent is:
                      <br />
                      Legal GPS, Inc.
                      <br />
                      Attn: Privacy Officer
                      <br />
                      Address: 4818 Washington Blvd St. Louis, MO 63108
                      <br />
                      Email: hello@legalgps.com
                    </p>
                  </div>
                </div>
                <div style={{ minHeight: '30px', clear: 'both' }} />
                <h5>
                  <span style={{ marginTop: '3px' }}>
                    <Checkbox
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                  </span>
                  Check here to indicate you agree to our terms of service
                  above.
                </h5>
                <div
                  className={
                    classes.blueButt +
                    ' ' +
                    (!isChecked ? classes.buttDisabled : '')
                  }
                  onClick={() => {
                    if (isChecked) {
                      acceptTerms();
                    }
                  }}
                >
                  <div className={classes.blueButtText}>Accept Terms</div>
                </div>
              </div>
              <div style={{ minHeight: '30px' }} />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default withStyles(styles)(withRouter(TermsModalContentStyled));
