import React, { FunctionComponent, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useStateValue } from '../../hoc/state';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import {
  CustomBreakPoints,
  GlobalAction,
  GlobalActionType,
} from '../../common/types';
import queryString from 'query-string';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  header: {
    margin: 0,
    width: '100%',
    backgroundColor: '#347CB1',
    height: '60px',
    minHeight: '60px',
    zIndex: 41,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      backgroundColor: '#347CB1',
      minWidth: '100%',
      width: '100%',
      height: '40px',
      minHeight: '40px',
      display: 'block',
    },
  },
  logoDiv: {
    width: '25%',
  },
  logo: {
    top: '16px',
    left: '50px',
    height: '40px',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      display: 'none',
    },
  },
  logoMobile: {
    display: 'none',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      display: 'block',
      position: 'absolute',
      top: '9px',
      left: '20px',
      height: '18px',
    },
  },
  socialLinks: {
    width: '25%',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      display: 'none',
    },
  },
  socialLinksInner: {
    marginLeft: 'auto',
    marginRight: 0,
    width: '30px',
    '& a': {
      display: 'block',
      textAlign: 'center',
      width: '30px',
      height: '30px',
      background: '#fff',
      borderRadius: '30px',
      [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
        marginRight: '19px',
      },
      '& i': {
        fontSize: '18px',
        lineHeight: '30px',
        color: '#0095FF',
      },
    },
  },
  searchContainerDiv: {
    width: '50%',
    height: '36px',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      display: 'none',
    },
  },
  searchContainer: {
    width: '430px',
    // display: 'block',
    margin: '0 auto',
  },
  searchContainerMobile: {
    display: 'none',
    position: 'absolute',
    top: '0',
    left: '0',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    zIndex: 100000,
  },
  searchContainerMobileShow: {
    display: 'block',
  },
  inputSearchMain: {
    marginTop: '0px',
    width: '100%',
    height: '36px',
    padding: '0 20px',
    fontSize: '1rem',
    borderRadius: '30px',
    border: 0,
    outline: 'none',
    backgroundColor: '#FFF',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      borderRadius: '0px',
      height: '40px',
      padding: '0 17px',
    },
    '&:focus': {
      transition: '0.35s ease',
      color: '#008ABF',
      '&::-webkit-input-placeholder': {
        transition: 'opacity 0.45s ease',
        opacity: 0,
      },
    },
    '&::-webkit-input-placeholder': {
      color: '#C9C9C9',
      fontSize: '16px',
    },
  },
  inputSearchMainDiv: {
    // marginTop: '11px',
    width: '100%',
    minHeight: '36px',
    fontSize: '1rem',
    borderRadius: '30px',
    border: 0,
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      borderRadius: '0px',
      marginTop: '34px',
    },
    outline: 'none',
    backgroundColor: '#FFF',
  },
  searchIcon: {
    position: 'relative',
    float: 'right',
    width: '21px',
    height: '21px',
    top: '-27px',
    right: '19px',
    cursor: 'pointer',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      top: '-27px',
    },
  },
  previewContainer: {
    height: '0px',
    opacity: 0,
    transition: 'all .75s ease',
    overflow: 'hidden',
  },
  previewContainerShow: {
    height: '100%',
    opacity: 1,
    paddingLeft: '0px',
    paddingRight: '0px',
    paddingBottom: '0px',
    paddingTop: '0px',
  },
  previewBody: {
    paddingTop: '0px',
    color: 'black',
    zIndex: 99999,
  },
  logoutLink: {
    cursor: 'pointer',
  },
  backSquare: {
    position: 'absolute',
    right: '19px',
    top: '-5px',
    width: '100%',
    height: '100%',
    zIndex: 9997,
    background: 'white',
    opacity: 0.01,
  },
  square: {
    '&::before': {
      content: '""',
      position: 'absolute',
      right: '19px',
      top: '-5px',
      width: '0px',
      height: '0px',
      borderStyle: 'solid',
      borderWidth: '0 5px 5px 5px',
      borderColor: 'transparent transparent #f9f9f9 transparent',
      zIndex: 9999,
      [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
        display: 'none',
      },
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      right: '12px',
      top: '-16px',
      width: '0px',
      height: '0px',
      borderStyle: 'solid',
      borderWidth: '0 8.5px 8.5px 8.5px',
      borderColor: 'transparent transparent #f9f9f9 transparent',
      zIndex: 9998,
      [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
        display: 'none',
      },
    },
    background: '#f9f9f9',
    border: '8px solid #f9f9f9',
    boxShadow: '0 3px 3px rgba(0, 0, 0, 0.2)',
    position: 'absolute',
    margin: '0px',
    top: '63px',
    right: '193px',
    width: '190px',
    zIndex: 99999,
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      background: '#0095FF',
      border: '8px solid #0095FF',
      top: '35px',
      right: '0px',
      width: '190px',
    },
    '& ul': {
      padding: '0px',
      marginBlockEnd: '3px',
      marginBlockStart: '0px',
      listStyleType: 'none',
      textAlign: 'left',
    },
    '& ul li': {
      color: '#C9C9C9',
      fontSize: '16px',
      padding: '5px',
      borderBottom: '1px solid #f5f5f5',
      [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
        color: 'white',
        fontSize: '10px',
        lineHeight: '20px',
        borderBottom: '1px solid #0095FF',
        paddingLeft: '20px',
      },
      '&:last-child': {
        borderBottom: '0px solid #C9C9C9',
      },
      '&:hover': {
        color: '#3ea9ff',
        cursor: 'pointer',
      },
    },
  },
  socialLinksMobile: {
    display: 'none',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      display: 'block',
      position: 'absolute',
      top: '14px',
      right: '0px',
      height: '20px',
      width: '200px',
    },
  },
  iconMobile: {
    position: 'relative',
    float: 'right',
    height: '23px',
    top: '-5px',
    marginRight: '15px',
    cursor: 'pointer',
    color: '#fff',
    '&:visited': {
      color: '#fff',
    },
  },
  switchDashboard: {
    marginLeft: '3px',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      marginLeft: '11px',
    },
  },
  account: {
    marginLeft: '5px',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      marginLeft: '13px',
    },
  },
  logout: {
    marginLeft: '5px',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      marginLeft: '13px',
    },
  },
}));
const HeaderNew: FunctionComponent<RouteComponentProps> = ({
  history,
  location,
}) => {
  const { org, homePath, globalDispatch } = useStateValue();
  const [logout, setLogout] = useState(false);
  const [isMobileSearchVisible, setMobileSearchVisible] = useState(false);
  const [searchTerms, setSearchTerms] = useState<string>('');

  const [showDropDown, setShowDropDown] = useState<boolean>(false);
  const [logo, setLogo] = useState<string>('/images/logo.png');
  const [logoMobile, setLogoMobile] = useState<string>(
    '/images/text_logo_white.png'
  );
  const classes = useStyles();

  const logoutClick = (e) => {
    e.preventDefault();
    const action: GlobalAction = {
      type: GlobalActionType.LOGOUT,
      payload: {},
    };
    globalDispatch(action);
    setLogout(true);
  };

  const updateSearchTerms = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerms(event.target.value);
  };

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter' || event.type === 'click') {
      setMobileSearchVisible(false);
      event.stopPropagation();
      event.preventDefault();
      history.push(`/search-results?search=${searchTerms}`);
    }
  };

  useEffect(() => {
    const parse = queryString.parse(location.search);
    if (location.pathname !== '/search-results') {
      setSearchTerms('');
    } else {
      setSearchTerms(`${parse.search ? parse.search : ''}`);
    }
  }, [location]);

  useEffect(() => {
    if (org && org.logo) {
      setLogo(org.logo);
      setLogoMobile(org.logo);
    }
  }, [org]);

  const body = logout ? (
    <Redirect to="/login" />
  ) : (
    <div className={classes.header}>
      <div className={classes.logoDiv}>
        <Link to={`/${homePath}`}>
          <img src={logo} className={classes.logo} />
          <img src={logoMobile} className={classes.logoMobile} />
        </Link>
      </div>
      <div className={classes.searchContainerDiv}>
        <form className={classes.searchContainer}>
          <div className={classes.inputSearchMainDiv}>
            <input
              type="text"
              id="search-bar"
              className={classes.inputSearchMain}
              placeholder="Search"
              value={searchTerms}
              onChange={updateSearchTerms}
              onKeyPress={handleKeyPress}
            />
            <a
              onClick={(e) => {
                handleKeyPress(e);
              }}
            >
              <img className={classes.searchIcon} src="/images/search.svg" />
            </a>
          </div>
        </form>
      </div>
      <div
        className={
          classes.previewContainer +
          (isMobileSearchVisible ? ' ' + classes.previewContainerShow : '')
        }
      >
        <div className={classes.previewBody}>
          <form
            className={
              classes.searchContainerMobile +
              ' ' +
              (isMobileSearchVisible ? classes.searchContainerMobileShow : '')
            }
          >
            <div className={classes.inputSearchMainDiv}>
              <input
                type="text"
                id="search-bar"
                className={classes.inputSearchMain}
                placeholder="Search"
                value={searchTerms}
                onChange={updateSearchTerms}
                onKeyPress={handleKeyPress}
              />
              <a
                onClick={(e) => {
                  handleKeyPress(e);
                }}
              >
                <img className={classes.searchIcon} src="/images/search.svg" />
              </a>
            </div>
          </form>
        </div>
      </div>
      <div className={classes.socialLinks}>
        <div className={classes.socialLinksInner}>
          <a
            className={classes.logoutLink}
            onClick={() => {
              setShowDropDown(true);
            }}
          >
            <i className="fa fa-user fa-lg" />
          </a>
        </div>
      </div>
      {showDropDown && (
        <div
          className={classes.square}
          onMouseLeave={() => {
            setShowDropDown(false);
          }}
        >
          <ul>
            <li onClick={logoutClick}>
              <i className="fa fa-sign-out fa-md" />{' '}
              <span className={classes.logout}>Logout</span>
            </li>
          </ul>
        </div>
      )}
      {showDropDown && (
        <div
          className={classes.backSquare}
          onClick={() => {
            setShowDropDown(false);
          }}
        />
      )}
      {!isMobileSearchVisible && (
        <div className={classes.socialLinksMobile}>
          <a
            className={classes.iconMobile}
            href="#0"
            onClick={() => {
              setShowDropDown(true);
            }}
          >
            <i className="fa fa-bars" />
          </a>
          <a
            className={classes.iconMobile}
            href="#0"
            onClick={() => {
              setMobileSearchVisible(true);
            }}
          >
            <i className="fa fa-search" aria-hidden="true"></i>
          </a>
        </div>
      )}
    </div>
  );
  return body;
};

export default withRouter(HeaderNew);
