import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import Body from '../layout/body';
import { Redirect, Switch } from 'react-router-dom';
import { Route, useHistory, useParams, useRouteMatch } from 'react-router';
import ActionItems from './action-items';
import ResultViewer from '../result/result';
import {
  ActionItemDescriptor,
  ActionItemsParams,
  DashboardContext,
  Result,
  ResultType,
} from '../../common/types';
import { useStateValue } from '../../hoc/state';
import ActionItemsHeader from './action-items-header';
import axiosInst, { axiosWrapper } from '../../common/axios';
import useDashboardApi from '../../apis/dashboard-apis';

const buildDescriptorFromResults = (
  results: Result[],
  context: DashboardContext,
  checkupCode: string
): ActionItemDescriptor[] => {
  return results
    .map((result) => {
      const checkupResult =
        context.userDashboardProgress.progress.checkups[checkupCode].results[
          result.code
        ];

      return { checkupCode, checkupResult, result };
    })
    .filter((item) => item.checkupResult);
};

const ActionItemGroup: FunctionComponent = () => {
  const params = useParams<ActionItemsParams>();
  const match = useRouteMatch();
  const history = useHistory();
  const { dashboardContext, homePath, currentDashboardId } = useStateValue();
  const [resultId, setResultId] = useState<string>('');
  const [results, setResults] = useState<ActionItemDescriptor[]>([]);
  const resultsRef = useRef<Result[] | null>(null);
  const { saveResultProgress } = useDashboardApi();

  if (!dashboardContext || !currentDashboardId) {
    return <Redirect to={`/${homePath}`} />;
  }

  const handleItemChecked = (result: Result, value: boolean) => {
    saveResultProgress(params.checkupCode, result.id, result.code, value);
  };

  const handleResultsSuccess = (results: Result[]) => {
    resultsRef.current = results;
    setResults(
      buildDescriptorFromResults(results, dashboardContext, params.checkupCode)
    );
  };

  useEffect(() => {
    axiosWrapper<Result[]>(
      axiosInst.get(`checkups/${params.checkupCode}/results`),
      ({ data }) => handleResultsSuccess(data),
      (e, errorMessage) => {}
    );
  }, []);

  if (!match) {
    return null;
  }

  useEffect(() => {
    if (params.resultType && params.resultType === ResultType.RESULT) {
      setResultId(history.location.pathname.split('/')[4]);
    } else if (!params.resultType) {
      setResultId('');
    }
  }, [history.location]);

  useEffect(() => {
    if (dashboardContext && resultsRef.current) {
      setResults(
        buildDescriptorFromResults(
          resultsRef.current,
          dashboardContext,
          params.checkupCode
        )
      );
    }
  }, [dashboardContext]);

  return (
    <Body>
      <ActionItemsHeader
        pathId={currentDashboardId}
        resultType={params.resultType}
        checkupCode={params.checkupCode}
        resultId={resultId}
        results={results}
        onCompletedCLick={handleItemChecked}
      />
      <Switch>
        <Route exact path={match.path}>
          <ActionItems checkupCode={params.checkupCode} results={results} />
        </Route>
        <Route path={`${match.path}/:resultCode`}>
          <ResultViewer />
        </Route>
      </Switch>
    </Body>
  );
};

export default ActionItemGroup;
