import React, { Fragment } from 'react';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core';
import { CustomBreakPoints } from '../../../common/types';

const styles = (theme: Theme) =>
  createStyles({
    modalShade: {
      position: 'fixed',
      top: '65px',
      left: '0px',
      width: '100%',
      height: '100%',
      backgroundColor: '#ffffff',
      opacity: 1,
      zIndex: 50,
    },
    modalBody: {
      margin: '0 auto',
      position: 'relative',
      width: '50%',
      [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
        width: '100%',
      },
      height: '100%',
      minHeight: '100%',
      backgroundColor: '#ffffff',
      zIndex: 51,
      borderRadius: '0px',
      color: 'black',
    },
    modalContentHolder: {
      top: '65px',
      left: '0px',
      width: '100%',
      height: 'calc(100% - 65px)',
      [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
        height: 'calc(100% - 50px)',
      },
      overflow: 'hidden',
      overflowY: 'scroll',
    },

    modalContent: {
      width: '100%',
      height: '100%',
    },
    modalClose: {
      position: 'absolute',
      zIndex: 52,
      right: '57px',
      top: '50px',
      width: '25px',
      height: '25px',
      backgroundColor: '#2E2E2E',
      color: '#fff',
      fontWeight: 'bold',
      fontSize: '20px',
      textAlign: 'center',
      borderRadius: '2px',
      cursor: 'pointer',
      '-webkit-transition': '-webkit-transform .4s ease-in-out',
      transition: 'transform .4s ease-in-out',
      '&:hover': {
        opacity: 0.6,
      },
    },
    visible: {
      visibility: 'visible',
      opacity: 1,
      transition: 'opacity .1s linear',
    },
    hidden: {
      visibility: 'hidden',
      opacity: 0,
      transition: 'visibility 0s .5s, opacity .5s linear',
    },
    blur: {
      '-webkit-filter': 'blur(10px)',
      '-moz-filter': 'blur(10px)',
      '-o-filter': 'blur(10px)',
      '-ms-filter': 'blur(10px)',
      filter:
        "url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='svgMask'><feGaussianBlur stdDeviation='10' /></filter></svg>#svgMask\")",
    },
  });

interface Props extends WithStyles<typeof styles> {
  blur: boolean;
}

const Dialogue: React.FunctionComponent<Props> = (props) => {
  const { classes, blur } = props;
  const blurClass = blur ? ' ' + classes.blur : '';

  return (
    <Fragment>
      <div className={classes.modalShade} />
      <div className={classes.modalBody + blurClass}>
        <div className={classes.modalContentHolder}>
          <div className={classes.modalContent}>{props.children}</div>
        </div>
      </div>
    </Fragment>
  );
};

export default withStyles(styles)(Dialogue);
