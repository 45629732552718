import { Dispatch, FunctionComponent, RefObject } from 'react';

declare global {
  interface Window {
    Stripe: any;
  }
}

export interface User {
  id: number;
  email: string;
  fullName: string;
  termsAcceptedOn: Date;
  token: string;
  refreshToken: string;
  emailVerified: boolean;
  awsIdentityId: string;
  awsToken: string;
  organization: Organization;
}

export interface ModalT {
  content: FunctionComponent | null;
  allowClose?: boolean;
  on: boolean;
}

export enum ModalType {
  PathDetails = 'PATH_DETAILS',
  Task = 'TASK',
}

export interface ModalNew<T> {
  type?: ModalType | null;
  open: boolean;
  data?: T;
}

export interface ModalNewContent {
  backgroundClicked: boolean;
  content: ModalNew<any>;
}

export interface LoginState {
  isLoading: boolean;
  userData?: { email: string; password: string };
}

export enum GlobalActionType {
  AUTH_VERIFIED,
  CLEAR_USER,
  LOGIN_SUCCESS,
  LOGOUT,
  UPDATE_MODAL,
  UPDATE_MODAL_GLOBAL,
  UPDATE_SCREEN_DIMENSIONS,
  SHOW_ERROR_MODAL,
  SAVE_RESULT_PROGRESS,
  FETCH_DASHBOARD_CONTEXT,
  FETCH_DASHBOARD_ERROR,
  UPDATE_DASHBOARD_ID,
  SAVE_TOTAL_PROGRESS,
  TERMS_ACCEPTED,
  IS_KEYBOARD_OPEN_MOBILE,
  TRACK_EVENT,
  SYNCED_EVENTS,
  FETCHED_ALL_DASHBOARDS,
  FETCHED_DASHBOARDS_ERROR,
  SET_ORG,
}

export interface Context {
  isAuthVerified: boolean;
  isAuthenticated: boolean;
  isTermsAccepted: boolean;
  org: Organization | null | undefined;
  user: User | null | undefined;
  homePath;
  dashboardContext?: DashboardContext | null | undefined;
  currentDashboardId: number | undefined | null;
  totalProgress?: number;
  modal?: ModalT | null;
  modalNew?: ModalNew<any> | null;
  globalDispatch: Dispatch<GlobalAction>;
  globalPercentageValues: any;
  screenDimensions?: ScreenDimensions;
  isKeyboardOpenMobile?: boolean;
  events: Event[];
  dashboardsList: DashboardGroup[];
}

export interface Organization {
  code: string;
  name?: string;
  homeHeader?: string;
  homeDescription?: string;
  homeVideo?: string;
  logo?: string;
  backgroundColor?: string;
  domain?: string;
  showLandingPage: boolean;
  loginLogo?: string;
  loginImage?: string;
  loginHeader?: string;
  loginDescription?: string;
}

export interface Event {
  type: EventType;
  eventOn?: Date;
  data: any;
}

export enum EventType {
  LOGIN,
  CHECKUP_COMPLETE,
  RESULT_COMPLETE,
  DASHBOARD_SELECTION,
  RESULT_SELECTION,
  STRING_SEARCH,
  TAG_SEARCH,
  RESOURCE_DOWNLOAD,
  RESOURCE_EXTERNAL_LINK,
  DASHBOARD_COMPLETED,
}

export interface GlobalAction {
  type: GlobalActionType;
  payload: GlobalActionPayload;
}

export interface ResultProgressAction {
  resultCode: string | null;
  checkupCode: string;
  complete: boolean;
}

export interface GlobalActionPayload {
  user?: User;
  dashboardContext?: DashboardContext;
  currentDashboardId?: number;
  resultProgress?: ResultProgressAction;
  totalProgress?: number;
  modal?: ModalT;
  modalNew?: ModalNew<any>;
  screenDimensions?: ScreenDimensions;
  isKeyboardOpenMobile?: boolean;
  event?: Event;
  dashboardsList?: any;
  org?: Organization;
}

export enum DraftJsBlockType {
  H1 = 'header-one',
  H2 = 'header-two',
  H3 = 'header-three',
  H4 = 'header-four',
  H5 = 'header-five',
  H6 = 'header-six',
  UNSTYLED = 'unstyled',
  ORDERED_LIST = 'ordered-list-item',
  UNORDERED_LIST = 'unordered-list-item',
  CODE_BLOCK = 'code-block',
}

export interface InlineStyleRange {
  offset: number;
  length: number;
  style: string;
}

export interface EntityRange {
  offset: number;
  length: number;
  key: number;
}

export interface DraftJsBlock {
  key: string;
  text: string;
  type: DraftJsBlockType;
  depth: number;
  inlineStyleRanges: InlineStyleRange[];
  entityRanges: EntityRange[];
}

export interface DraftJsEntityData {
  href: string;
  url: string;
}

export interface DraftJsEntity {
  type: string;
  mutability: string;
  data: DraftJsEntityData;
}

export interface DraftJsEntityMap {
  [key: string]: DraftJsEntity;
}

export interface DraftJs {
  blocks: DraftJsBlock[];
  entityMap: DraftJsEntityMap;
}

export interface Result extends Task {
  id: number;
  layout: DraftJs;
  videos: string[];
  resultDownloads: ResultDownload[];
  resultLinks: ResultLink[];
}

export interface Task {
  code: string;
  title: string;
  description: string;
  image: string;
  completionTime: string;
}

export interface ResultDownload {
  download: Download;
}

export interface TitleDescriptionImage {
  title: string;
  description?: string;
  image?: string;
}

export interface Download extends TitleDescriptionImage {
  id: number;
  filename: string;
}

export interface ResultLink {
  link: Link;
}

export interface Link extends TitleDescriptionImage {
  id: number;
  code: string;
  url: string;
}

export interface ResultResourcesProps {
  result: Result;
}

export interface ResultResourceItemProps {
  titleDescriptionImage: TitleDescriptionImage;
  url?: string;

  onclick?(event: any);
}

export interface Wheel {
  name: string;
  children: any[];
}

export interface Progress {
  dashboardId: number;
  checkups: { [code: string]: Checkup };
}

export interface CheckupResults {
  [code: string]: CheckupResult;
}

export interface Checkup {
  resultsCountTowardsPercentage: boolean;
  displayed: boolean;
  locked: boolean;
  id: number;
  code: string;
  questionsList: any;
  answers: any;
  results: CheckupResults;
  resultList: number[];
}

export interface CheckupResult {
  completed: boolean;
}

export interface UserDashboardProgress {
  id: number;
  progress: Progress;
}

export interface DashboardContext {
  wheel: Wheel;
  userDashboardProgress: UserDashboardProgress;
  currentDashboard: any;
}

export interface ResultVideoProps {
  video?: string;
  videoExternal?: string;
}

export interface ResultPanelItemProps {
  block: DraftJsBlock;
  entityMap: DraftJsEntityMap;
}

export interface ResultPanelHeaderProps {
  text: string;
}

export interface ResultPanelItemListProps {
  blocks: DraftJsBlock[];
  entityMap: DraftJsEntityMap;
}

export const CustomBreakPoints = {
  mobile: 760,
};

export enum ResultType {
  TASK = 'task',
  RESULT = 'result',
}

export interface ActionItemsParams {
  checkupCode: string;
  resultType?: string;
}

export interface ActionItemsProps {
  checkupCode: string;
  resultType?: string;
  results: ActionItemDescriptor[];
}

export interface ResultParams {
  resultCode: string;
}

export interface RegistrationContext {
  state: RegistrationState;
  dispatch: Dispatch<RegistrationActions>;
}

interface RegistrationUser {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export interface RegistrationState {
  email?: string;
  paymentPlan?: RegistrationAccessCode;
  user?: RegistrationUser;
  steps?: string[];
  paymentToken?: any;
  currentStep?: number;
  stepToRemove?: string;
  ipAuthId?: number | null;
}

export interface RegistrationActions {
  type: RegistrationActionType;
  payload: RegistrationState;
}

export enum RegistrationActionType {
  INIT,
  UPDATE_PRODUCT_PLAN,
  NEXT_PAGE,
  REMOVE_STEP,
  UPDATE_USER,
  UPDATE_TOKEN,
}

export interface AccessCode {
  id: number;
  name: string;
  productPlan?: ProductPlan;
}

export interface RegistrationAccessCode {
  accessCodeId: number;
  cost: number;
  paymentRequired: boolean;
}

export interface ProductPlan {
  id: number;
  type: string;
}

export interface RegistrationChild {
  onContinue(): void;
}

export interface FormField<T> {
  value: T;
  label?: string;
  validationTypes?: FormFieldValidator[];
  error?: string | null | undefined;
  htmlAttributes?: any;
}

export interface FormFieldValidator {
  type: FormFieldValidationType;
  values?: any;
  validator: (
    formField: FormField<any>,
    values: any,
    form: Form
  ) => string | null;
}

export enum FormFieldValidationType {
  REQUIRED,
  EMAIL,
  FIELDS_MATCH,
  MIN_LENGTH,
  MAX_LENGTH,
}

export interface Form {
  [key: string]: FormField<any>;
}

export interface InputProperties {
  width?: string;
  formField: FormField<string>;
  name: string;
  htmlAttributes?: any;

  onChange(name: string, value: string, formField: FormField<string>);

  onBlur?(name: string, formField: FormField<string>);
}

export interface VerificationCodeParams {
  length?: number;

  onChange(value: string | null);
}

export interface RegisterUserRequest {
  user: RegistrationUser;
  paymentToken: string | null;
  accessCodeId: number | null;
}

export interface CreditCardFormParams {
  onFormChange(complete: boolean);
}

export interface FormFieldsParams {
  form: Form;

  onChange(name: string, value: string, formField: FormField<string>);

  onBlur?(name: string, formField: FormField<string>);
}

export interface ScreenDimensions {
  clientHeight: number;
  clientWidth: number;
  isMobile: boolean;
}

export interface PathSlidesParams {
  dashboards: DashboardGroup;
}

export interface PathSlideParams {
  index: number;
  dashboard: Dashboard;
  mobile?: boolean;

  onClick();
}

export interface LandingTasksParams {
  tasks: Task[];
}

export interface TaskCardProps {
  task: Task;
  type: ResultType;
  titleColor?: string;
  completed?: boolean;
  small?: boolean;
}

export interface LandingHeaderParams {
  organization: Organization;
}

export interface DashboardGroup {
  name?: string;
  dashboards: Dashboard[];
}

export interface Dashboard {
  id: number;
  description?: string;
  name?: string;
  icon?: string;
  image?: string;
  video?: string;
  completionTime?: string;
}

export interface ResultPanelProps {
  result: Result;
  additionalCards?: AdditionalResultCards;
  hideRightPanel?: boolean;
  floatCards?: boolean;
}

export interface AdditionalResultCards {
  [tasks: number]: Task | ResultResourceItemProps;
}

export enum HomePaths {
  DEFAULT = 'path',
  DASHBOARD = 'dashboard',
}

export interface BodyGroupProps {
  background?: string;
  className?: string;
  scrollRef?: RefObject<HTMLDivElement>;
}

export interface ActionItemDescriptor {
  checkupCode: string;
  checkupResult: CheckupResult;
  result: Result;
}

export interface PathParams {
  pathId: string;
}

export interface ActionItemsHeaderProps extends ActionItemsProps {
  pathId: number;
  resultId?: string;

  onCompletedCLick(result: Result, value: boolean);
}

export enum ActionItemHeaderButtonType {
  OUTLINED,
  COMPLETED,
  BLUE,
  MOBILE,
}

export interface ActionItemHeaderButtonProps {
  text: string;
  type: ActionItemHeaderButtonType;

  onClick?();

  showCheck?: boolean;
  showArrow?: boolean;
  showArrowEnd?: boolean;
}

export interface TaskSearchParams {
  search: string;
}

export interface TaskSearchResult {
  tags: string[];
  results: Result[];
}

export interface CompletionTimeOverlayProps {
  time: string;
  className?: string;
}

export interface AssetSearch {
  linkCodes: string[];
  resultCodes: string[];
}

export interface AssetSearchResponse {
  links: Link[];
  results: Result[];
}

export interface ActionItemsCardHeaderProps {
  results: ActionItemDescriptor[];
  checkupCode: string;
}

export interface ResultLinkProps {
  url: string;
  text: string;
  external?: boolean;
  download?: boolean;
  task?: boolean;
}

export interface DownloadLinkResult {
  url: string;
}
