import React, { FunctionComponent, memo } from 'react';
import { ResultPanelItemProps } from '../../../common/types';
import { makeStyles } from '@material-ui/core';
import ResultPanelItemStyledText from '../../../components/universal/universal-styled-text';

const useStyles = makeStyles((theme) => ({
  articleStandardContent: {
    lineHeight: '1.688rem',
    paddingBottom: '1.063rem',
  },
}));

export const ResultPanelItemDefault: FunctionComponent<
  ResultPanelItemProps
> = ({ block, entityMap }) => {
  const classes = useStyles();

  return (
    <div key={block.key} className={classes.articleStandardContent}>
      <ResultPanelItemStyledText block={block} entityMap={entityMap} />
    </div>
  );
};

export default memo(ResultPanelItemDefault);
