import React, { FunctionComponent } from 'react';
import {
  Dashboard,
  EventType,
  GlobalActionType,
  ModalType,
  PathSlidesParams,
} from '../../common/types';
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slider,
} from 'pure-react-carousel';
import PathSlide from './path-slide';
import { makeStyles } from '@material-ui/core';
import { useStateValue } from '../../hoc/state';

const useStyles = makeStyles((theme) => ({
  middleSlider: {
    width: '100%',
  },
  pathsHeaderRow: {
    marginTop: '20px',
    marginBottom: '10px',
    width: '100%',
    display: 'flex',
  },
  pathsHeader: {
    width: '50%',
    color: '#347CB1',
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  pathsButtonsWrapper: {
    width: '50%',
    display: 'flex',
    justifyContent: 'flex-end',
    '& > button:first-child': {
      marginRight: '5px',
    },
  },
  button: {
    color: '#707070',
    width: '34px',
    height: '34px',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    borderRadius: '34px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #707070',
    backgroundColor: '#fff',
  },
}));

const LandingPaths: FunctionComponent<PathSlidesParams> = ({ dashboards }) => {
  const { globalDispatch } = useStateValue();
  const classes = useStyles();

  const handleClick = (dashboard: Dashboard) => {
    globalDispatch({
      type: GlobalActionType.UPDATE_MODAL_GLOBAL,
      payload: {
        modalNew: {
          type: ModalType.PathDetails,
          open: true,
          data: dashboard,
        },
      },
    });
    globalDispatch({
      payload: {
        event: {
          type: EventType.DASHBOARD_SELECTION,
          data: {
            dashboardId: dashboard.id,
          },
        },
      },
      type: GlobalActionType.TRACK_EVENT,
    });
  };

  return (
    <div>
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={70}
        totalSlides={dashboards.dashboards.length}
        visibleSlides={3.4}
      >
        <div className={classes.pathsHeaderRow}>
          <div className={classes.pathsHeader}>{dashboards.name}</div>
          <div className={classes.pathsButtonsWrapper}>
            <ButtonBack className={classes.button}>{'<'}</ButtonBack>
            <ButtonNext className={classes.button}>{'>'}</ButtonNext>
          </div>
        </div>
        <div className={classes.middleSlider}>
          <Slider>
            {dashboards.dashboards.map((dl, idx) => (
              <PathSlide
                key={idx}
                index={idx}
                dashboard={dl}
                onClick={() => handleClick(dl)}
              />
            ))}
          </Slider>
        </div>
      </CarouselProvider>
    </div>
  );
};

export default LandingPaths;
