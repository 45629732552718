import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core';
import { ResultResourceItemProps } from '../../../common/types';
import { useNestedRoute } from '../../../hoc/location-hooks';
import { Link } from 'react-router-dom';
import { getCodeFromUrl, isTaskLink } from '../../../common/draftJsUtils';

const useStyles = makeStyles((theme) => ({
  resource: {
    width: '290px',
    height: '120px',
    borderRadius: '2px 0px 0px 2px',
    boxShadow: '0px 3px 6px #00000029',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      boxShadow: '0px 3px 6px #00000050',
    },
  },
  resourceLeft: {
    width: '120px',
    height: '120px',
  },
  resourceRight: {
    width: '170px',
    paddingLeft: '.8rem',
    paddingRight: '5px',
  },
  image: {
    width: '120px',
    height: '120px',
  },
  title: {
    color: '#347CB1',
    fontSize: '1rem',
    fontWeight: 'bold',
    marginBottom: '6px',
  },
  description: {
    color: '#717171',
    fontSize: '.8rem',
  },
}));

const ResultResourceItem: FunctionComponent<ResultResourceItemProps> = ({
  titleDescriptionImage,
  url,
  onclick,
}) => {
  const classes = useStyles();
  const urlRoot = useNestedRoute();
  let taskCode: null | string = null;
  if (url && isTaskLink(url)) {
    taskCode = getCodeFromUrl(url);
  }

  const body = (
    <>
      <div className={classes.resourceLeft}>
        <img
          className={classes.image}
          src={titleDescriptionImage.image}
          alt={titleDescriptionImage.title}
        />
      </div>
      <div className={classes.resourceRight}>
        <div className={classes.title}>{titleDescriptionImage.title}</div>
        <div className={classes.description}>
          {titleDescriptionImage.description}
        </div>
      </div>
    </>
  );

  return taskCode ? (
    <Link to={`${urlRoot}/task/${taskCode}`} className={classes.resource}>
      {body}
    </Link>
  ) : (
    <a
      className={classes.resource}
      href={url || '#0'}
      target="_bank"
      onClick={onclick}
      onTouchStart={onclick}
    >
      {body}
    </a>
  );
};

export default ResultResourceItem;
