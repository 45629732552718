import { createMuiTheme } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { useStateValue } from '../hoc/state';
import { withRouter } from 'react-router';

const CustomThemeProvider: FunctionComponent<RouteComponentProps> = (props) => {
  const { children } = props;
  const { org } = useStateValue();
  const theme = createMuiTheme({
    palette: {
      background: {
        default: '#F9F9F9',
      },
      primary: {
        main: '#0095FF',
      },
      secondary: {
        main: org && org.backgroundColor ? org.backgroundColor : '#337AB4',
      },
    },
    overrides: {
      MuiButton: {
        textPrimary: {
          color: '#fff',
          background: '#0095FF',
        },
      },
    },
  });
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default withRouter(CustomThemeProvider);
