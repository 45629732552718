import React, { createContext, useContext, useEffect, useReducer } from 'react';
import globalReducer from '../common/global-reducer';
import {
  Context,
  CustomBreakPoints,
  GlobalActionType,
  HomePaths,
  ModalType,
  ScreenDimensions,
} from '../common/types';
import { loginWithRefreshToken, syncEvents } from '../apis/user-apis';
import { TermsModal } from '../components/UI/modal/terms-modal';
import { useInterval } from './hooks';
import queryString from 'query-string';
import { useHistory } from 'react-router';
import { closeModalState } from '../components/UI/modal/modal-utils';

// const currentDashboardIdStored =
//   localStorage.getItem('currentDashboardId') || [];
// const currentDashboardIdSplit = String(currentDashboardIdStored).split(
//   '*$*678!'
// )[1];
// const currentDashboardId = currentDashboardIdSplit
//   ? Number(currentDashboardIdSplit)
//   : null;
const defaultState: Partial<Context> = {
  isAuthenticated: false,
  isAuthVerified: false,
  isTermsAccepted: false,
  homePath: HomePaths.DEFAULT,
  // currentDashboardId: currentDashboardId,
  events: [],
  dashboardsList: [],
};
var windowWidthHeld = 0;

export const StateContext = createContext(defaultState as Context);

export const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(globalReducer, defaultState as Context);
  const history = useHistory();

  useInterval(() => {
    if (state.user && state.events && state.events.length > 0) {
      syncEvents(state.events, dispatch);
    }
  }, 90000);

  useEffect(() => {
    const token = sessionStorage.getItem('refreshToken');
    if (token != null) {
      loginWithRefreshToken(token, dispatch);
    } else {
      dispatch({ type: GlobalActionType.AUTH_VERIFIED, payload: {} });
    }
  }, []);

  useEffect(() => {
    if (
      !state.isTermsAccepted &&
      state.isAuthenticated &&
      state.user &&
      state.user.emailVerified
    ) {
      dispatch({
        type: GlobalActionType.UPDATE_MODAL,
        payload: { modal: TermsModal },
      });
    }
  }, [state.isTermsAccepted, state.isAuthenticated]);

  //Determine Global screen size specs.
  useEffect(() => {
    // first: determine client height and mobile environment
    const doc: Document = window.document;
    const domElement = doc.getElementById('root');
    if (domElement) {
      const screenDimensions: ScreenDimensions = {
        clientHeight: domElement.clientHeight,
        clientWidth: domElement.clientWidth,
        isMobile: domElement.clientWidth < CustomBreakPoints.mobile,
      };
      dispatch({
        type: GlobalActionType.UPDATE_SCREEN_DIMENSIONS,
        payload: { screenDimensions: screenDimensions },
      });
    }
    // second: determine client height and mobile environment
    windowWidthHeld = window.innerWidth;
    const handleResize = () => {
      if (
        (window.innerWidth > CustomBreakPoints.mobile &&
          windowWidthHeld < CustomBreakPoints.mobile) ||
        (window.innerWidth < CustomBreakPoints.mobile &&
          windowWidthHeld > CustomBreakPoints.mobile)
      ) {
        window.location.reload();
      }
      windowWidthHeld = window.innerWidth;

      // this handles global mobile keyboard resizing events.
      // For now only in one direction to avoid flickering.
      if (doc.activeElement) {
        const nodeType: string | null = doc.activeElement.getAttribute('type');
        if (nodeType === 'text' || nodeType === 'password') {
          // Logic for while keyboard is shown
          dispatch({
            type: GlobalActionType.IS_KEYBOARD_OPEN_MOBILE,
            payload: { isKeyboardOpenMobile: true },
          });
        } else {
          // Logic for while keyboard is hidden
        }
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const querySearch = queryString.parse(location.search);
    if (querySearch.task) {
      dispatch({
        type: GlobalActionType.UPDATE_MODAL_GLOBAL,
        payload: {
          modalNew: {
            type: ModalType.Task,
            open: true,
            data: querySearch.task,
          },
        },
      });
    } else if (
      state.modalNew &&
      state.modalNew.open &&
      state.modalNew.type === ModalType.Task
    ) {
      dispatch(closeModalState);
    }
  }, [history.location]);

  return (
    <StateContext.Provider value={{ ...state, globalDispatch: dispatch }}>
      {state.isAuthVerified ? children : null}
    </StateContext.Provider>
  );
};

export const useStateValue = () => useContext(StateContext);
