import React, { FormEvent, FunctionComponent, useState } from 'react';
import { withRouter } from 'react-router';
import { Button, makeStyles } from '@material-ui/core';
import VerificationCode from '../../components/UI/input/verification-code';

const useStyles = makeStyles((theme) => ({
  formRoot: {
    '& > div': {
      marginBottom: '1rem',
    },
  },

  codeText: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '1.5rem',
  },

  codeRoot: {
    textAlign: 'center',
    '& input': {
      fontSize: '2rem',
      width: '2.1rem',
      marginRight: '1rem',
      outline: '0',
      borderWidth: '0 0 2px',
      borderColor: '#0095FF',
      backgroundColor: 'rgba(0,0,0,0)',
      textAlign: 'center',
    },
    '& input:last-child': {
      marginRight: '0',
    },
  },
}));

const ResetPasswordPin: FunctionComponent<any> = ({ onContinue }) => {
  const classes = useStyles();
  const [code, setCode] = useState<string | null>(null);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (code) {
      onContinue(code);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.formRoot}>
        <div className={classes.codeText}>
          Please Check Your Email and Enter Your Code
        </div>
        <VerificationCode onChange={setCode} />
        <div>
          <Button type="submit" fullWidth variant="contained" color="primary">
            VERIFY
          </Button>
        </div>
      </div>
    </form>
  );
};

export default withRouter(ResetPasswordPin);
