import React, {
  FormEvent,
  FunctionComponent,
  useEffect,
  useState,
} from 'react';
import CreditCardForm from '../../components/payment/credit-card';
import { Button, makeStyles } from '@material-ui/core';
import { useStripe } from '../../components/payment/stripe-hook-provider';
import { RegistrationChild } from '../../common/types';
import { useRegistrationApi, useStripeTokenApi } from './registration-api';

const useStyles = makeStyles((theme) => ({
  formRoot: {
    '& > div': {
      marginBottom: '1rem',
    },
  },

  codeText: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '1.4rem',
  },

  descriptionText: {
    textAlign: 'center',
    fontSize: '.9rem',
    color: '#656565',
    lineHeight: '1.6rem',
  },

  error: {
    color: '#f44336',
    marginTop: '.2rem',
    fontSize: '.8rem',
  },
}));

const Payment: FunctionComponent<RegistrationChild> = ({ onContinue }) => {
  const classes = useStyles();
  const stripe = useStripe();
  const { success, updateToken, error, saving } = useStripeTokenApi();
  const [formComplete, setFormComplete] = useState(false);
  const { getCost } = useRegistrationApi();

  useEffect(() => {
    if (success) {
      onContinue();
    }
  }, [success]);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (formComplete) {
      const { token } = await stripe[0].createToken();
      if (token) {
        await updateToken(token);
      }
    }
  };

  const handleFormChange = (complete: boolean) => {
    setFormComplete(complete);
  };

  const errorDiv = error ? <div className={classes.error}>{error}</div> : null;

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.formRoot}>
        <div className={classes.codeText}>One more thing</div>
        <div
          className={classes.descriptionText}
        >{`$${~~getCost}/month. Cancel anytime.`}</div>
        <CreditCardForm onFormChange={handleFormChange} />
        {errorDiv}
        <div>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={saving}
          >
            Continue
          </Button>
        </div>
      </div>
    </form>
  );
};

export default Payment;
