import { useStateValue } from '../../hoc/state';
import { useEffect, useState } from 'react';
import {
  RegisterUserRequest,
  RegistrationAccessCode,
  RegistrationActionType,
} from '../../common/types';
import { useRegistrationValue } from './registration-provider';
import { fetchProductPlanByAccessCodeById } from '../../apis/payment-api';
import {
  fetchIPAuthCheck,
  loginSuccessCallback,
  registerUser,
  resetPassword,
  resetPasswordPinGen,
  verifyVerificationCode,
} from '../../apis/user-apis';

export const useRegistrationApi = () => {
  const { state, dispatch } = useRegistrationValue();
  const { isAuthenticated, user } = useStateValue();
  const [saving, setSaving] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const buildSteps = async (
    accessCode: RegistrationAccessCode | null,
    isIPAuthorized: boolean
  ) => {
    let steps = ['access-code', 'user-details', 'payment', 'verification-code'];
    if (isAuthenticated && user && !user.emailVerified) {
      steps = ['verification-code'];
      dispatch({
        type: RegistrationActionType.UPDATE_USER,
        payload: {
          user: {
            firstName: '',
            lastName: '',
            password: '',
            email: user.email,
          },
        },
      });
    } else if (isIPAuthorized) {
      steps = steps.filter((s) => s !== 'access-code');
      steps = steps.filter((s) => s !== 'payment');
    } else if (accessCode) {
      steps = steps.filter((s) => s !== 'access-code');
      if (!accessCode.paymentRequired) {
        steps = steps.filter((s) => s !== 'payment');
      }
    }
    return steps;
  };

  const getCost = state.paymentPlan ? state.paymentPlan.cost : 0;

  const getEmail = state.user ? state.user.email : 'email';

  const isIPAuthorized = state.ipAuthId ? true : false;

  const isPaymentRequired = state.paymentPlan
    ? state.paymentPlan.paymentRequired
    : true;

  const init = async (accessCode: string | undefined | null) => {
    let foundAccessCode: null | RegistrationAccessCode = null;
    const ipAuth = await fetchIPAuthCheck();
    let ipAuthId = null;
    if (ipAuth && ipAuth.ipAuthRestriction) {
      ipAuthId = ipAuth.ipAuthRestriction;
    }
    if (accessCode) {
      try {
        foundAccessCode = await fetchProductPlanByAccessCodeById(accessCode);
        dispatch({
          payload: { paymentPlan: foundAccessCode },
          type: RegistrationActionType.UPDATE_PRODUCT_PLAN,
        });
      } catch (e) {}
    }
    const steps = await buildSteps(foundAccessCode, ipAuthId ? true : false);
    dispatch({
      payload: { steps, ipAuthId },
      type: RegistrationActionType.INIT,
    });
  };

  const nextStep = () => {
    dispatch({
      payload: {},
      type: RegistrationActionType.NEXT_PAGE,
    });
  };

  const registerNewUser = async (
    user,
    paymentToken = null,
    accessCodeId = null
  ) => {
    setSaving(true);
    setError(null);
    if (user) {
      const request: RegisterUserRequest = {
        paymentToken: paymentToken
          ? paymentToken
          : state.paymentToken
          ? state.paymentToken
          : null,
        user: user,
        accessCodeId: accessCodeId
          ? accessCodeId
          : state.paymentPlan
          ? state.paymentPlan.accessCodeId
          : null,
      };
      try {
        await registerUser(request);
        setSuccess(true);
      } catch (e) {
        setError('Error registering user.');
      } finally {
        setSaving(false);
      }
    }
    setSaving(false);
  };
  return {
    state,
    nextStep,
    init,
    getCost,
    getEmail,
    isIPAuthorized,
    registerNewUser,
    isPaymentRequired,
  };
};

export const useAccessCodeApi = () => {
  const { dispatch } = useRegistrationValue();
  const [fetching, setFetching] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchCode = async (code: string) => {
    setError(null);
    setFetching(true);

    try {
      const paymentPlan = await fetchProductPlanByAccessCodeById(code);
      if (!paymentPlan.paymentRequired) {
        dispatch({
          payload: { stepToRemove: 'payment' },
          type: RegistrationActionType.REMOVE_STEP,
        });
      }
      dispatch({
        payload: { paymentPlan },
        type: RegistrationActionType.UPDATE_PRODUCT_PLAN,
      });
      setSuccess(true);
    } catch (error) {
      setError('Code Not Found');
    } finally {
      setFetching(false);
    }
  };

  return { fetching, success, error, fetchCode };
};

export const useStripeTokenApi = () => {
  const { state, dispatch } = useRegistrationValue();
  const [saving, setSaving] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const updateToken = async (paymentToken: any) => {
    setSaving(true);
    setError(null);
    dispatch({
      payload: { paymentToken },
      type: RegistrationActionType.UPDATE_TOKEN,
    });
    if (state.user && state.paymentPlan) {
      const request: RegisterUserRequest = {
        paymentToken,
        user: state.user,
        accessCodeId: state.paymentPlan.accessCodeId,
      };
      try {
        await registerUser(request);
        setSuccess(true);
      } catch (e) {
        setError('Error processing card.  Please correct card and try again.');
      } finally {
        setSaving(false);
      }
    }
    setSaving(false);
  };

  return { updateToken, success, error, saving };
};

export const useVerificationCodeApi = () => {
  const [fetching, setFetching] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { state } = useRegistrationValue();
  const { globalDispatch } = useStateValue();

  useEffect(() => {}, []);

  const verifyCode = async (code: string) => {
    setError(null);
    setFetching(true);
    try {
      if (state.user) {
        const response = await verifyVerificationCode(state.user.email, code);
        loginSuccessCallback(response, globalDispatch);
      }
    } catch (e) {
      setError('Invalid Code');
      setFetching(false);
    }
  };

  return { fetching, verifyCode, success, error };
};

export const useResetPasswordPinGenApi = () => {
  const [fetching, setFetching] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {}, []);

  const emailCode = async (email: string) => {
    setSuccess(false);
    setError(null);
    setFetching(true);
    try {
      await resetPasswordPinGen(email);
      setSuccess(true);
    } catch (e) {
      setError('Something went wrong with your request');
    } finally {
      setFetching(false);
    }
  };

  return { fetching, emailCode, success, error };
};

export const useResetPasswordApi = () => {
  const [fetching, setFetching] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {}, []);

  const resetPw = async (email: string, code: string, password: string) => {
    try {
      const success = await resetPassword(email, code, password);
      setSuccess(true);
    } catch (e) {
      setError('Something went wrong with your request');
    }
  };

  return { fetching, resetPw, success, error };
};
