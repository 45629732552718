import { match } from 'react-router';
import React from 'react';
import { Location } from 'history';
import { DraftJsBlock, DraftJsEntityMap } from './types';
import { useNestedRoute } from '../hoc/location-hooks';
import ResultLink from '../containers/module-viewer/result-panel/result-link';

export const isInternalLink = (url: string) => {
  return (
    url &&
    (url.toLocaleLowerCase().endsWith('#task') ||
      url.toLocaleLowerCase().endsWith('#download'))
  );
};

export const isTaskLink = (url: string) => {
  return url && url.toLocaleLowerCase().endsWith('#task');
};

export const isDownloadLink = (url: string) => {
  return url && url.toLocaleLowerCase().endsWith('#download');
};

export const isKeyBlock = (text: string) => {
  return text.startsWith('KEY|');
};

export const isTaskBlock = (text: string) => {
  return isKeyBlock(text) && text.split('|')[1] === 'TASK_BOX';
};

export const isResourceBlock = (text: string) => {
  return isKeyBlock(text) && text.split('|')[1] === 'RESOURCE_BOX';
};

export const isVideoBlock = (text: string) => {
  return isKeyBlock(text) && text.split('|')[1] === 'VIDEO';
};

export const isVideoExternalBlock = (text: string) => {
  return isKeyBlock(text) && text.split('|')[1] === 'VIDEO_EXTERNAL';
};

export const isImageBlock = (text: string) => {
  return isKeyBlock(text) && text.split('|')[1] === 'IMAGE';
};

export const isDefinitionLink = (url: string) => {
  return url && url.endsWith('#definition');
};

export const isProfileLink = (url: string) => {
  return url && url.endsWith('#profile');
};

export const isImageLink = (url: string) => {
  return url && url.endsWith('#image');
};

export const getCodeFromUrl = (url: string) => {
  const urlId = url.split('/');
  return urlId[urlId.length - 1].split('#')[0];
};

export const getCodeFromBlock = (
  block: DraftJsBlock,
  entityMap: DraftJsEntityMap
): string => {
  const url = entityMap[block.entityRanges[0].key].data.url;
  return getCodeFromUrl(url);
};

export const getCodesFromBlocks = (
  blocks: DraftJsBlock[],
  entityMap: DraftJsEntityMap
): string[] => {
  const codes: string[] = [];
  for (const block of blocks) {
    codes.push(getCodeFromBlock(block, entityMap));
  }
  return codes;
};

export const buildInternalLink = (
  text: string,
  url: string,
  match: match<{}>,
  location: Location
) => {
  const code = getCodeFromUrl(url);

  if (isDownloadLink(url)) {
    return <ResultLink url={`download/url/${code}`} text={text} download />;
  }
  const urlRoot = useNestedRoute();
  return <ResultLink url={`${urlRoot}/task/${code}`} text={text} task />;
};
