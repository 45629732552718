import { Reducer } from 'react';
import {
  Checkup,
  Context,
  DashboardContext,
  GlobalAction,
  GlobalActionType,
  HomePaths,
  ResultProgressAction,
} from './types';
import ErrorModalContent from '../components/UI/modal/error-modal-content';

const globalReducer: Reducer<Context, GlobalAction> = (state, action) => {
  function updateProgress(
    checkup: Checkup,
    resultProgress: ResultProgressAction
  ): Checkup {
    // consider a null id value be a batch update that corresponds to the complete boolean
    if (resultProgress.resultCode === null) {
      Object.keys(checkup.results).forEach((key) => {
        checkup.results[key].completed = resultProgress.complete;
      });
      return {
        ...checkup,
        results: checkup.results,
      };
      // consider a provided id to be a specific update
    } else {
      const newResults = {
        ...checkup.results,
        [resultProgress.resultCode]: {
          completed: resultProgress.complete,
        },
      };
      return {
        ...checkup,
        results: newResults,
      };
    }
  }

  const updateCheckupResult = (
    dashboardContext: DashboardContext | null | undefined,
    resultProgress: ResultProgressAction | undefined
  ): DashboardContext | null | undefined => {
    if (dashboardContext && resultProgress) {
      return {
        ...dashboardContext,
        userDashboardProgress: {
          ...dashboardContext.userDashboardProgress,
          progress: {
            ...dashboardContext.userDashboardProgress.progress,
            checkups: {
              ...dashboardContext.userDashboardProgress.progress.checkups,
              [resultProgress.checkupCode]: updateProgress(
                dashboardContext.userDashboardProgress.progress.checkups[
                  resultProgress.checkupCode
                ],
                resultProgress
              ),
            },
          },
        },
      };
    }
    return dashboardContext;
  };

  const determinePercentageComplete: any = (dashboardContext: any) => {
    const findTotalFromContext = (
      dashboardContext: DashboardContext
    ): number => {
      return Object.values(
        dashboardContext.userDashboardProgress.progress.checkups
      ).reduce((total, cur) => {
        if (cur.resultsCountTowardsPercentage) {
          // results no longer have a dependable starting point, so if no answers have been made,
          // we use the base resultList outside of progress to determine denominator now.
          if (cur.answers.length == 0) {
            return (
              total +
              dashboardContext.currentDashboard.checkups[cur.code].resultList
                .length
            );
          } else {
            return total + cur.resultList.length;
          }
        } else {
          return total;
        }
      }, 0);
    };

    const completedChecked: any = (iterated: any) => {
      var held = Object.values(iterated).reduce((total: any, cur: any) => {
        return cur.completed ? ++total : total;
      }, 0);
      return held;
    };

    const findUserComplete = (dashboardContext: DashboardContext) => {
      return Object.values(
        dashboardContext.userDashboardProgress.progress.checkups
      ).reduce((total, cur) => {
        return total + completedChecked(cur.results);
      }, 0);
    };

    return {
      totalResultsAvailable: findTotalFromContext(dashboardContext),
      userCompletedReults: findUserComplete(dashboardContext),
    };
  };

  switch (action.type) {
    case GlobalActionType.AUTH_VERIFIED:
      return {
        ...state,
        isAuthVerified: true,
      };
    case GlobalActionType.LOGIN_SUCCESS:
      const user = action.payload ? action.payload.user : null;
      return {
        ...state,
        isAuthVerified: true,
        isAuthenticated: true,
        isTermsAccepted: user ? (user.termsAcceptedOn ? true : false) : false,
        user: user,
        org: user ? user.organization : null,
        homePath:
          user && user.organization && user.organization.showLandingPage
            ? HomePaths.DASHBOARD
            : HomePaths.DEFAULT,
      };
    case GlobalActionType.LOGOUT:
      sessionStorage.removeItem('refreshToken');
      sessionStorage.removeItem('token');
      return {
        ...state,
        isAuthenticated: false,
        isTermsAccepted: false,
        user: null,
      };
    case GlobalActionType.CLEAR_USER:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    case GlobalActionType.UPDATE_MODAL:
      return {
        ...state,
        modal: action.payload.modal,
      };
    case GlobalActionType.UPDATE_MODAL_GLOBAL:
      const modalNew = action.payload.modalNew;
      if (modalNew && !modalNew.open) {
        modalNew.type = null;
        modalNew.data = null;
      }
      return {
        ...state,
        modalNew,
      };
    case GlobalActionType.UPDATE_SCREEN_DIMENSIONS:
      return {
        ...state,
        screenDimensions: action.payload.screenDimensions,
      };
    case GlobalActionType.IS_KEYBOARD_OPEN_MOBILE:
      return {
        ...state,
        isKeyboardOpenMobile: action.payload.isKeyboardOpenMobile,
      };
    case GlobalActionType.FETCHED_ALL_DASHBOARDS:
      return {
        ...state,
        dashboardsList: action.payload.dashboardsList,
      };

    case GlobalActionType.SHOW_ERROR_MODAL:
      return {
        ...state,
        modal: { on: true, content: ErrorModalContent, allowClose: true },
      };
    case GlobalActionType.FETCH_DASHBOARD_CONTEXT:
      return {
        ...state,
        dashboardContext: action.payload.dashboardContext,
        globalPercentageValues: determinePercentageComplete(
          action.payload.dashboardContext
        ),
      };
    case GlobalActionType.FETCH_DASHBOARD_ERROR:
      //if their dashboard isn't found (or times out), make them pick a new one (see behavior at wheelContainer.tsx)
      return {
        ...state,
        currentDashboardId: null,
      };
    case GlobalActionType.UPDATE_DASHBOARD_ID:
      return {
        ...state,
        currentDashboardId: action.payload.currentDashboardId,
      };
    case GlobalActionType.SAVE_RESULT_PROGRESS:
      const updatedResults = updateCheckupResult(
        state.dashboardContext,
        action.payload.resultProgress
      );
      return {
        ...state,
        dashboardContext: updatedResults,
        globalPercentageValues: determinePercentageComplete(updatedResults),
      };
    case GlobalActionType.SAVE_TOTAL_PROGRESS:
      return {
        ...state,
        totalProgress: action.payload.totalProgress,
        globalPercentageValues: determinePercentageComplete(
          state.dashboardContext
        ),
      };
    case GlobalActionType.TERMS_ACCEPTED:
      return {
        ...state,
        isTermsAccepted: true,
      };
    case GlobalActionType.TRACK_EVENT:
      if (action.payload.event) {
        action.payload.event.eventOn = new Date();
        return {
          ...state,
          events: state.events.concat(action.payload.event),
        };
      }
    case GlobalActionType.SYNCED_EVENTS:
      return {
        ...state,
        events: [],
      };
    case GlobalActionType.SET_ORG:
      return {
        ...state,
        org: action.payload.org,
      };
    default:
      return state;
  }
};
export default globalReducer;
