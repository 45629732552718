import React from 'react';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    optionHolder: {
      height: '55px',
      width: '100%',
      padding: '2px 36px',
      background: '#ffffff',
      borderBottom: '2px solid #f7f7f7',
      color: 'black',
      cursor: 'pointer',
    },
    rFQaTable: {
      marginTop: '3px',
      width: '100%',
      opacity: 0.8,
      outlineStyle: 'none',
      '& td:first-child': {
        width: '40px',
        height: '40px',
        backgroundImage: 'url(/images/questions/A.png)',
        backgroundSize: 'contain',
      },
      '&:hover': {
        opacity: 1,
      },
    },
    rFQaTableSelected: {
      width: '100%',
      opacity: 1,
      '& td:first-child': {
        width: '40px',
        height: '40px',
        backgroundImage: 'url(/images/questions/A-2.png)',
        backgroundSize: 'contain',
      },
    },

    rFQbTable: {
      marginTop: '3px',
      width: '100%',
      opacity: 0.8,
      outlineStyle: 'none',
      '& td:first-child': {
        width: '40px',
        height: '40px',
        backgroundImage: 'url(/images/questions/B.png)',
        backgroundSize: 'contain',
      },
      '&:hover': {
        opacity: 1,
      },
    },
    rFQbTableSelected: {
      width: '100%',
      opacity: 1,
      '& td:first-child': {
        width: '40px',
        height: '40px',
        backgroundImage: 'url(/images/questions/B-2.png)',
        backgroundSize: 'contain',
      },
    },

    rFQcTable: {
      marginTop: '3px',
      width: '100%',
      opacity: 0.8,
      outlineStyle: 'none',
      '& td:first-child': {
        width: '40px',
        height: '40px',
        backgroundImage: 'url(/images/questions/C.png)',
        backgroundSize: 'contain',
      },
      '&:hover': {
        opacity: 1,
      },
    },
    rFQcTableSelected: {
      width: '100%',
      opacity: 1,
      '& td:first-child': {
        width: '40px',
        height: '40px',
        backgroundImage: 'url(/images/questions/C-2.png)',
        backgroundSize: 'contain',
      },
    },

    rFQdTable: {
      marginTop: '3px',
      width: '100%',
      opacity: 0.8,
      outlineStyle: 'none',
      '& td:first-child': {
        width: '40px',
        height: '40px',
        backgroundImage: 'url(/images/questions/D.png)',
        backgroundSize: 'contain',
      },
      '&:hover': {
        opacity: 1,
      },
    },
    rFQdTableSelected: {
      width: '100%',
      opacity: 1,
      '& td:first-child': {
        width: '40px',
        height: '40px',
        backgroundImage: 'url(/images/questions/D-2.png)',
        backgroundSize: 'contain',
      },
    },

    rFQeTable: {
      marginTop: '3px',
      width: '100%',
      opacity: 0.8,
      outlineStyle: 'none',
      '& td:first-child': {
        width: '40px',
        height: '40px',
        backgroundImage: 'url(/images/questions/E.png)',
        backgroundSize: 'contain',
      },
      '&:hover': {
        opacity: 1,
      },
    },
    rFQeTableSelected: {
      width: '100%',
      opacity: 1,
      '& td:first-child': {
        width: '40px',
        height: '40px',
        backgroundImage: 'url(/images/questions/E-2.png)',
        backgroundSize: 'contain',
      },
    },
    rFQTableText: {
      padding: '0px 7px',
    },
  });

interface Props extends WithStyles<typeof styles> {
  setAnswerHeld: any;
  answer: any;
  isSelected: any;
  tabRefs: any;
  index: any;
  tabRefIndexStart: any;
}

const AnswerOption: React.FunctionComponent<Props> = (props) => {
  const {
    classes,
    answer,
    isSelected,
    setAnswerHeld,
    tabRefs,
    tabRefIndexStart,
    index,
  } = props;

  let tableClass = classes.rFQaTable;
  let tableSelectedClass = classes.rFQaTableSelected;

  if (index === 1) {
    tableClass = classes.rFQbTable;
    tableSelectedClass = classes.rFQbTableSelected;
  }
  if (index === 2) {
    tableClass = classes.rFQcTable;
    tableSelectedClass = classes.rFQcTableSelected;
  }
  if (index === 3) {
    tableClass = classes.rFQdTable;
    tableSelectedClass = classes.rFQdTableSelected;
  }
  if (index === 4) {
    tableClass = classes.rFQeTable;
    tableSelectedClass = classes.rFQeTableSelected;
  }

  return (
    <div className={classes.optionHolder}>
      <table
        className={tableClass + (isSelected ? ' ' + tableSelectedClass : '')}
        onClick={() => setAnswerHeld(answer.key)}
      >
        <tbody>
          <tr>
            <td>
              <div />
            </td>
            <td className={classes.rFQTableText}>
              <div
                ref={tabRefs[index]}
                tabIndex={tabRefIndexStart + index}
                aria-label="Answer"
                role="button"
              >
                {answer.text}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default withStyles(styles)(AnswerOption);
