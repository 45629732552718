import React, { useEffect, useState } from 'react';
import { StripeProvider as StripeProviderInner } from 'react-stripe-elements';

export const StripeProvider = ({ children }) => {
  const [stripe, setStripe] = useState(null);

  const initializeStripe = () => {
    setStripe(window.Stripe(process.env.REACT_APP_STRIPE_KEY));
  };

  useEffect(() => {
    if (window.Stripe) {
      initializeStripe();
    } else {
      // Otherwise wait for Stripe script to load
      let stripeJs = document.querySelector('#stripe-js');
      if (stripeJs) {
        stripeJs.addEventListener('load', () => {
          initializeStripe();
        });
      }
    }
  }, []);

  return <StripeProviderInner stripe={stripe}>{children}</StripeProviderInner>;
};
