import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { CustomBreakPoints } from '../../../common/types';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '355px',
    position: 'absolute',
    top: '65px',
    left: '53px',
    zIndex: 10,
    border: '1px solid #cacaca',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      border: '1px solid white',
      position: 'relative',
      margin: '0 auto',
      top: '20px',
      left: '0px',
    },
    borderRadius: '10px',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  itemIcon: {
    color: '#e2e2e2',
    fontSize: '35px',
    paddingTop: '9px',
  },
  itemIconHolder: {
    width: '66px',
    height: '66px',
    minWidth: '66px',
    minHeight: '66px',
    border: '4px solid #cacaca',
    borderRadius: '50% 50%',
    //marginLeft: '13px',
    textAlign: 'center',
  },
  itemVaultHolder: {
    width: '66px',
    height: '66px',
    minWidth: '66px',
    minHeight: '66px',
    //marginLeft: '13px',
    backgroundImage: 'url(/images/vault_locked.png)',
    backgroundSize: 'cover',
  },
  itemVaultHolderActive: {
    width: '65px',
    height: '65px',
    //marginLeft: '13px',
    backgroundImage: 'url(/images/vault_unlocked.png)',
    backgroundSize: 'cover',
  },

  itemIconActive: {
    color: 'white',
    fontSize: '35px',
    paddingTop: '9px',
  },
  itemIconHolderActive: {
    minWidth: '65px',
    minHeight: '65px',
    width: '65px',
    height: '65px',
    border: '4px solid #0076c9',
    background: '#0094ff',
    borderRadius: '50% 50%',
    //marginLeft: '13px',
    textAlign: 'center',
  },
  itemIconDone: {
    color: 'white',
    fontSize: '35px',
    paddingTop: '9px',
  },
  itemIconHolderDone: {
    minWidth: '65px',
    minHeight: '65px',
    width: '65px',
    height: '65px',
    border: '4px solid #65cc5c',
    background: '#74ff69',
    borderRadius: '50% 50%',
    //marginLeft: '13px',
    textAlign: 'center',
  },
  iconHolder: {
    display: 'flex',
    marginLeft: '10px',
  },
  title: {
    color: '#656565',
    textAlign: 'center',
    paddingTop: '7px',
    marginTop: '15px',
    marginBottom: '15px',
    fontSize: '22px',
  },
  resetIcon: {
    color: '#656565',
    fontSize: '22px',
    position: 'absolute',
    top: '5px',
    right: '5px',
    cursor: 'pointer',
  },
  desc: {
    fontSize: '15px',
    textAlign: 'center',
    marginTop: '20px',
    marginBottom: '15px',
    marginRight: '20px',
    marginLeft: '20px',
    color: '#656565',
  },
  buttonHolder: {
    minWidth: '100%',
    marginTop: '18px',
    marginBottom: '15px',
    marginRight: '0px',
    marginLeft: '0px',
    textAlign: 'center',
    margin: '0 auto',
  },
  seperator: {
    width: '100%',
    borderBottom: '1px solid #cacaca',
    paddingTop: '10px',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      border: '1px solid white',
    },
  },
  blueButt: {
    display: 'block',
    cursor: 'pointer',
    width: '200px',
    height: '40px',
    color: 'white',
    border: '4px solid #0094ff',
    borderRadius: '4px',
    background: '#0094ff',
    margin: '0 auto',
  },
  blueButtText: {
    height: '30px',
    marginTop: '5px',
    fontWeight: 'bold',
    fontSize: '19px',
  },
  percentageHolder: {},
  prcComBox: {
    border: '15px solid #0095FF',
    width: '160px',
    height: '160px',
    borderRadius: '50%',
    paddingTop: '22px',
    margin: '0 auto',
    fontFamily: 'Montserrat',
    fontWeight: 600,
    marginTop: '25px',
    marginBottom: '25px',
    textAlign: 'center',
    position: 'relative',
  },
  shieldBox: {
    width: '160px',
    height: '200px',
    paddingTop: '22px',
    margin: '0 auto',
    marginTop: '25px',
    marginBottom: '25px',
    textAlign: 'center',
    position: 'relative',
    backgroundImage: 'url(/images/shield.png)',
    backgroundSize: 'cover',
  },
  prcComValue: {
    color: '#0095FF',
    fontSize: '70px',
    '&::after': {
      content: '"%"',
      position: 'absolute',
      color: '#0095FF',
      fontSize: '18px',
      top: '70px',
    },
  },
  prcComSign: {
    color: '#0095FF',
    fontSize: '24px',
  },
  prcComLabel: {
    color: 'black',
    fontSize: '17px',
    marginLeft: '7px',
  },
  number: {
    fontFamily: 'Montserrat',
    textAlign: 'center',
    verticalAlign: 'middle',
    margin: '0 auto',
    color: '#e2e2e2',
    fontWeight: 'bold',
  },
  itemIconPercent: {
    position: 'relative',
    top: '-15px',
    left: '73px',
    width: '50px',
    height: '50px',
    background: 'white',
    color: '#0095FF',
    borderRadius: '50% 50%',
    textAlign: 'center',
    fontSize: '33px',
    paddingTop: '3px',
    //border: '1px solid black'
  },
  CarouselSlideHolder: {},
  commonIconBase: {
    paddingLeft: '6px',
    cursor: 'pointer',
  },
  tableHolder: {
    borderSpacing: '0px !important',
    marginLeft: '1px',
  },
  leftSlider: {
    maxWidth: '20px',
    minWidth: '20px',
    padding: '0px',
  },
  middleSlider: {
    width: '310px',
    minWidth: '310px',
    minHeight: '80px',
  },
  rightSlider: {
    maxWidth: '20px',
    minWidth: '20px',
    padding: '0px',
  },
  myButton: {
    minWidth: '100%',
    '& button': {
      'box-shadow': 'none',
      background: 'transparent',
      'background-color': 'transparent',

      border: 'none',
      display: 'inline-block',
      cursor: 'pointer',
      color: '#a0a0a0',
      'font-family': 'Arial',
      'font-size': '22px',
      'font-weight': 'bold',
      padding: '19px 0px',
      marginLeft: '0px',
      minWidth: '100%',
      textAlign: 'center',
      'text-decoration': 'none',
      'text-shadow': '0px 1px 0px #ffffff',
      '&:hover': {},
      opacity: 0.7,
      '&:disabled': {
        opacity: 0.2,
        cursor: 'default',
      },
    },
  },
  myButtonRight: {
    minWidth: '100%',
    '& button': {
      'border-radius': '6px 0px 0px 6px',
    },
  },
}));

export default function VerticalLinearStepper(props) {
  const wheelProc = props.wheelProc;
  const activeStep = props.activeStep;
  const classes = useStyles(props);
  const isVaultStep =
    wheelProc.children &&
    wheelProc.children[activeStep] &&
    wheelProc.children[activeStep].centerOfWheel;
  const buttonText = isVaultStep ? 'Access your vault' : 'Go';
  const headingText = isVaultStep ? 'Vault Unlocked' : 'Levels';

  function getActiveStepForSlider(activeStep, numberOfSteps) {
    if (numberOfSteps === 0) {
      return 0;
    }
    if (numberOfSteps - activeStep < 3) {
      return numberOfSteps - 4;
    }
    if (activeStep - 2 > 0) {
      return activeStep - 2;
    } else {
      return 0;
    }
  }

  function getStepItem(item, index) {
    if (item.centerOfWheel === true) {
      if (item.isLocked) {
        return (
          <div className={classes.commonIconBase}>
            <div className={classes.itemVaultHolder} key={'itemKey' + index} />
          </div>
        );
      }
      return (
        <div
          className={classes.commonIconBase}
          onClick={() => {
            props.setStartModalData(item);
            props.setVaultVisible(true);
          }}
        >
          <div
            className={classes.itemVaultHolderActive}
            key={'itemKey' + index}
          />
        </div>
      );
    }
    if (item.isLocked) {
      //this represents locked up
      return (
        <div
          className={classes.commonIconBase}
          onClick={() => {
            props.handleClick(item.code);
          }}
        >
          <div className={classes.itemIconHolder} key={'itemKey' + index}>
            <div className={classes.itemIcon}>
              <i className="fa icon-foo">{item.icon}</i>
            </div>
          </div>
        </div>
      );
    }
    if (item.completeNoResults || item.percentageComplete === 100) {
      //this represents checkmark
      return (
        <div
          className={classes.commonIconBase}
          onClick={() => {
            props.handleClick(item.code);
          }}
        >
          <div className={classes.itemIconHolderDone} key={'itemKey' + index}>
            <div className={classes.itemIconDone}>
              <i className="fa icon-foo">&#xf058;</i>
            </div>
          </div>
        </div>
      );
    } else {
      //this represents blue "active"
      return (
        <div
          className={classes.commonIconBase}
          onClick={() => {
            props.handleClick(item.code);
          }}
        >
          <div className={classes.itemIconHolderActive} key={'itemKey' + index}>
            <div className={classes.itemIconActive}>
              <i className="fa icon-foo">{item.icon}</i>
            </div>
          </div>
        </div>
      );
    }
  }

  return (
    <div className={classes.root}>
      <a
        onClick={() => {
          props.setDialogueResetWarningVisible(true);
        }}
      >
        <div className={classes.resetIcon}>
          <i className="fa icon-foo">&#xf0e2;</i>
        </div>
      </a>
      <h1 className={classes.title}>{headingText}</h1>

      <div className={classes.CarouselSlideHolder}>
        <CarouselProvider
          naturalSlideWidth={75}
          naturalSlideHeight={65}
          totalSlides={7}
          visibleSlides={4}
          currentSlide={getActiveStepForSlider(
            activeStep,
            (wheelProc.children && wheelProc.children.length) || 0
          )}
        >
          <table className={classes.tableHolder}>
            <thead />
            <tbody>
              <tr>
                <td className={classes.leftSlider}>
                  <div className={classes.myButton}>
                    <ButtonBack> &lt; </ButtonBack>
                  </div>
                </td>
                <td className={classes.middleSlider}>
                  <Slider>
                    {wheelProc.children &&
                      wheelProc.children.map((item, index) => (
                        <Slide index={index} key={index + 'fobar'}>
                          {getStepItem(item, index)}
                        </Slide>
                      ))}
                  </Slider>
                </td>
                <td className={classes.rightSlider}>
                  <div
                    className={classes.myButton + ' ' + classes.myButtonRight}
                  >
                    <ButtonNext> &gt; </ButtonNext>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </CarouselProvider>
      </div>
      <div className={classes.buttonHolder}>
        <div
          className={classes.blueButt}
          onClick={() => {
            if (isVaultStep) {
              const vaultWheelItem = wheelProc.children[activeStep];
              props.setStartModalData(vaultWheelItem);
              props.setVaultVisible(true);
            } else {
              props.handleClick(wheelProc.children[activeStep].code);
            }
          }}
        >
          <div className={classes.blueButtText}>{buttonText}</div>
        </div>
      </div>
      <div className={classes.seperator} />
      {isVaultStep && <div className={classes.shieldBox} />}
      {!isVaultStep && (
        <div className={classes.prcComBox}>
          <div className={classes.prcComValue}>
            {(wheelProc.children &&
              wheelProc.children[activeStep].percentageComplete) ||
              0}
          </div>
          <div className={classes.prcComLabel}>
            <div className={classes.itemIconPercent}>
              <i className="fa icon-foo">
                {wheelProc.children && wheelProc.children[activeStep].icon}
              </i>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
