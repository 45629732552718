import React, { FunctionComponent, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useStateValue } from '../../../hoc/state';
import {
  CustomBreakPoints,
  ModalNewContent,
  ModalType,
} from '../../../common/types';
import PathOverviewModal from './path-overview-modal';
import TaskModal from './task-modal';

const MODALS = {
  [ModalType.PathDetails]: PathOverviewModal,
  [ModalType.Task]: TaskModal,
};

const useStyles = makeStyles((theme) => ({
  modalShade: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(46, 46, 46, .7)',
    zIndex: 50,
  },
  modalBody: {
    position: 'absolute',
    top: '72px',
    left: '0px',
    right: '0px',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '1200px',
    height: '85%',
    backgroundColor: '#F9F9F9',
    zIndex: 51,
    borderRadius: '5px',
  },
  modalContentHolder: {
    display: 'flex',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      width: '100%',
    },
  },
  modalClose: {
    position: 'absolute',
    zIndex: 52,
    right: '57px',
    top: '50px',
    width: '25px',
    height: '25px',
    backgroundColor: '#2E2E2E',
    color: '#fff',
    fontWeight: 'bold',
    fontSize: '20px',
    textAlign: 'center',
    borderRadius: '2px',
    cursor: 'pointer',
    '-webkit-transition': '-webkit-transform .4s ease-in-out',
    transition: 'transform .4s ease-in-out',
    '&:hover': {
      opacity: 0.6,
    },
  },
  visible: {
    visibility: 'visible',
    // transition: 'opacity .1s linear',
  },
  hidden: {
    visibility: 'hidden',
    // transition: 'visibility 0s .5s, opacity .5s linear',
  },
}));

const childClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
  event.stopPropagation();
};

const ModalGlobal: React.FunctionComponent = () => {
  const { modalNew } = useStateValue();
  const classes = useStyles();
  const [backgroundClicked, setBackgroundClicked] = useState(false);

  useEffect(() => {
    setBackgroundClicked(false);
  }, [modalNew]);

  if (!modalNew || !modalNew.open) {
    return null;
  }

  const Content: FunctionComponent<ModalNewContent> | null = modalNew.type
    ? MODALS[modalNew.type]
    : null;

  return (
    <div
      className={classes.modalShade}
      onClick={() => setBackgroundClicked(true)}
    >
      <div onClick={childClick} className={classes.modalContentHolder}>
        {Content ? (
          <Content backgroundClicked={backgroundClicked} content={modalNew} />
        ) : null}
      </div>
    </div>
  );
};

export default ModalGlobal;
