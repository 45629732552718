import React, { FunctionComponent, memo } from 'react';
import { ResultPanelHeaderProps } from '../../../common/types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  articleHeaderH1: {
    color: '#347CB1',
    fontSize: '1.7rem',
    fontWeight: 'bold',
    paddingBottom: '1rem',
  },
}));

export const ResultPanelHeader: FunctionComponent<ResultPanelHeaderProps> = ({
  text,
}) => {
  const classes = useStyles();

  return <div className={classes.articleHeaderH1}>{text}</div>;
};

export default memo(ResultPanelHeader);
