import React from 'react';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core';
import { CustomBreakPoints } from '../../../common/types';

const styles = (theme: Theme) =>
  createStyles({
    modalBody: {
      position: 'fixed',
      left: '0px',
      bottom: '0px',
      width: '100%',
      height: '65px',
      backgroundColor: '#ffffff',
      borderRadius: '0px',
      borderTop: '2px solid #f7f7f7',
      color: 'black',
      [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
        height: '50px',
      },
    },
    modalContentHolder: {
      padding: '8px 65px',
      overflow: 'hidden',
      [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
        padding: '3px 35px',
      },
    },

    modalContent: {
      width: '100%',
      height: '100%',
    },
    numericHolder: {
      float: 'right',
      fontWeight: 'bold',
      marginRight: '20px',
      marginTop: '9px',
      [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
        marginRight: '8px',
      },
    },
    buttonHolderRight: {
      float: 'right',
    },
    buttonHolderLeft: {
      float: 'left',
    },
    buttonC: {
      minWidth: '219px',
      textAlign: 'center',
      fontSize: '18px',
      fontWeight: 'bold',
      color: 'white',
      border: '1px solid white',
      background: '#0095ff',
      outline: 'solid #ffffff 2px',
      padding: '12px 20px',
      borderRadius: '5px',
      boxShadow: '0 0 0 2px #ffffff',
      outlineStyle: 'none',
      cursor: 'pointer',
      [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
        minWidth: '119px',
        fontSize: '14px',
      },
      '&:hover': {
        background: '#0095ff',
        border: '1px solid white',
        boxShadow: '0 0 0 2px #0095ff',
        outline: 'solid #0095ff 2px',
        outlineStyle: 'none',
      },
      '&:disabled': {
        background: '#b8b9bb',
        border: '1px solid white',
        boxShadow: '0 0 0 2px #ffffff',
        outline: 'solid #ffffff 2px',
        cursor: 'default',
        outlineStyle: 'none',
      },
    },
    dotHolder: {
      marginLeft: '10px',
      '& img': {
        paddingTop: '5px',
        width: '15px',
        marginLeft: '5px',
      },
      [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
        display: 'none',
      },
    },
    numOf: {
      display: 'inline-block',
      marginTop: '-3px',
    },
  });

interface Props extends WithStyles<typeof styles> {
  id: string;
  answerHeld: any;
  iterateQuestions: any;
  iterateBackQuestion: any;
  qLength: number;
  qIndex: number;
  tabRefs: any;
}

const DialogueFooter: React.FunctionComponent<Props> = (props) => {
  const {
    classes,
    id,
    answerHeld,
    iterateQuestions,
    iterateBackQuestion,
    qLength,
    qIndex,
    tabRefs,
  } = props;

  const buildDots = () => {
    let imgHtml: any = [];
    for (var _i = 0; _i < qLength; _i++) {
      if (_i === qIndex) {
        imgHtml.push(
          <img src="/images/questions/BC.png" key={id + _i + qIndex + 'bc'} />
        );
      } else {
        imgHtml.push(
          <img src="/images/questions/SC.png" key={id + _i + qIndex + 'sc'} />
        );
      }
    }
    return imgHtml;
  };
  const isLastAnswer = qLength === qIndex + 1 && answerHeld;
  const buttonText = isLastAnswer ? 'Get Results' : 'Confirm My Answer';
  return (
    <div className={classes.modalBody}>
      <div className={classes.modalContentHolder}>
        <div className={classes.modalContent}>
          <div
            className={classes.buttonHolderLeft}
            ref={tabRefs[1]}
            aria-label="Go Back"
            role="button"
            tabIndex={602}
          >
            <button
              disabled={qIndex === 0}
              className={classes.buttonC}
              onClick={() => iterateBackQuestion()}
            >
              <span>Back</span>
            </button>
          </div>
          <div
            className={classes.buttonHolderRight}
            ref={tabRefs[0]}
            aria-label="Select Answer and Continue"
            role="button"
            tabIndex={601}
          >
            <button
              disabled={!answerHeld}
              className={classes.buttonC}
              onClick={() => iterateQuestions(id, answerHeld)}
            >
              <span>{buttonText}</span>
            </button>
          </div>
          <table className={classes.numericHolder}>
            <tbody>
              <tr>
                <td>
                  <div className={classes.numOf}>
                    <span className="notranslate">
                      <span>{qIndex + 1}</span> <span>of</span>{' '}
                      <span>{qLength}</span>
                    </span>
                  </div>
                </td>
                <td>
                  <div className={classes.dotHolder}>{buildDots()}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(DialogueFooter);
