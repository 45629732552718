import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import * as d3 from 'd3';
import SVGBuilder from '../../common/SVGBuilder';
import { makeStyles } from '@material-ui/core';
import Dialogue from '../../components/UI/modal/dialogue';
import DialogueWarning from '../../components/UI/modal/dialogue-warning';
import DialogueVault from '../../components/UI/modal/dialogue-vault';
import DialogueResetWarning from '../../components/UI/modal/dialogue-reset-warning';
import DialogueNoResultsWarning from '../../components/UI/modal/dialogue-no-results-warning';
import DialogueStart from '../../components/UI/modal/dialogue-start';
import DialogueHasResults from '../../components/UI/modal/dialogue-has-results';
import DialogueHasResultsTwo from '../../components/UI/modal/dialogue-has-results-two';
import DialogueFooter from '../../components/UI/modal/dialogue-footer';
import axiosInst, { axiosWrapper } from '../../common/axios';
import ProgressGuageWheelTwo from '../../components/UI/progress-guage/progress-guage-wheel-two';
import ProgressGuageWheelStep from '../../components/UI/progress-guage/progress-guage-wheel-step';
import LoadingOverlay from 'react-loading-overlay';
import useDashboardApi from '../../apis/dashboard-apis';
import { useStateValue } from '../../hoc/state';
import { CustomBreakPoints } from '../../common/types';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { processAnswer } from '../../common/answer-parser';
import QuestionFormattedText from './questions/question-formatted-text';
import AnswerOption from './questions/answer-option';
import ReactDOM from 'react-dom';

const useStyles = makeStyles((theme) => ({
  wheel_container: {
    position: 'absolute',
    width: '100%',
    height: 'calc(100% - 65px)',
  },
  blur: {
    '-webkit-filter': 'blur(10px)',
    '-moz-filter': 'blur(10px)',
    '-o-filter': 'blur(10px)',
    '-ms-filter': 'blur(10px)',
    filter:
      "url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='svgMask'><feGaussianBlur stdDeviation='10' /></filter></svg>#svgMask\")",
  },
  article_bread_crumb: {
    fontSize: '.9rem',
    color: '#0095FF',
    lineHeight: '22px',
    top: '80px',
    left: '54px',
    position: 'absolute',
    zIndex: 10,
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      top: '97px',
      left: '22px',
      fontWeight: 'bold',
      display: 'none',
    },
  },
  breadCrumbLink: {
    textDecoration: 'none',
    color: '#0095FF',
    '&:hover': {
      textDecoration: 'none',
      color: '#0095FF',
    },
    '&:visited': {
      textDecoration: 'none',
      color: '#0095FF',
    },
  },
  reset: {
    fontSize: '.9rem',
    color: '#0095FF',
    lineHeight: '22px',
    top: '80px',
    right: '50px',
    position: 'absolute',
    zIndex: 10,
    cursor: 'pointer',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      top: '97px',
      right: '22px',
      fontWeight: 'bold',
    },
  },
  main: {
    position: 'absolute',
    top: '50%',
    left: 'calc(50% + 200px)',
    transform: 'translate(-50%, -50%)',
    background:
      'radial-gradient(circle, rgba(122,122,122,1) 0%, rgba(255,255,255,0) 35%, rgba(255,255,255,0) 100%)',

    minWidth: '407px',
    minHeight: '407px',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      display: 'none',
    },
  },
  canvas_holder: {
    position: 'absolute',
    top: '50%',
    left: 'calc(50% + 200px)',
    transform: 'translate(-50%, -50%)',
    zIndex: 6,
    overflow: 'hidden',
  },
  link: {
    textDecoration: 'none',
    color: '#0095FF',
    transition: 'font-size .15s',
    '&:hover': {
      fontSize: '22px',
      fontWeight: 'bold',
    },
  },
  link_hover: {
    fontSize: '22px',
    fontWeight: 'bold',
  },
  resultFilterQuestion: {
    width: '100%',
    background: '#fffff',
  },
  resultFilterQuestionChoices: {
    height: '100%',
    width: '100%',
  },
  rFQChildTextBody: {
    width: '100%',
    fontSize: '18px',
    padding: '13px 40px',
    color: '#2E2E2E',
    lineHeight: '22px',
    minHeight: '93px',
    '& ul': {
      listStyleType: 'square',
    },
  },
  rFQChildTextBodySubHeading1: {
    width: '100%',
    fontSize: '18px',
    fontWeight: 'bold',
    padding: '1px 40px',
    color: 'black',
    lineHeight: '1px',
    minHeight: '1px',
    borderBottom: '2px solid #f7f7f7',
  },
  rFQChildTextBodySubHeading: {
    width: '100%',
    fontSize: '18px',
    fontWeight: 'bold',
    padding: '13px 40px',
    color: 'black',
    lineHeight: '22px',
    minHeight: '33px',
    borderBottom: '2px solid #f7f7f7',
  },
  rFQChild: {
    height: '100%',
    width: '100%',
  },
  rFQTitle: {
    height: '50px',
    width: '100%',
    padding: '13px 40px',
    background: '#ffffff',
    borderBottom: '2px solid #f7f7f7',
    color: 'black',
    fontSize: '28px',
    fontWeight: 'bold',
  },
  rFQClose: {
    float: 'right',
    margin: '10px',
    color: 'black',
    cursor: 'pointer',
    position: 'absolute',
    right: '0px',
    top: '0px',
    padding: '5px 10px',
    fontWeight: 'bold',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      marginRight: '16px',
      marginBottom: '3px',
    },
    '&:hover': {
      opacity: 0.6,
    },
  },
  rFQCloseImage: {
    width: '26px',
  },

  optionHolder: {
    height: '55px',
    width: '100%',
    padding: '2px 36px',
    background: '#ffffff',
    borderBottom: '2px solid #f7f7f7',
    color: 'black',
    cursor: 'pointer',
  },
  iconCheck: {
    color: '#6FDC73',
    cursor: 'pointer',
    fontSize: '58px',
    marginLeft: '37px',
    '-webkit-transition': '-webkit-transform .15s ease-in-out',
    transition: 'transform .15s ease-in-out',
    '&:hover': {
      '-webkit-transform': 'rotate(360deg)',
      transform: 'font-size .15s',
      color: '#74ff69',
      fontSize: '60px',
    },
  },
  iconRemove: {
    color: '#FF7A7A',
    cursor: 'pointer',
    fontSize: '58px',
    marginLeft: '15px',
    '-webkit-transition': '-webkit-transform .15s ease-in-out',
    transition: 'transform .15s ease-in-out',
    '&:hover': {
      '-webkit-transform': 'rotate(180deg)',
      transform: 'font-size .15s',
      color: '#e74a4a',
      fontSize: '60px',
    },
  },
  canvasHolderBg: {
    opacity: 0,
    //background: 'url("/images/C.svg")',
    backgroundRepeat: 'no-repeat',
    width: '252px',
    height: '259px',
    [theme.breakpoints.down('sm')]: {},
    [theme.breakpoints.up('md')]: {},
    [theme.breakpoints.up(1360)]: {
      width: '252px',
      height: '259px',
    },
    border: '0px solid white',
    borderRadius: '50% 50%',
    position: 'absolute',
    zIndex: 2,
    top: '50%',
    left: '50%',
    //transform: 'translate(-50%, -50%)',
    animation: '$opacityX .1s linear forwards',
    overflow: 'hidden',
  },

  canvasHolderBgComplete: {
    opacity: 0,
    background: 'url("/images/trophy100x5.png")',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    width: '252px',
    height: '259px',
    [theme.breakpoints.down('sm')]: {},
    [theme.breakpoints.up('md')]: {},
    [theme.breakpoints.up(1360)]: {
      width: '252px',
      height: '259px',
    },
    border: '0px solid white',
    borderRadius: '50% 50%',
    position: 'absolute',
    zIndex: 2,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    animation: '$opacityX .1s 0s linear forwards',
  },
  '@keyframes opacityX': {
    '100%': {
      opacity: 1,
    },
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  warningHolder: {
    padding: '20px',
  },
  warningTitle: {
    fontSize: '20px',
  },
  warningBody: {
    paddingTop: '10px',
  },
  unStylelink: {
    textDecoration: 'none',
    cursor: 'pointer',
    color: '#0046FF',
    fontWeight: 600,
  },
}));

var localWheel: any;
var localCurrentDashboard: any;
var localUserDashboardProgress: any;

enum SAVE_RESPONSE_ACTION {
  NAVIGATE,
  OBFUSCATE,
  RESET,
}

var saveResponseAction: any = null;

const Wheel: FunctionComponent<RouteComponentProps> = ({
  history,
  match,
  ...props
}) => {
  const classes = useStyles();
  const [wheel, setWheel] = useState({});
  const [userDashboardProgress, setUserDashboardProgress] = useState({});
  const [currentCheckupID, setCurrentCheckupID] = useState(0);
  const [currentResultFilterQuestions, setCurrentResultFilterQuestions] =
    useState<any>();
  const [currentResultName, setCurrentResultName] = useState('');
  const [answerHeld, setAnswerHeld] = useState(null);
  const [CRFQIndex, setCRFQIndex] = useState(0);
  const [isActiveEmptyQuestions, setIsActiveEmptyQuestions] = useState(false);
  const [breadCrumb, setBreadCrumb] = useState(['Main Menu']);
  const [backHover, setBackHover] = useState(false);
  const [isDialogueVisible, setDialogueVisible] = useState(false);
  const [isWarningVisible, setWarningVisible] = useState(false);
  const [isVaultVisible, setVaultVisible] = useState(false);
  const [isDialgoueResetWarningVisible, setDialgoueResetWarningVisible] =
    useState(false);

  const [isMoreQuestionInformationShown, setMoreQuestionInformationShown] =
    useState(false);

  type startingQuestionResults = {
    results: any;
    resultList: any;
    questionList: any;
  };
  const [initialQuestionResults, setInitialQuestionResults] = useState(
    {} as startingQuestionResults
  );
  const [currentResultsCount, setCurrentResultsCount] = useState(0);
  const [isHasResultsVisible, setHasResultsVisible] = useState(false);
  const [isHasResultsVisibleTwo, setHasResultsVisibleTwo] = useState(false);
  const [isStartVisible, setStartVisible] = useState(false);
  const [startModalData, setStartModalData] = useState({ code: null });
  const [vizLockingData, setVizLockingData] = useState({ code: null });
  const [isRemoveSpokeDialogueVisible, setRemoveSpokeDialogueVisible] =
    useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [mainDivHeight, setMainDivHeight] = useState(700);
  const [totalProgressCalculated, setTotalProgressCalculated] = useState(0);
  const [isVaultInWheel, setIsVaultInWheel] = useState<null | boolean>(null);

  const dashboardApi = useDashboardApi();
  const {
    dashboardContext,
    currentDashboardId,
    totalProgress,
    globalPercentageValues,
    screenDimensions,
    homePath,
    dashboardsList,
  } = useStateValue();

  const isMobile: boolean = screenDimensions
    ? screenDimensions.isMobile
    : false;
  const [modalTabIndex, setModalTabIndex] = useState(0);

  const getActiveStep = (wheelProc) => {
    if (!wheelProc.children) {
      return 0;
    }
    let completedSteps = wheelProc.children.map(function (e, i) {
      return e.percentageComplete === 100 || e.completeNoResults === true;
    });

    const lastCompletedStep = completedSteps.lastIndexOf(true);
    let activeStep = lastCompletedStep === -1 ? 0 : lastCompletedStep + 1;
    if (isVaultInWheel === false && activeStep === completedSteps.length) {
      return activeStep - 1;
    }
    return activeStep;
  };

  const triggerLockedWarning = (data) => {
    setStartModalData(data);
    setWarningVisible(true);
  };

  const triggerStartWarning = (data: any) => {
    //hack to do nothing for legal vault and just drill down
    if (data.name === 'Legal Vault') {
      localWheel.clicked(data.code, false);
      return false;
    }

    const progress = localUserDashboardProgress.progress.checkups[data.code];
    const resultsLength = Object.keys(progress.results).length;
    if (
      resultsLength !== 0 &&
      progress.questionList.length &&
      progress.answers.length === progress.questionList.length
    ) {
      setStartModalData(data);
      setCurrentResultsCount(resultsLength);
      setHasResultsVisibleTwo(true);
    } else {
      setStartModalData(data);
      setStartVisible(true);
    }
  };

  // if there are children the inside of the wheel, the wheel will handle that, as it is a concern of the wheel
  const determineClickAction = (id: number) => {
    const checkupData: any = localCurrentDashboard.checkups[id];
    const checkupProgress: any =
      localUserDashboardProgress.progress.checkups[id];
    if (
      checkupProgress.questionList.length &&
      checkupProgress.answers.length !== checkupProgress.questionList.length
    ) {
      triggerDialogue(
        checkupData.name,
        id,
        checkupData.questions,
        checkupProgress.questionList
      );
    } else {
      navigateToCheckup(id);
    }
  };

  // this method filters out the corret questions out of the dashboard questions
  // from the progress question list
  const prepCurrentResultFilterQuestionState = (
    currentResultFilterQuestionsBase,
    currentQuestionList
  ) => {
    let activeQuestions = currentResultFilterQuestionsBase.filter(function (
      questions
    ) {
      return currentQuestionList.includes(questions.code) > 0;
    });
    setCurrentResultFilterQuestions(activeQuestions);
  };

  // let clientHeight = 500;
  const triggerDialogue = (
    name: string,
    id: number,
    currentResultFilterQuestionsBase: any,
    progressQuestionList: any
  ) => {
    setCurrentResultName(name);
    setCurrentCheckupID(id);
    setCRFQIndex(0);
    const pCheckup = localUserDashboardProgress.progress.checkups[id];

    setInitialQuestionResults({
      results: { ...pCheckup.results },
      questionList: [...progressQuestionList],
      resultList: [...pCheckup.resultList],
    });
    prepCurrentResultFilterQuestionState(
      currentResultFilterQuestionsBase,
      progressQuestionList
    );
    //when we launch the modal, remove focus from any svg element
    if (document.activeElement instanceof SVGElement) {
      document.activeElement.blur();
    }
    setDialogueVisible(true);
  };

  const updateBreadCrumbs = (id, breadCrumbText) => {
    history.push({
      pathname: id ? '/path/' + id : '/path',
    });
    setBreadCrumb(breadCrumbText);
  };

  useEffect(() => {
    // this will probably need to get more complex in the future
    if (history.action === 'POP') {
      if (localWheel && history.location.pathname === '/path') {
        localWheel.clicked(null, false);
      }
    }
  }, [history.location]);

  useEffect(() => {
    setTotalProgressCalculated(totalProgress || 0);
  }, [totalProgress]);

  useEffect(() => {
    if (globalPercentageValues) {
      const overall = Math.round(
        (globalPercentageValues.userCompletedReults /
          globalPercentageValues.totalResultsAvailable) *
          100
      );
      setTotalProgressCalculated(overall || 0);
    }
  }, [globalPercentageValues]);

  const dashboardHasVault = (wheel) => {
    return wheel.children.map((e) => e.centerOfWheel).indexOf(true) !== -1;
  };

  useEffect(() => {
    localWheel = null;
    localCurrentDashboard = null;
    localUserDashboardProgress = null;
    if (
      !dashboardContext ||
      currentDashboardId !== dashboardContext.currentDashboard.id
    ) {
      dashboardApi.fetchDashboardContext(false);
    } else {
      localUserDashboardProgress = dashboardContext.userDashboardProgress;
      localCurrentDashboard = dashboardContext.currentDashboard;

      const wheelProc = SVGBuilder.preProcessData(
        30000,
        dashboardContext.wheel,
        localUserDashboardProgress,
        localCurrentDashboard,
        setVizLockingData
      );
      setWheel(wheelProc);
      setUserDashboardProgress(dashboardContext.userDashboardProgress);
      setIsVaultInWheel(dashboardHasVault(wheelProc));
    }
  }, [dashboardContext]);

  useEffect(() => {
    //consider adding lodash so we can replace this with _.isEmpty
    if (!(Object.keys(wheel).length === 0 && wheel.constructor === Object)) {
      d3.select('#main-wheel svg').remove();
      localWheel = null;
      const localHeight = screenDimensions ? screenDimensions.clientHeight : 0;
      localWheel = new SVGBuilder(
        wheel,
        localHeight - 30,
        determineClickAction,
        triggerLockedWarning,
        triggerStartWarning,
        navigateToCheckup,
        updateBreadCrumbs,
        match.params
      );
      var svgVal = localWheel.renderWheel();

      //d3's append wants this to be a function or string literal
      d3.select('#main-wheel').append(function () {
        return svgVal;
      });

      //weird responsive wheel hack
      setMainDivHeight(localHeight);
    }
  }, [wheel]);

  // until we can do a serious refactor and address some null pointer exceptions regarding the local wheel and the backbutton
  // do not attempt to move the consequence of this into the global-reducer.
  const saveDashboard = async () => {
    await axiosWrapper<any>(
      axiosInst.post<any>('dashboard', localUserDashboardProgress.progress),
      (resp) => {
        saveDashboardCallback(true, resp);
      },
      (e, errorMessage) => saveDashboardCallback(false, errorMessage)
    );
  };

  const saveDashboardCallback = (success, data) => {
    if (success) {
      if (saveResponseAction === SAVE_RESPONSE_ACTION.OBFUSCATE) {
        window.location.reload();
      } else if (saveResponseAction === SAVE_RESPONSE_ACTION.NAVIGATE) {
        navigateToCheckup(currentCheckupID);
      } else if (saveResponseAction === SAVE_RESPONSE_ACTION.RESET) {
        window.location.reload();
      }
    } else {
      console.log('something went terribly wrong');
    }
  };

  const navigateToCheckup = (id: any) => {
    // this helps keep our percentages and data in check
    // normally we don't want to do anything that would mess with the wheel,
    // but in this case we are leaving, and we need to.
    dashboardApi.fetchDashboardContext(false);
    history.push('/action-items/' + id);
  };

  const externalBackButtonClick = () => {
    localWheel.clicked(null, false);
  };

  const buildBreadCrumbs = () => {
    const linkClassName = `${classes.link} ${
      backHover ? classes.link_hover : ''
    }`;
    if (breadCrumb.length === 1) {
      return <span>{breadCrumb[0]}</span>;
    } else {
      return (
        <Fragment>
          <a
            href="javascript:void(0)"
            className={linkClassName}
            onClick={externalBackButtonClick}
          >
            {breadCrumb[0]}
          </a>{' '}
          / <span>{breadCrumb[1]}</span>
        </Fragment>
      );
    }
  };

  const removeSpoke = () => {
    saveResponseAction = SAVE_RESPONSE_ACTION.OBFUSCATE;
    setIsSaving(true);
    //ensure a brief visual indicator data was saved.
    setTimeout(() => saveDashboard(), 1400);
  };

  ///////////////////////////////////////////////////////////////////////////
  //START: Reset answer series
  ///////////////////////////////////////////////////////////////////////////

  const resetAnswerSeries = (shouldHide: boolean = false) => {
    // if we are on the first question, no need it reverse iterate actions.
    if (CRFQIndex === 0) {
      setAnswerHeld(null);
      resetAnswerSeriesCleanup(shouldHide);
      return false;
    }
    //we want to avoid mounting and unmounting errors by not rendering during this time.
    setIsActiveEmptyQuestions(true);
    //this kicks off a CRFQ countdown to 0, that is monitered with [useEffect] below.
    iterateBackQuestion();
    resetAnswerSeriesCleanup(shouldHide);
  };

  const resetAnswerSeriesCleanup = (shouldHide: boolean = false) => {
    setModalTabIndex(0);
    setRemoveSpokeDialogueVisible(false);
    setMoreQuestionInformationShown(false);
    if (shouldHide) {
      setDialogueVisible(false);
    } else {
      setDialogueVisible(true);
    }
  };

  useEffect(() => {
    if (isActiveEmptyQuestions) {
      if (CRFQIndex > 0) {
        iterateBackQuestion();
      } else {
        setIsActiveEmptyQuestions(false);
      }
    }
  }, [CRFQIndex]);

  const iterateBackQuestion = () => {
    setMoreQuestionInformationShown(false);
    const progress =
      localUserDashboardProgress.progress.checkups[currentCheckupID];
    const prevAnswer = progress.answers[progress.answers.length - 1].userAnswer;

    processAnswer(
      { progress: localUserDashboardProgress.progress },
      prevAnswer,
      currentResultFilterQuestions[CRFQIndex - 1],
      true
    );

    progress.answers.pop();
    prepCurrentResultFilterQuestionState(
      localCurrentDashboard.checkups[currentCheckupID].questions,
      progress.questionList
    );
    setCRFQIndex(CRFQIndex - 1);
    setAnswerHeld(null);
  };

  const onMoreInformationClick = () => {
    setMoreQuestionInformationShown(true);
  };

  const isLastQuestionInSeries = () => {
    return CRFQIndex + 1 === currentResultFilterQuestions.length;
  };

  const iterateQuestions = (id: number, answer: any) => {
    if (isLastQuestionInSeries()) {
      //see if they opted to reset on their last question
      if (answer === 'B') {
        resetAnswerSeries();
        return false;
      }
    }
    setMoreQuestionInformationShown(false);

    const questionResponse = { id: id, userAnswer: answer };
    const progress =
      localUserDashboardProgress.progress.checkups[currentCheckupID];

    progress.answers.push(questionResponse);
    processAnswer(
      { progress: localUserDashboardProgress.progress },
      answer,
      currentResultFilterQuestions[CRFQIndex]
    );
    prepCurrentResultFilterQuestionState(
      localCurrentDashboard.checkups[currentCheckupID].questions,
      progress.questionList
    );

    if (isLastQuestionInSeries()) {
      const progress =
        localUserDashboardProgress.progress.checkups[currentCheckupID];
      const resultsLength = Object.keys(progress.results).length;
      if (resultsLength === 0) {
        setDialogueVisible(false);
        setRemoveSpokeDialogueVisible(true);
        //still iterate for back button
        setCRFQIndex(CRFQIndex + 1);
        return false;
      } else {
        setCurrentResultsCount(resultsLength);
        setDialogueVisible(false);
        setHasResultsVisible(true);
        //still iterate for back button
        setCRFQIndex(CRFQIndex + 1);
      }
    } else {
      //go to the next question
      setCRFQIndex(CRFQIndex + 1);
    }
    setAnswerHeld(null);
  };

  const handleModalADAKeyEvents = (
    key,
    e,
    tabRefsTop,
    tabRefsAnswers,
    tabRefsBottom,
    currentResultFilterQuestion
  ) => {
    if (key === 'tab') {
      var modalTabIndexLoc = modalTabIndex;

      if (modalTabIndexLoc < tabRefsTop.length) {
        tabRefsTop[modalTabIndexLoc].current.focus();
      } else if (modalTabIndexLoc < tabRefsTop.length + tabRefsAnswers.length) {
        tabRefsAnswers[modalTabIndexLoc - tabRefsTop.length].current.focus();
      } else {
        tabRefsBottom[
          modalTabIndexLoc - (tabRefsTop.length + tabRefsAnswers.length)
        ].current.focus();
      }

      if (
        tabRefsTop.length + tabRefsAnswers.length + tabRefsBottom.length - 1 ===
        modalTabIndexLoc
      ) {
        setModalTabIndex(0);
      } else {
        modalTabIndexLoc++;

        setModalTabIndex(modalTabIndexLoc);
      }
    }
    if (key === 'enter') {
      const id = currentResultFilterQuestion.id;
      //handle the close button
      if (modalTabIndex === 3) {
        setModalTabIndex(0);
        resetAnswerSeries(true);
        setAnswerHeld(null);
        return;
      }
      if (
        document.activeElement ===
        ReactDOM.findDOMNode(tabRefsBottom[0].current)
      ) {
        //if no answer, then do nothing i.e. simulating disabled
        if (answerHeld) {
          iterateQuestions(id, answerHeld);
          setModalTabIndex(0);
        }
        return;
      }
      if (
        document.activeElement ===
        ReactDOM.findDOMNode(tabRefsBottom[1].current)
      ) {
        if (CRFQIndex !== 0) {
          iterateBackQuestion();
          setModalTabIndex(0);
        }
        return;
      }

      const possibleAnswer =
        currentResultFilterQuestion.answer.values[
          modalTabIndex - (tabRefsTop.length + 1)
        ];
      if (possibleAnswer) {
        setAnswerHeld(possibleAnswer.key);
      } else {
        setAnswerHeld(null);
      }
    }
    e.preventDefault();
  };

  const buildResultFilterQuestions = () => {
    if (isActiveEmptyQuestions) {
      return false;
    }
    const currentResultFilterQuestion = currentResultFilterQuestions[CRFQIndex];
    const id = currentResultFilterQuestion.code;
    ////////////////////////////////////////////
    // Create tabable references for ADA reading accessability
    ////////////////////////////////////////////
    const modalTitle: any = React.createRef();
    const modalBody: any = React.createRef();
    const closeButton: any = React.createRef();
    const backButton: any = React.createRef();
    const continueButton: any = React.createRef();

    const tabRefsTop = [modalTitle, modalBody, closeButton];
    const tabRefsAnswers: any = [];
    currentResultFilterQuestion.answer.values.map(() => {
      tabRefsAnswers.push(React.createRef());
    });
    const tabRefsBottom = [continueButton, backButton];
    ////////////////////////////////////////////

    return (
      <div className={classes.resultFilterQuestion}>
        <div className={classes.rFQTitle} ref={tabRefsTop[0]} tabIndex={500}>
          {currentResultName}
        </div>
        <div
          className={classes.rFQClose}
          onClick={() => {
            resetAnswerSeries(true);
          }}
          ref={tabRefsTop[2]}
          aria-label="Close Dialogue"
          role="button"
          tabIndex={502}
        >
          <img
            src="/images/questions/X.png"
            className={classes.rFQCloseImage}
          />
        </div>
        <div
          className={classes.rFQChildTextBody}
          role="article"
          ref={tabRefsTop[1]}
          tabIndex={501}
        >
          <QuestionFormattedText text={currentResultFilterQuestion.question} />
        </div>
        <div className={classes.rFQChildTextBodySubHeading1} />
        <div className={classes.resultFilterQuestionChoices}>
          <div className={classes.rFQChild}>
            {currentResultFilterQuestion.answer.values.map((answer, index) => {
              return (
                <AnswerOption
                  tabRefs={tabRefsAnswers}
                  tabRefIndexStart={tabRefsTop.length + 500}
                  index={index}
                  answer={answer}
                  isSelected={answerHeld === answer.key}
                  setAnswerHeld={setAnswerHeld}
                  key={answer.key + index + 'answerOption'}
                />
              );
            })}

            {!isMoreQuestionInformationShown && (
              <div className={classes.rFQChildTextBodySubHeading}>
                Not sure how to answer?{' '}
                <a
                  href="#"
                  className={classes.unStylelink}
                  onClick={() => {
                    onMoreInformationClick();
                  }}
                >
                  {' '}
                  Click here for more information.{' '}
                </a>
              </div>
            )}
            {isMoreQuestionInformationShown && (
              <div className={classes.rFQChildTextBody}>
                <QuestionFormattedText
                  text={currentResultFilterQuestion.description}
                />
              </div>
            )}
            <KeyboardEventHandler
              handleKeys={['tab', 'enter']}
              onKeyEvent={(key, e) =>
                handleModalADAKeyEvents(
                  key,
                  e,
                  tabRefsTop,
                  tabRefsAnswers,
                  tabRefsBottom,
                  currentResultFilterQuestion
                )
              }
              handleFocusableElements
            />
          </div>
        </div>
        <DialogueFooter
          id={id}
          answerHeld={answerHeld}
          iterateQuestions={iterateQuestions}
          iterateBackQuestion={iterateBackQuestion}
          qLength={currentResultFilterQuestions.length}
          qIndex={CRFQIndex}
          tabRefs={tabRefsBottom}
        />
      </div>
    );
  };

  const reset = async () => {
    setIsSaving(true);
    await resetDashboard();
  };

  const resetDashboard = async () => {
    await axiosWrapper<any>(
      axiosInst.get<any>(`dashboard/${currentDashboardId}/reset`),
      (resp) => {
        window.location.reload();
      },
      (e, errorMessage) => window.location.reload()
    );
  };

  const bodyBlurClass = isDialogueVisible ? ' ' + classes.blur : '';

  const pathName = dashboardsList
    .flatMap((d) => d.dashboards)
    .find((d) => d.id === currentDashboardId)?.name;

  return (
    <Fragment>
      <LoadingOverlay
        active={isSaving}
        spinner
        text="Loading"
        styles={{
          overlay: (base) => ({
            ...base,
            background: 'rgba(18, 120, 199, 0.5)',
            position: 'fixed',
          }),
        }}
      />
      {isDialgoueResetWarningVisible && (
        <DialogueResetWarning
          onCloseClick={() => {
            setDialgoueResetWarningVisible(false);
          }}
          onResetClick={reset}
          data={{}}
        />
      )}
      {isRemoveSpokeDialogueVisible && (
        <DialogueNoResultsWarning
          data={startModalData}
          onClick={() => {
            removeSpoke();
          }}
          onCloseClick={() => {
            setRemoveSpokeDialogueVisible(false);
            resetAnswerSeries();
          }}
        />
      )}
      {isDialogueVisible && (
        <Dialogue blur={isSaving}>{buildResultFilterQuestions()}</Dialogue>
      )}
      {isWarningVisible && (
        <DialogueWarning
          onClick={() => {
            setWarningVisible(false);
          }}
          onResetClick={() => {
            setWarningVisible(false);
            setDialgoueResetWarningVisible(true);
          }}
          data={startModalData}
        />
      )}
      {isVaultVisible && (
        <DialogueVault
          onClick={() => {
            setVaultVisible(false);
          }}
          onNavigate={(code) => {
            navigateToCheckup(code);
          }}
          data={startModalData}
        />
      )}
      {isStartVisible && (
        <DialogueStart
          data={startModalData}
          onClick={() => {
            setStartVisible(false);
            localWheel.clicked(startModalData.code, false);
          }}
          onCloseClick={() => {
            setStartVisible(false);
          }}
        />
      )}
      {/******* PUT THIS BACK FOR THE STUPID LITTLE UNLOCK THING ******/}
      {/*vizLockingData.code && (breadCrumb.length === 1) &&
        <DialogueUnlocked
          data={vizLockingData}
        />
      */}
      {isHasResultsVisible && (
        <DialogueHasResults
          data={startModalData}
          currentResultsCount={currentResultsCount}
          onClick={() => {
            saveResponseAction = SAVE_RESPONSE_ACTION.NAVIGATE;
            setIsSaving(true);
            //ensure a brief visual indicator data was saved.
            setTimeout(() => saveDashboard(), 1400);
          }}
          onResetClick={() => {
            //TO DO: deal with what we do here if we can't save.
            setHasResultsVisible(false);
            resetAnswerSeries();
          }}
          onCloseClick={() => {
            setHasResultsVisible(false);
            resetAnswerSeries();
          }}
        />
      )}
      {isHasResultsVisibleTwo && (
        <DialogueHasResultsTwo
          data={startModalData}
          currentResultsCount={currentResultsCount}
          onClick={() => {
            navigateToCheckup(startModalData.code);
          }}
          onResetClick={() => {
            setHasResultsVisibleTwo(false);
            setDialgoueResetWarningVisible(true);
          }}
          onCloseClick={() => {
            setHasResultsVisibleTwo(false);
          }}
        />
      )}
      <div className={classes.article_bread_crumb}>
        <Link to={`/${homePath}`} className={classes.breadCrumbLink}>
          Home
        </Link>
        \{pathName}
      </div>

      {!isMobile && (
        <div className={classes.wheel_container + bodyBlurClass}>
          <ProgressGuageWheelStep
            wheelProc={wheel}
            totalProgressCalculated={totalProgressCalculated}
            activeStep={getActiveStep(wheel)}
            setStartModalData={setStartModalData}
            setVaultVisible={setVaultVisible}
            setDialogueResetWarningVisible={setDialgoueResetWarningVisible}
            handleClick={(code) => {
              localWheel.clicked(code, true);
            }}
          />
          <div
            id="main-wheel"
            className={classes.main}
            style={{
              maxWidth: mainDivHeight - 100 + 'px',
              minWidth: mainDivHeight - 100 + 'px',
            }}
          />
          <div className={classes.canvas_holder}>
            {userDashboardProgress && isVaultInWheel === true && (
              <ProgressGuageWheelTwo
                percent={totalProgressCalculated}
                name="Overall"
                back={externalBackButtonClick}
                showBack={breadCrumb.length === 1}
                setBackHover={setBackHover}
                setStartModalData={setStartModalData}
                setWarningVisible={setWarningVisible}
                setVaultVisible={setVaultVisible}
                wheelProc={wheel}
              />
            )}
            {userDashboardProgress && isVaultInWheel === false && (
              <img src="/images/think_legal.png" style={{ width: '180px' }} />
            )}
          </div>
        </div>
      )}

      {isMobile && (
        <Fragment>
          <ProgressGuageWheelStep
            wheelProc={wheel}
            totalProgressCalculated={totalProgressCalculated}
            activeStep={getActiveStep(wheel)}
            setStartModalData={setStartModalData}
            setVaultVisible={setVaultVisible}
            setDialogueResetWarningVisible={setDialgoueResetWarningVisible}
            handleClick={(code) => {
              localWheel.clicked(code, true);
            }}
          />
        </Fragment>
      )}
      {isDialgoueResetWarningVisible && (
        <DialogueResetWarning
          onCloseClick={() => {
            setDialgoueResetWarningVisible(false);
          }}
          onResetClick={resetDashboard}
          data={{}}
        />
      )}
    </Fragment>
  );
};

export default Wheel;
