import TermsModalContentStyled from './terms-modal-content-styled';
import React from 'react';
import { acceptedTerms } from '../../../apis/user-apis';
import { ModalT } from '../../../common/types';
import { useStateValue } from '../../../hoc/state';

const TermsModalContent = () => {
  const { globalDispatch } = useStateValue();
  const acceptTerms = () => {
    acceptedTerms(globalDispatch);
  };

  return <TermsModalContentStyled acceptTerms={acceptTerms} />;
};
export const TermsModal: ModalT = {
  on: true,
  content: TermsModalContent,
  allowClose: false,
};

export default TermsModal;
