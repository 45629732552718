import React, {
  FormEvent,
  FunctionComponent,
  useEffect,
  useState,
} from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Button, makeStyles } from '@material-ui/core';
import { Form, FormField, RegistrationChild } from '../../common/types';
import { useAccessCodeApi } from './registration-api';
import {
  isValid,
  REQUIRED,
  validateForm,
} from '../../components/UI/forms/form-utils';
import InputTextForm from '../../components/UI/input/input-text-form';

const useStyles = makeStyles((theme) => ({
  formRoot: {
    '& > div': {
      marginBottom: '1rem',
    },
  },

  codeText: {
    fontWeight: 'bold',
    fontSize: '2rem',
    color: '#545B64',
  },

  descriptionText: {
    fontSize: '.9rem',
    color: '#717171',
  },
}));

const defaultForm = {
  accessCode: {
    value: '',
    validationTypes: [REQUIRED],
    htmlAttributes: {
      maxLength: 32,
      autoFocus: true,
    },
  },
};

const AccessCode: FunctionComponent<
  RegistrationChild & RouteComponentProps
> = ({ onContinue }) => {
  const classes = useStyles();
  const [form, setForm] = useState<Form>(defaultForm);
  const { fetchCode, fetching, success, error } = useAccessCodeApi();

  useEffect(() => {
    if (success) {
      onContinue();
    }
  }, [success]);

  useEffect(() => {
    setForm({ accessCode: { ...form.accessCode, error } });
  }, [error]);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    let validatedForm = validateForm(form);
    setForm(validatedForm);

    if (isValid(validatedForm)) {
      await fetchCode(form.accessCode.value);
    }
  };

  const handleChange = (
    name: string,
    value: string,
    formField: FormField<any>
  ) => {
    const changedForm = {
      ...form,
      [name]: {
        ...form[name],
        value,
      },
    };
    setForm(changedForm);
  };

  const errorDiv = error ? <div>{error}</div> : null;

  return (
    <form onSubmit={handleSubmit} className={classes.formRoot}>
      <div className={classes.codeText}>Enter Your Code</div>
      <div className={classes.descriptionText}>
        {
          "Don't have a code? Right now we're offering limited access to our platform.  To learn more, contact us directly at "
        }
        <a href="mailto:hello@thewayfarer.io">hello@thewayfarer.io</a>
        {'.'}
      </div>
      <div>
        <InputTextForm
          onChange={handleChange}
          name="accessCode"
          formField={form.accessCode}
          htmlAttributes={form.accessCode.htmlAttributes}
        />
      </div>
      <div>
        <Button type="submit" fullWidth variant="contained" color="primary">
          Continue
        </Button>
      </div>
    </form>
  );
};

export default withRouter(AccessCode);
