import React, {
  FunctionComponent,
  MouseEvent,
  useEffect,
  useState,
} from 'react';
import { makeStyles, Popover } from '@material-ui/core';
import { Result } from '../../common/types';
import axiosInst, { axiosWrapper } from '../../common/axios';
import ResultPanel from '../../containers/module-viewer/result-panel/result-panel';

interface DefinitionParams {
  text: string;
  code: string;
}

const useStyles = makeStyles((theme) => ({
  definitionWrapper: {
    overflow: 'hidden',
    height: '100%',
  },
  titleWrapper: {
    height: '3.5rem',
    padding: '0 2.5rem ',
    display: 'table',
    backgroundColor: '#0095FF',
    color: '#fff',
    width: '100%',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '1.25rem',
    padding: '1.25rem 0',
    display: 'table-cell',
    verticalAlign: 'middle',
  },
  definition: {
    height: 'calc(100% - 3.5rem)',
    overflow: 'auto',
  },
}));

const Definition: FunctionComponent<DefinitionParams> = ({ text, code }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLAnchorElement | null>(
    null
  );
  const [result, setResult] = useState<Result | null>(null);
  const classes = useStyles();

  const handleOpen = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    axiosWrapper<Result>(
      axiosInst.get(`/results/code/${code}`),
      ({ data }) => {
        setResult(data);
      },
      (e, errorMessage) => {
        //console.log(errorMessage);
      }
    );
  }, [code]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <a
        href="#"
        onClick={handleOpen}
        target="_blank"
        rel="noopener noreferrer"
      >
        {text}
      </a>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            width: '22rem',
            height: '100%',
            maxHeight: '20rem',
            overflow: 'hidden',
          },
        }}
      >
        {result ? (
          <>
            <div className={classes.titleWrapper}>
              <div className={classes.title}>{result.title}</div>
            </div>
            <div className={classes.definition}>
              <ResultPanel result={result} hideRightPanel />
            </div>
          </>
        ) : (
          'Loading'
        )}
      </Popover>
    </>
  );
};

export default Definition;
