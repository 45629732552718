// some intermediary tracking arrays that help us manage 'go back' functionality,
// but don't make sense to save into our checkup object (server side).
var checkupQuestionWasPresentTracker: any = {};
var checkupResultWasPresentTracker: any = {};

export const processAnswer = (
  ctx: any,
  currentAnswer: string,
  currentQuestion: any,
  inverse: boolean = false
) => {
  const processAction = (ctx: any, action: string, inverse: boolean) => {
    const actionParams = action.split(' ');
    processActionVerb(ctx, actionParams, inverse);
  };

  //Action Verbs
  const ADD = 'ADD';
  const REMOVE = 'REMOVE';
  const UNLOCK = 'UNLOCK';
  const GOTO = 'GOTO';
  //Param Types
  const CHECKUP = 'CHECKUP';
  const RESULT = 'RESULT';
  const QUESTION = 'QUESTION';

  const gotoAction = (ctx: any, param1: string) => {
    if (testParamType(param1) === CHECKUP) {
      gotoCheckup(ctx, param1);
    }
  };
  const unlockAction = (ctx: any, param1: string) => {
    if (testParamType(param1) === CHECKUP) {
      unlockCheckup(ctx, param1);
    }
  };
  const removeAction = (ctx: any, param1: string, param2: string) => {
    if (param2) {
      if (testParamType(param1) === CHECKUP) {
        if (testParamType(param2) === QUESTION) {
          removeCheckupQuestion(ctx, param1, param2);
        } else if (testParamType(param2) === RESULT) {
          removeCheckupResult(ctx, param1, param2);
        }
      }
    }
  };
  const addAction = (ctx: any, param1: string, param2: string) => {
    if (param2) {
      if (testParamType(param1) === CHECKUP) {
        if (testParamType(param2) === QUESTION) {
          addCheckupQuestion(ctx, param1, param2);
        } else if (testParamType(param2) === RESULT) {
          addCheckupResult(ctx, param1, param2);
        }
      }
    }
  };
  const gotoCheckup = (ctx: any, checkupCode: string) => {
    const checkup = ctx.progress.checkups[checkupCode];
    if (checkup) {
      //Add something to context that allows us to move forward to a different checkup
    }
  };
  const unlockCheckup = (ctx: any, checkupCode: string) => {
    const checkup = ctx.progress.checkups[checkupCode];
    if (checkup) {
      checkup.locked = false;
    }
  };
  const addCheckupQuestion = (
    ctx: any,
    checkupCode: string,
    questionCodeList: string
  ) => {
    const checkup = ctx.progress.checkups[checkupCode];
    if (checkup) {
      if (inverse) {
        // we should never add back a question from the removal list that was never there to begin with.
        // Removal lists stay static in json, but presence in the list is dynamic. We handle this below.
        const primaryQuestionCode = currentQuestion.code;
        const wasInPrevQuestionListList =
          checkupQuestionWasPresentTracker[primaryQuestionCode][0];
        const questionCodeListSplit = questionCodeList.split(',');
        //////////////////////////////////////////////////
        //if these ever don't match up, we have a problem.
        //////////////////////////////////////////////////
        if (wasInPrevQuestionListList.length !== questionCodeListSplit.length) {
          //to do, handle this more gracefully...
          throw "Houstan we have an 'inverse add question' problem";
        }
        /////////////////////////////////////////////////
        let cleanedList: string[] = [];
        questionCodeListSplit.forEach((el, idx) => {
          if (wasInPrevQuestionListList[idx]) {
            cleanedList.push(el);
          }
        });
        checkup.questionList = checkup.questionList.concat(cleanedList);
        checkupQuestionWasPresentTracker[primaryQuestionCode].shift();
      } else {
        checkup.questionList = checkup.questionList.concat(
          questionCodeList.split(',')
        );
      }
    }
  };
  const addCheckupResult = (
    ctx: any,
    checkupCode: string,
    resultCodeList: string
  ) => {
    const checkup = ctx.progress.checkups[checkupCode];
    if (checkup) {
      const resultCodesSplit = resultCodeList.split(',');
      if (inverse) {
        // we should never add back a result from the removal list that was never there to begin with.
        // Removal lists stay static in json, but presence in the list is dynamic. We handle this below.
        const primaryQuestionCode = currentQuestion.code;
        const wasInPrevResultListList =
          checkupResultWasPresentTracker[primaryQuestionCode][0];
        //////////////////////////////////////////////////
        //if these ever don't match up, we have a problem.
        //////////////////////////////////////////////////
        if (wasInPrevResultListList.length !== resultCodesSplit.length) {
          //to do, handle this more gracefully...
          throw "Houstan we have an 'inverse result adding' problem";
        }
        let cleanedList: string[] = [];
        resultCodesSplit.forEach((el, idx) => {
          if (wasInPrevResultListList[idx]) {
            cleanedList.push(el);
          }
        });
        checkup.resultList = checkup.resultList.concat(cleanedList);
        cleanedList.forEach(
          (code) => (checkup.results[code] = { completed: false })
        );
        checkupResultWasPresentTracker[primaryQuestionCode].shift();
      } else {
        checkup.resultList = checkup.resultList.concat(resultCodesSplit);
        resultCodesSplit.forEach(
          (code) => (checkup.results[code] = { completed: false })
        );
      }
    }
  };

  const removeCheckupQuestion = (
    ctx: any,
    checkupCode: string,
    questionCodeList: string
  ) => {
    const checkup = ctx.progress.checkups[checkupCode];
    if (checkup) {
      // compare the proposed removal list to the current list,
      // there is no gaurantee they are the same as another module may
      // have modified these results
      let tempCheckupTracker: boolean[] = [];
      questionCodeList.split(',').forEach((el) => {
        let isIncluded = checkup.questionList.includes(el);
        tempCheckupTracker.push(isIncluded);
      });
      // toss the above boolean list into an object indexed at the questioncode
      // so we have it if we need to inverse the operation (i.e. back)
      const primaryQuestionCode = currentQuestion.code;
      if (!checkupQuestionWasPresentTracker[primaryQuestionCode]) {
        checkupQuestionWasPresentTracker[primaryQuestionCode] = [];
      }
      checkupQuestionWasPresentTracker[primaryQuestionCode].push(
        tempCheckupTracker
      );
      // finally, remove the checkup question from the question list
      checkup.questionList = checkup.questionList.filter((el) => {
        return !questionCodeList.split(',').includes(el);
      });
    }
  };

  const removeCheckupResult = (
    ctx: any,
    checkupCode: string,
    resultCodeList: string
  ) => {
    const checkup = ctx.progress.checkups[checkupCode];
    if (checkup) {
      let tempResultTracker: boolean[] = [];
      const resultCodesSplit = resultCodeList.split(',');
      // compare the proposed removal list to the current list
      resultCodesSplit.forEach((el) => {
        let isIncluded = checkup.resultList.includes(el);
        tempResultTracker.push(isIncluded);
      });
      // toss the above boolean list into an object indexed at the questioncode
      // so we have it if we need to inverse the operation (i.e. back)
      const primaryQuestionCode = currentQuestion.code;
      if (!checkupResultWasPresentTracker[primaryQuestionCode]) {
        checkupResultWasPresentTracker[primaryQuestionCode] = [];
      }
      checkupResultWasPresentTracker[primaryQuestionCode].push(
        tempResultTracker
      );
      // Finally, remove the specified results from the result list
      checkup.resultList = checkup.resultList.filter(
        (el) => !resultCodesSplit.includes(el)
      );
      // cleanup the 'completed: bool' list, as the count of this is leveraged elsewhere
      resultCodesSplit.forEach((code) => delete checkup.results[code]);
    }
  };

  const processActionVerb = (ctx: any, params: string[], inverse: boolean) => {
    if (params[0] === ADD) {
      if (inverse) {
        removeAction(ctx, params[1], params[2]);
      } else {
        addAction(ctx, params[1], params[2]);
      }
    } else if (params[0] === REMOVE) {
      if (inverse) {
        addAction(ctx, params[1], params[2]);
      } else {
        removeAction(ctx, params[1], params[2]);
      }
    } else if (params[0] === UNLOCK) {
      unlockAction(ctx, params[1]);
    } else if (params[0] === GOTO) {
      gotoAction(ctx, params[1]);
    }
  };
  const testParamType = (param: string) => {
    if (
      param.startsWith('R') ||
      param.startsWith('T') ||
      param.startsWith('t')
    ) {
      return RESULT;
    } else if (param.startsWith('C')) {
      return CHECKUP;
    } else if (param.startsWith('Q')) {
      return QUESTION;
    }
  };

  ///////////////////////////////////////////////////////////////
  //default behavior from method call below
  //////////////////////////////////////////////////////////////
  const answerActions = currentQuestion.listener.answers[currentAnswer];
  if (answerActions) {
    answerActions.forEach((action) => {
      processAction(ctx, action, inverse);
    });
  }
};
