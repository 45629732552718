import {
  CustomBreakPoints,
  Dashboard,
  ModalNewContent,
} from '../../../common/types';
import React, { FunctionComponent, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { useStateValue } from '../../../hoc/state';
import Button from '@material-ui/core/Button';
import ReactPlayer from 'react-player';
import { useHistory } from 'react-router';
import { closeModalState } from './modal-utils';

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: '5px',
    margin: '0 auto',
    overflow: 'hidden',
    width: '770px',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      width: '95%',
    },
  },
  header: {
    padding: '1rem',
    backgroundColor: '#347CB1',
    display: 'flex',
  },
  headerLogo: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-start',
  },
  headerTitle: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  headerClose: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    cursor: 'pointer',
  },
  videoHolder: {
    // width: '770px',
    display: 'flex',
    // height: '433px',
    position: 'relative',
    paddingTop: '56.25%',
  },
  reactPlayer: {
    position: 'absolute',
    top: '0',
    left: '0',
  },
  imageHolder: {
    position: 'relative',
    backgroundSize: 'cover',
    width: '100%',
    paddingTop: '56.25%',
  },
  imageOverlay: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    backgroundColor: 'rgba(52,124,177,0.8)',
    width: '100%;',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  overlayContent: {
    fontSize: '2rem',
    fontWeight: 'bold',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    textAlign: 'center',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      fontSize: '1.2rem',
    },
  },
  descriptionWrapper: {
    padding: '2rem',
    display: 'flex',
    color: '#333333',
    alignItems: 'center',
    justifyContent: 'center',
  },
  descriptionGroup: {
    display: 'flex',
    flexDirection: 'column',
    width: '60%',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      width: '100%',
    },
  },
  descriptionImageWrapper: {
    width: '40%',
    display: 'inline-flex',
    justifyContent: 'center',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      display: 'none',
    },
  },
  descriptionH1: {
    fontWeight: 'bold',
    fontSize: '.9rem',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      textAlign: 'center',
    },
  },
  descriptionH2: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    paddingBottom: '1.5rem',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      textAlign: 'center',
    },
  },
  descriptionBody: {
    color: '#717171',
    fontSize: '1rem',
    lineHeight: '1.5rem',
    paddingBottom: '2rem',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      textAlign: 'center',
      paddingBottom: '1rem',
    },
  },
  nextButton: {
    textAlign: 'left',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      textAlign: 'center',
    },
  },
}));

const PathOverviewModal: FunctionComponent<ModalNewContent> = ({
  backgroundClicked,
}) => {
  const { modalNew, globalDispatch } = useStateValue();
  const history = useHistory();

  const dashboard =
    modalNew && modalNew.data ? (modalNew.data as Dashboard) : null;
  const classes = useStyles();

  if (!dashboard) {
    return <></>;
  }

  const handleCloseModal = () => {
    globalDispatch(closeModalState);
  };

  const handlePathClick = () => {
    handleCloseModal();
    history.push(`/path/${dashboard.id}`);
  };

  useEffect(() => {
    if (backgroundClicked) {
      handleCloseModal();
    }
  }, [backgroundClicked]);

  let headerImageVid;

  if (dashboard.video) {
    headerImageVid = (
      <div className={classes.videoHolder}>
        <ReactPlayer
          url={`https://legalgps.wistia.com/medias/${dashboard.video}`}
          className={classes.reactPlayer}
          width="100%"
          height="100%"
        />
      </div>
    );
  } else {
    headerImageVid = (
      <div
        className={classes.imageHolder}
        style={{ backgroundImage: `url('${dashboard.image}')` }}
      >
        <div className={classes.imageOverlay}>
          <div className={classes.overlayContent}>{dashboard.name}</div>
        </div>
      </div>
    );
  }

  const wheelImage = `https://legal-gps-images.s3.us-east-2.amazonaws.com/wheel_thumb_${3}.png`;

  return (
    <div className={classes.content}>
      <div className={classes.header}>
        <div className={classes.headerLogo}>
          <i className="fa fa-road" aria-hidden="true"></i>
        </div>
        <div className={classes.headerTitle}>
          {dashboard.completionTime} min
        </div>
        <div className={classes.headerClose} onClick={handleCloseModal}>
          <i className="fa fa-window-close" aria-hidden="true"></i>
        </div>
      </div>
      {headerImageVid}
      <div className={classes.descriptionWrapper}>
        <div className={classes.descriptionGroup}>
          <div className={classes.descriptionH1}>Path</div>
          <div className={classes.descriptionH2}>{dashboard.name}</div>
          <div className={classes.descriptionBody}>{dashboard.description}</div>
          <div className={classes.nextButton}>
            <Button
              variant="contained"
              color="primary"
              onClick={handlePathClick}
              endIcon={
                <i
                  className={'fa fa-arrow-right'}
                  style={{ fontSize: '14px' }}
                />
              }
            >
              Start Path
            </Button>
          </div>
        </div>
        <div className={classes.descriptionImageWrapper}>
          <img src={wheelImage} alt="path" />
        </div>
      </div>
    </div>
  );
};

export default PathOverviewModal;
