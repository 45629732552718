import { Reducer } from 'react';
import {
  RegistrationActions,
  RegistrationActionType,
  RegistrationContext,
} from '../../common/types';

const registrationReducer: Reducer<RegistrationContext, RegistrationActions> = (
  state,
  action
) => {
  switch (action.type) {
    case RegistrationActionType.INIT: {
      return {
        ...state,
        state: {
          ...state.state,
          steps: action.payload.steps,
          ipAuthId: action.payload.ipAuthId,
          currentStep: 0,
        },
      };
    }
    case RegistrationActionType.REMOVE_STEP: {
      const steps = state.state.steps
        ? state.state.steps.filter((s) => s !== action.payload.stepToRemove)
        : state.state.steps;
      return {
        ...state,
        state: {
          ...state.state,
          steps,
        },
      };
    }
    case RegistrationActionType.UPDATE_PRODUCT_PLAN: {
      return {
        ...state,
        state: {
          ...state.state,
          paymentPlan: action.payload.paymentPlan,
        },
      };
    }
    case RegistrationActionType.UPDATE_USER: {
      return {
        ...state,
        state: {
          ...state.state,
          user: action.payload.user,
        },
      };
    }
    case RegistrationActionType.UPDATE_TOKEN: {
      return {
        ...state,
        state: {
          ...state.state,
          paymentToken: action.payload.paymentToken,
        },
      };
    }
    case RegistrationActionType.NEXT_PAGE: {
      return {
        ...state,
        state: {
          ...state.state,
          currentStep:
            state.state.currentStep != null
              ? ++state.state.currentStep
              : state.state.currentStep,
        },
      };
    }
    default:
      return state;
  }
};

export default registrationReducer;
