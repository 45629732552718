import React, { FunctionComponent, memo } from 'react';
import { DraftJsBlockType, ResultPanelItemProps } from '../../../common/types';
import { makeStyles } from '@material-ui/core';
import ResultPanelItemStyledText from '../../../components/universal/universal-styled-text';

const useStyles = makeStyles((theme) => ({
  articleHeader: {},
  articleHeaderH1: {
    color: '#347CB1',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    lineHeight: '2.5rem',
    paddingBottom: '.8rem',
  },
  articleHeaderH2: {
    color: '#545B64',
    fontSize: '1.525rem',
    fontWeight: 'bold',
    lineHeight: '2.525rem',
    paddingBottom: '1.063rem',
    paddingTop: '1.75rem',
  },
  articleHeaderH3: {
    color: '#545B64',
    fontSize: '1.25rem',
    lineHeight: '2.063rem',
    paddingBottom: '.6875rem',
    paddingTop: '1.3rem',
  },
  articleHeaderH4: {
    color: '#545B64',
    fontSize: '1.05rem',
    lineHeight: '1.688rem',
    paddingBottom: '.4375rem',
  },
  articleHeaderH5: {
    color: '#2E2E2E',
    fontSize: '1rem',
    fontWeight: 'bold',
  },
  articleHeaderH6: {
    color: '#2E2E2E',
    fontSize: '.9rem',
    fontWeight: 'bold',
  },
  potentialSavingsFix: {
    paddingBottom: '1.75rem',
  },
}));

const buildClasses = (
  allClasses: any,
  headerType: DraftJsBlockType,
  text: string
): string => {
  const classes: string[] = [allClasses.articleHeader];
  const header = Object.keys(DraftJsBlockType).find(
    (key) => DraftJsBlockType[key] === headerType
  );
  if (
    headerType === DraftJsBlockType.H6 &&
    text.toLocaleLowerCase().startsWith('potential savings')
  ) {
    classes.push(allClasses.potentialSavingsFix);
  }
  classes.push(allClasses[`articleHeader${header}`]);
  return classes.join(' ');
};

export const ResultPanelItemHeader: FunctionComponent<ResultPanelItemProps> = ({
  block,
  entityMap,
}) => {
  const classes = useStyles();

  return (
    <div className={buildClasses(classes, block.type, block.text)}>
      <ResultPanelItemStyledText block={block} entityMap={entityMap} />
    </div>
  );
};

export default memo(ResultPanelItemHeader);
