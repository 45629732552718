import React, { FunctionComponent } from 'react';
import { CustomBreakPoints, LandingHeaderParams } from '../../common/types';
import { makeStyles } from '@material-ui/core';
import ReactPlayer from 'react-player';
import { useStateValue } from '../../hoc/state';

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: '#2A5371',
    width: '100%',
    display: 'flex',
    height: '380px',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      height: '100%',
      padding: '2rem 3rem',
    },
  },
  headerInner: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerLeft: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    paddingRight: '4rem',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      width: '100%',
      paddingRight: '0',
      textAlign: 'center',
    },
  },
  headerRight: {
    width: '50%',
  },
  title: {
    fontSize: '1.9rem',
    fontWeight: 'bold',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      fontSize: '1.2rem',
    },
  },
  description: {
    paddingTop: '1rem',
    lineHeight: '2rem',
  },
  video: {
    height: '300px',
    width: '533px',
    marginLeft: 'auto',
    marginRight: 0,
  },
}));

const LandingHeader: FunctionComponent<LandingHeaderParams> = ({
  organization,
}) => {
  const { screenDimensions } = useStateValue();
  const classes = useStyles();
  const isMobile: boolean = screenDimensions
    ? screenDimensions.isMobile
    : false;
  const video: string | undefined = organization?.homeVideo;

  return (
    <div className={classes.header}>
      <div className={classes.headerInner}>
        <div className={classes.headerLeft}>
          <div className={classes.title}>{organization.homeHeader}</div>
          <div className={classes.description}>
            {organization.homeDescription}
          </div>
        </div>
        {!isMobile && video && (
          <div className={classes.headerRight}>
            <div className={classes.video}>
              <ReactPlayer
                url={`https://legalgps.wistia.com/medias/${video}`}
                width="100%"
                height="100%"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LandingHeader;
