import React, { Fragment, useState } from 'react';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import ModalButton from './modal-button';
import ReactDOM from 'react-dom';
import { CustomBreakPoints } from '../../../common/types';

const styles = (theme: Theme) =>
  createStyles({
    modalShade: {
      position: 'fixed',
      top: '0px',
      left: '0px',
      width: '100%',
      height: '100%',
      backgroundColor: '#2E2E2E',
      opacity: 0.5,
      zIndex: 50,
      [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
        opacity: 1,
        backgroundColor: '#63BEFF',
      },
    },
    modalBody: {
      margin: '0 auto',
      position: 'absolute',
      top: '-300px',
      left: '50%',
      marginLeft: '-176px',
      width: '352px',
      height: '',
      backgroundColor: '#63BEFF',
      zIndex: 51,
      borderRadius: '5px',
      '-webkit-box-shadow': '0px 3px 6px 0px rgba(0,0,0,0.16)',
      '-moz-box-shadow': '0px 3px 6px 0px rgba(0,0,0,0.16)',
      'box-shadow': '0px 3px 6px 0px rgba(0,0,0,0.16)',
      color: 'white',
      animation: '$change .25s linear forwards',
      [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
        '-webkit-box-shadow': '0px 3px 6px 0px rgba(0,0,0,0)',
        '-moz-box-shadow': '0px 3px 6px 0px rgba(0,0,0,0)',
        'box-shadow': '0px 3px 6px 0px rgba(0,0,0,0)',
      },
    },
    '@keyframes change': {
      '100%': {
        transform: 'translateY(390px)',
      },
    },
    modalContentHolder: {
      top: '65px',
      left: '0px',
      width: '100%',
      height: 'calc(100% - 65px)',
      //overflow: 'hidden',
      //overflowY: 'scroll',
    },

    modalContent: {
      width: '100%',
      height: '100%',
    },
    modalClose: {
      position: 'absolute',
      zIndex: 52,
      right: '57px',
      top: '50px',
      width: '25px',
      height: '25px',
      backgroundColor: '#2E2E2E',
      color: '#fff',
      fontWeight: 'bold',
      fontSize: '20px',
      textAlign: 'center',
      borderRadius: '2px',
      cursor: 'pointer',
      '-webkit-transition': '-webkit-transform .4s ease-in-out',
      transition: 'transform .4s ease-in-out',
      '&:hover': {
        opacity: 0.6,
      },
    },
    visible: {
      visibility: 'visible',
      opacity: 1,
      transition: 'opacity .1s linear',
    },
    hidden: {
      visibility: 'hidden',
      opacity: 0,
      transition: 'visibility 0s .5s, opacity .5s linear',
    },
    blur: {
      '-webkit-filter': 'blur(10px)',
      '-moz-filter': 'blur(10px)',
      '-o-filter': 'blur(10px)',
      '-ms-filter': 'blur(10px)',
      filter:
        "url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='svgMask'><feGaussianBlur stdDeviation='10' /></filter></svg>#svgMask\")",
    },
    topIconContainer: {
      textAlign: 'center',
      marginTop: '-30px',
    },
    topIconHolder: {
      backgroundColor: '#fff',
      border: '9px solid #63BEFF',
      borderRadius: '50%',
      height: '78px',
      width: '78px',
      margin: '0 auto',
    },
    icon: {
      color: '#63BEFF',
      marginTop: '9px',
      marginRight: '2px',
      fontSize: '40px',
    },
    iconBottom: {
      color: '#fff',
      marginTop: '2.2px',
      fontSize: '20px',
    },
    mHolder: {
      padding: '20px',
    },
    mTitle: {
      fontSize: '20px',
      marginTop: '-10px',
      fontWeight: 600,
    },
    mBody: {
      paddingTop: '10px',
      fontSize: '13px',
    },
    tableIndicator: {
      margin: '0 auto',
      paddingTop: '30px',
      paddingBottom: '15px',
    },
    unStylelink: {
      textDecoration: 'none',
      cursor: 'pointer',
      color: 'white',
      fontWeight: 600,
    },
    backArrow: {
      backgroundImage: "url('images/back_arrow.png')",
      width: '16px',
      height: '16px',
      position: 'relative',
    },
    backArrowHolder: {
      width: '32px',
      height: '32px',
      padding: '8px',
      position: 'absolute',
      left: '15px',
      top: '15px',
      zIndex: 52,
      cursor: 'pointer',
      [theme.breakpoints.up(CustomBreakPoints.mobile)]: {
        display: 'none',
      },
    },
  });

interface Props extends WithStyles<typeof styles> {
  onClick: any;
  data: any;
  onCloseClick: any;
}

const DialogueStart: React.FunctionComponent<Props> = (props) => {
  const { classes, onClick, data, onCloseClick } = props;
  const [isVideoVisible, setVideoVisible] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const titleText = data.name;
  const src = data.videos
    ? '//fast.wistia.net/embed/iframe/' + data.videos[0]
    : '//fast.wistia.net/embed/iframe/avk9twrrbn';
  const tabRefs: Array<any> = [
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ];
  const handleModalADAKeyEvents = (key, e, tabRefs, callback) => {
    if (key === 'tab') {
      tabRefs[tabIndex].current.focus();
      var tabIndexLoc = tabIndex;
      if (tabRefs.length - 1 === tabIndexLoc) {
        setTabIndex(0);
      } else {
        tabIndexLoc++;
        setTabIndex(tabIndexLoc);
      }
    }
    if (key === 'enter') {
      if (document.activeElement === ReactDOM.findDOMNode(tabRefs[2].current)) {
        callback();
        return;
      }
    }
    e.preventDefault();
  };

  return (
    <Fragment>
      <div className={classes.modalShade} onClick={onCloseClick} />
      <div className={classes.backArrowHolder} onClick={onCloseClick}>
        <div className={classes.backArrow} />
      </div>
      <div className={classes.modalBody}>
        <div className={classes.modalContentHolder}>
          <div className={classes.topIconContainer}>
            <div className={classes.topIconHolder}>
              <i className={'fa fa-location-arrow ' + classes.icon}></i>
            </div>
            <div className={classes.mHolder}>
              <div
                className={classes.mTitle}
                role="article"
                ref={tabRefs[0]}
                tabIndex={501}
              >
                {titleText}
              </div>
              <div
                className={classes.mBody}
                role="article"
                ref={tabRefs[1]}
                tabIndex={502}
              >
                {data.description}
              </div>
              <div style={{ minHeight: '30px' }} />
              <div
                role="button"
                ref={tabRefs[2]}
                tabIndex={503}
                aria-label="Start"
              >
                <ModalButton
                  onClick={onClick}
                  data={data}
                  type="primary"
                  text="Start"
                />
              </div>
              {data.videos && !isVideoVisible && (
                <div className={classes.mBody} style={{ marginTop: '5px' }}>
                  Still not sure?{' '}
                  <a
                    href="#"
                    className={classes.unStylelink}
                    onClick={() => {
                      setVideoVisible(true);
                    }}
                  >
                    Click here for more info.
                  </a>
                </div>
              )}
              {data.videos && isVideoVisible && (
                <Fragment>
                  <div
                    className={classes.mBody}
                    style={{
                      marginTop: '5px',
                      marginBottom: '10px',
                      textAlign: 'left',
                    }}
                  >
                    More Information:
                  </div>
                  <iframe
                    style={{ width: '100%' }}
                    src={src}
                    frameBorder="0"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                    title="video"
                  ></iframe>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
      <KeyboardEventHandler
        handleKeys={['tab', 'enter']}
        onKeyEvent={(key, e) =>
          handleModalADAKeyEvents(key, e, tabRefs, onClick)
        }
        handleFocusableElements
      />
    </Fragment>
  );
};

export default withStyles(styles)(DialogueStart);
