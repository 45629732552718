import { useLocation } from 'react-router';

export const useNestedRoute = (): string => {
  const location = useLocation();

  const pathSplit = location.pathname.split('/');

  if (pathSplit[1] === 'action-items') {
    return `/${pathSplit[1]}/${pathSplit[2]}`;
  }
  return '';
};
