import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { DownloadLinkResult, ResultLinkProps } from '../../../common/types';
import { makeStyles } from '@material-ui/core';
import axiosInst, { axiosWrapper } from '../../../common/axios';

const useStyles = makeStyles((theme) => ({
  link: {
    color: '#326891',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
      color: '#326891',
    },
    '&:visited': {
      color: '#326891',
    },
  },
}));

const ResultLink: FunctionComponent<ResultLinkProps> = ({
  url,
  text,
  external,
  download,
}) => {
  const classes = useStyles();

  const handleClick = async (e) => {
    e.preventDefault();
    axiosWrapper<DownloadLinkResult>(
      axiosInst.get(url),
      ({ data }) => {
        const downloadFrame = document.createElement('iframe');
        downloadFrame.setAttribute('src', data.url);
        downloadFrame.setAttribute('class', 'hiddenText');
        document.body.appendChild(downloadFrame);
      },
      (e, errorMessage) => {}
    );
  };

  if (external) {
    return (
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className={classes.link}
      >
        {text}
        &nbsp;
        <i className={'fa fa-external-link'} style={{ fontSize: '15px' }}></i>
      </a>
    );
  } else if (download) {
    return (
      <a
        href="#0"
        className={classes.link}
        onClick={handleClick}
        onTouchStart={handleClick}
      >
        {text}
      </a>
    );
  }
  return (
    <Link className={classes.link} to={url}>
      {text}
    </Link>
  );
};

export default ResultLink;
