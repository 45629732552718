import React, { Fragment, FunctionComponent } from 'react';
import {
  DraftJsBlock,
  DraftJsBlockType,
  ResultPanelItemListProps,
} from '../../../common/types';
import ResultPanelItemStyledText from '../../../components/universal/universal-styled-text';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  resultUlOl: {
    marginBlockStart: 0,
    marginBlockEnd: 0,
    paddingTop: '1.75rem',
    paddingBottom: '1.75rem',
    '& li': {
      paddingBottom: '.6rem',
    },
  },
}));

const buildItemTree = (
  blocks: DraftJsBlock[]
): Map<DraftJsBlock, DraftJsBlock[]> => {
  const depth = blocks[0].depth;
  let cur: DraftJsBlock | null = null;
  const map = new Map<DraftJsBlock, DraftJsBlock[]>();
  for (const block of blocks) {
    if (block.depth === depth) {
      map.set(block, []);
      cur = block;
    } else {
      if (cur) {
        let children = map.get(cur);
        if (!children) {
          children = [];
          map.set(cur, children);
        }
        children.push(block);
      }
    }
  }
  return map;
};

const ResultsPanelItemList: FunctionComponent<ResultPanelItemListProps> = ({
  blocks,
  entityMap,
}) => {
  const classes = useStyles();

  const map = buildItemTree(blocks);

  const type = blocks[0].type;

  const listItems = Array.from(map).map(([block, children]) => {
    return (
      <Fragment key={block.key}>
        <li>
          <ResultPanelItemStyledText block={block} entityMap={entityMap} />
        </li>
        {children.length > 0 ? (
          <ResultsPanelItemList blocks={children} entityMap={entityMap} />
        ) : null}
      </Fragment>
    );
  });

  return (
    <div>
      {type === DraftJsBlockType.ORDERED_LIST ? (
        <ol className={classes.resultUlOl}>{listItems}</ol>
      ) : (
        <ul className={classes.resultUlOl}>{listItems}</ul>
      )}
    </div>
  );
};

export default ResultsPanelItemList;
