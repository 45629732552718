import React, { Fragment } from 'react';
import Button from '@material-ui/core/Button';
//import { withStyles } from "@material-ui/core";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#35C37D',
      main: '#FFFFFF',
      dark: '#35C37D',
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#35C37D',
      main: '#FFFFFF',
      dark: '#35C37D',
    },
    // error: will use the default color
  },
  overrides: {
    MuiButton: {
      // Name of the rule
      label: {
        marginTop: '-2px',
        textDecoration: 'none !important',
      },
      root: {
        // Some CSS
        minWidth: '100%',
        background: 'white',
        borderRadius: 0,
        border: 0,
        colorInherit: '#fff',
        color: 'white',
        height: 40,
        fontWeight: 600,
        padding: '0 30px',
        boxShadow: '4px 9px 26px 0 rgba(16,124,71,0.10)',
        textTransform: 'none',
        '&:hover': {
          textDecoration: 'none !important',
          backgroundColor: '#fafafa',
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: '#fafafa',
          },
        },
      },
      textPrimary: {
        color: '#63BEFF',
        '&:hover': {
          textDecoration: 'none !important',
          backgroundColor: '#white',
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: '#white',
          },
        },
      },
      textSecondary: {
        color: '#72D597',
        '&:hover': {
          textDecoration: 'none !important',
          backgroundColor: '#white',
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: '#white',
          },
        },
      },
      contained: {
        color: '#fff',
      },
      outlined: {
        '&:hover': {
          backgroundColor: '#35C37D',
        },
      },
      containedPrimary: {
        color: '#fff',
      },

      containedSecondary: {
        color: '#fff',
      },

      //raised: {
      //  color: "#fff"
      //},

      colorInherit: {
        color: '#afafaf',
        //border: "1px solid #afafaf",
        backgroundColor: '#E5E5E5',
        '&:hover': {
          backgroundColor: '#E5E5E5',
        },
      },
    },
  },
  typography: {
    // The default font size of the Material Specification.
    fontSize: 19, // px
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    // Tell Material-UI what's the font-size on the html element.
    // 16px is the default font-size used by browsers.
    htmlFontSize: 16,
  },
});

interface Props {
  onClick: any;
  data: any;
  type: any;
  text: string;
}

const ModalButton: React.FunctionComponent<Props> = (props) => {
  const { onClick, data, type, text } = props;

  const clickDelay = () => {
    setTimeout(function () {
      onClick();
    }, 300);
  };

  return (
    <Fragment>
      <MuiThemeProvider theme={theme}>
        <div style={{ minWidth: '100%' }}>
          <Button color={type} onClick={onClick}>
            {text}
          </Button>
        </div>
      </MuiThemeProvider>
    </Fragment>
  );
};

export default ModalButton;
