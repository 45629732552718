import React, { Fragment, useState } from 'react';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import Typist from 'react-typist';
import Check from '@material-ui/icons/CheckCircle';
import ModalButton from './modal-button';
import ReactDOM from 'react-dom';
import { CustomBreakPoints } from '../../../common/types';

const styles = (theme: Theme) =>
  createStyles({
    modalShade: {
      position: 'fixed',
      top: '0px',
      left: '0px',
      width: '100%',
      height: '100%',
      backgroundColor: 'white',
      opacity: 1,
      zIndex: 50,
      animation: '$opacityX .5s 4.3s linear forwards',
      [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
        opacity: 1,
        backgroundColor: '#72D597',
      },
    },
    '@keyframes opacityX': {
      '100%': {
        opacity: 0.4,
      },
    },
    modalBody: {
      margin: '0 auto',
      position: 'absolute',
      top: '-320px',
      left: '50%',
      marginLeft: '-176px',
      width: '352px',
      height: '',
      backgroundColor: '#72D597',
      zIndex: 51,
      borderRadius: '5px',
      '-webkit-box-shadow': '0px 3px 6px 0px rgba(0,0,0,0.16)',
      '-moz-box-shadow': '0px 3px 6px 0px rgba(0,0,0,0.16)',
      'box-shadow': '0px 3px 6px 0px rgba(0,0,0,0.16)',
      [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
        '-webkit-box-shadow': '0px 3px 6px 0px rgba(0,0,0,0)',
        '-moz-box-shadow': '0px 3px 6px 0px rgba(0,0,0,0)',
        'box-shadow': '0px 3px 6px 0px rgba(0,0,0,0)',
      },
      color: 'white',
      animation: '$change .20s 4.5s linear forwards',
    },
    '@keyframes change': {
      '100%': {
        transform: 'translateY(410px)',
      },
    },
    callibratingLogo: {
      background: 'url("/images/gps_animation.gif")',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      width: '300px',
      height: '331px',
    },
    callibratingText: {
      color: '#0095ff',
      fontWeight: 'bold',
      fontSize: '20px',
      textAlign: 'center',
      padding: '30px',
    },
    callibrating: {
      width: '300px',
      height: '431px',
      margin: '0 auto',
      position: 'absolute',

      left: '50%',
      marginLeft: '-150px',
      top: '100px',
      zIndex: 50,
      animation: '$change2 .3s 4s linear forwards',
    },
    '@keyframes change2': {
      '100%': {
        transform: 'translateY(-600px)',
      },
    },
    modalContentHolder: {
      top: '65px',
      left: '0px',
      width: '100%',
      height: 'calc(100% - 65px)',
      //overflow: 'hidden',
      //overflowY: 'scroll',
    },

    modalContent: {
      width: '100%',
      height: '100%',
    },
    modalClose: {
      position: 'absolute',
      zIndex: 52,
      right: '57px',
      top: '50px',
      width: '25px',
      height: '25px',
      backgroundColor: '#2E2E2E',
      color: '#fff',
      fontWeight: 'bold',
      fontSize: '20px',
      textAlign: 'center',
      borderRadius: '2px',
      cursor: 'pointer',
      '-webkit-transition': '-webkit-transform .4s ease-in-out',
      transition: 'transform .4s ease-in-out',
      '&:hover': {
        opacity: 0.6,
      },
    },
    visible: {
      visibility: 'visible',
      opacity: 1,
      transition: 'opacity .1s linear',
    },
    hidden: {
      visibility: 'hidden',
      opacity: 0,
      transition: 'visibility 0s .5s, opacity .5s linear',
    },
    blur: {
      '-webkit-filter': 'blur(10px)',
      '-moz-filter': 'blur(10px)',
      '-o-filter': 'blur(10px)',
      '-ms-filter': 'blur(10px)',
      filter:
        "url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='svgMask'><feGaussianBlur stdDeviation='10' /></filter></svg>#svgMask\")",
    },
    topIconContainer: {
      textAlign: 'center',
      marginTop: '-30px',
    },
    topIconHolder: {
      backgroundColor: '#fff',
      border: '9px solid #72D597',
      borderRadius: '50%',
      height: '78px',
      width: '78px',
      margin: '0 auto',
    },
    icon: {
      color: '#63FF9C',
      marginTop: '9px',
      marginRight: '2px',
      fontSize: '40px',
    },
    iconBottom: {
      color: '#fff',
      marginTop: '2.2px',
      fontSize: '20px',
    },
    mHolder: {
      padding: '20px',
    },
    mTitle: {
      fontSize: '20px',
      marginTop: '-10px',
      fontWeight: 600,
    },
    mBody: {
      paddingTop: '10px',
      fontSize: '13px',
    },
    tableIndicator: {
      margin: '0 auto',
      paddingTop: '30px',
      paddingBottom: '15px',
    },
    unStylelink: {
      textDecoration: 'none',
      cursor: 'pointer',
      color: 'white',
      fontWeight: 600,
    },
  });

interface Props extends WithStyles<typeof styles> {
  onClick: any;
  onResetClick: any;
  data: any;
  onCloseClick: any;
  currentResultsCount: number;
}

const DialogueHasResults: React.FunctionComponent<Props> = (props) => {
  const {
    classes,
    onClick,
    onResetClick,
    data,
    onCloseClick,
    currentResultsCount,
  } = props;
  const [tabIndex, setTabIndex] = useState(0);
  const tabRefs: Array<any> = [
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ];
  const handleModalADAKeyEvents = (
    key,
    e,
    tabRefs,
    callbackOne,
    callbackTwo
  ) => {
    if (key === 'tab') {
      tabRefs[tabIndex].current.focus();
      var tabIndexLoc = tabIndex;
      if (tabRefs.length - 1 === tabIndexLoc) {
        setTabIndex(0);
      } else {
        tabIndexLoc++;
        setTabIndex(tabIndexLoc);
      }
    }
    if (key === 'enter') {
      if (document.activeElement === ReactDOM.findDOMNode(tabRefs[2].current)) {
        callbackOne();
        return;
      }
      if (document.activeElement === ReactDOM.findDOMNode(tabRefs[3].current)) {
        callbackTwo();
        return;
      }
    }
    e.preventDefault();
  };

  return (
    <Fragment>
      <div className={classes.modalShade} onClick={onClick} />
      <div className={classes.modalBody}>
        <div className={classes.modalContentHolder}>
          <div className={classes.topIconContainer}>
            <div className={classes.topIconHolder}>
              <i className={'fa fa-location-arrow ' + classes.icon}></i>
            </div>
            <div className={classes.mHolder}>
              <div
                className={classes.mTitle}
                role="article"
                ref={tabRefs[0]}
                tabIndex={501}
              >
                {data.name}
              </div>
              <div
                className={classes.mBody}
                role="article"
                ref={tabRefs[1]}
                tabIndex={502}
              >
                Congratulations! You have results.
              </div>
              <div style={{ minHeight: '30px' }} />
              <div
                role="button"
                ref={tabRefs[2]}
                tabIndex={503}
                aria-label="View Results"
              >
                <ModalButton
                  onClick={onClick}
                  data={data}
                  type="secondary"
                  text="View Results"
                />
              </div>
              <div
                className={classes.mBody}
                style={{ marginTop: '5px' }}
                role="button"
                ref={tabRefs[3]}
                tabIndex={504}
                aria-label="Reset Answers"
              >
                Made a mistake?{' '}
                <a
                  href="#"
                  className={classes.unStylelink}
                  onClick={() => {
                    onResetClick();
                  }}
                >
                  Reset Answers
                </a>
              </div>

              <div className={classes.mBody}>
                <table className={classes.tableIndicator}>
                  <tbody>
                    <tr>
                      <td>
                        <Check
                          className={classes.iconBottom}
                          fontSize="small"
                        />
                      </td>
                      <td>
                        &nbsp;{currentResultsCount}{' '}
                        results&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.callibrating}>
        <div className={classes.callibratingText}>
          <Typist
            startDelay={400}
            cursor={{ hideWhenDone: false, hideWhenDoneDelay: 400 }}
          >
            Loading...
          </Typist>
        </div>
        <div className={classes.callibratingLogo}></div>
      </div>
      <KeyboardEventHandler
        handleKeys={['tab', 'enter']}
        onKeyEvent={(key, e) =>
          handleModalADAKeyEvents(key, e, tabRefs, onClick, onResetClick)
        }
        handleFocusableElements
      />
    </Fragment>
  );
};

export default withStyles(styles)(DialogueHasResults);
