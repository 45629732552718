import React, { FunctionComponent } from 'react';
import { RouteComponentProps } from 'react-router';
import { makeStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { CustomBreakPoints } from '../../common/types';
import { useStateValue } from '../../hoc/state';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  registerRoot: {
    height: 'calc(100% - 43px)',
    display: 'flex',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      height: '100%',
    },
  },
  header: {
    textAlign: 'center',
    marginTop: '1rem',
    width: '100%',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      display: 'none',
    },
  },
  headerImage: {
    width: 'auto',
    height: '43px',
  },
  leftColumn: {
    width: '50%',
    height: '100%',
    color: '#2E2E2E',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    paddingRight: '2rem',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      width: '100%',
      minWidth: '90%',
      position: 'absolute',
      top: '200px',
      left: '0px',
      height: 'calc(100% - 200px)',
      overflowY: 'scroll',
      justifyContent: 'start',
      paddingTop: '20px',
      alignItems: 'center',
      paddingRight: '0',
    },
  },
  rightColumn: {
    width: '50%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '2rem',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      width: '0%',
      paddingLeft: '0',
      display: 'none',
    },
  },
  colummKeyboardOpen: {
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      height: 'calc(100% - 68px)',
      position: 'absolute',
      top: '68px',
      left: '0px',
    },
  },
  step: {
    width: '30%',
  },
  marginBottom: {
    marginBottom: '.5rem',
  },
  sideImage: {
    width: '100%',
    maxWidth: '340px',
    marginTop: '65px',
  },
  logoImage: {
    width: '65%',
    float: 'right',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  mobileHeader: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'auto auto',
    backgroundPosition: 'center',
    height: '200px',
    minHeight: '200px',
    maxHeight: '200px',
    minWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up(CustomBreakPoints.mobile)]: {
      display: 'none',
    },
    position: 'absolute',
    top: '0px',
    left: '0px',
  },
  headerKeyboardOpen: {
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      height: '68px',
      minHeight: '68px',
      maxHeight: '68px',
    },
  },
  logoImageText: {
    width: '174px',
    height: '43px',
  },
  standardHeader: {
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      display: 'none',
    },
  },
  rightLogo: {
    width: '450px',
    height: '391px',
  },
}));

const PublicLayout: FunctionComponent<RouteComponentProps> = ({
  location,
  match,
  children,
}) => {
  const classes = useStyles();
  const { isKeyboardOpenMobile, org } = useStateValue();
  const orgLogo = org?.loginLogo || '/images/logo.png';
  const loginImage =
    org?.loginImage ||
    'https://legal-gps-images.s3.us-east-2.amazonaws.com/wayfarer/login-logo.png';
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <img src={orgLogo} className={classes.headerImage} />
      </div>
      <div className={classes.registerRoot}>
        <div
          className={
            classes.mobileHeader +
            ' ' +
            (isKeyboardOpenMobile ? classes.headerKeyboardOpen : '')
          }
        >
          <img src={orgLogo} className={classes.headerImage} />
        </div>
        <div
          className={
            classes.leftColumn +
            ' ' +
            (isKeyboardOpenMobile ? classes.colummKeyboardOpen : '')
          }
        >
          <div
            className={classes.standardHeader}
            style={{ marginBottom: '.5rem' }}
          ></div>
          {children}
        </div>
        <div className={classes.rightColumn}>
          <img src={loginImage} className={classes.rightLogo} />
        </div>
      </div>
    </div>
  );
};

export default withRouter(PublicLayout);
