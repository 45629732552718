import React, { FunctionComponent, useState } from 'react';
import { Redirect, withRouter } from 'react-router';
import { Button, makeStyles } from '@material-ui/core';
import { useVerificationCodeApi } from './registration-api';
import ResetPasswordTry from './reset-password-try';
import ResetPasswordPin from './reset-password-pin';
import ResetPasswordMatch from './reset-password-match';
import { CustomBreakPoints } from '../../common/types';
import { useStateValue } from '../../hoc/state';

const useStyles = makeStyles((theme) => ({
  step: {
    width: '65%',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      width: '100%',
      textAlign: 'center',
    },
  },
  marginBottom: {
    marginBottom: '.5rem',
  },
}));

const ResetPassword: FunctionComponent<any> = () => {
  const classes = useStyles();
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState<string | null>(null);
  const [code, setCode] = useState<string | null>(null);
  const [redirect, setRedirect] = useState<string | null>(null);
  const { success, verifyCode, error } = useVerificationCodeApi();
  const { isKeyboardOpenMobile } = useStateValue();

  const handleStepOne = (email: string) => {
    setStep(2);
    setEmail(email);
  };

  const handleStepTwo = (code: string) => {
    setStep(3);
    setCode(code);
  };

  const handleStepThree = () => {
    setStep(4);
  };

  const handleStepFour = () => {
    setRedirect('/login');
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div className={classes.step}>
      <div className={classes.marginBottom}>
        {step === 1 && <ResetPasswordTry onContinue={handleStepOne} />}
        {step === 2 && <ResetPasswordPin onContinue={handleStepTwo} />}
        {step === 3 && (
          <ResetPasswordMatch
            onContinue={handleStepThree}
            code={code}
            email={email}
          />
        )}
        {step === 4 && (
          <div>
            <div>
              <h3>Your password has been reset!</h3>
              <br />
              <div>
                <Button
                  onClick={handleStepFour}
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  Continue to Login
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(ResetPassword);
