import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import queryString from 'query-string';
import {
  fetchOrganizationByCode,
  fetchOrganizationByDomain,
} from '../apis/org-apis';
import { useStateValue } from './state';

const Org: React.FunctionComponent<RouteComponentProps> = (props) => {
  const { globalDispatch, org } = useStateValue();
  const { location } = props;
  useEffect(() => {
    const host = window.location.host;
    const parse = queryString.parse(location.search);
    const orgParam = parse.org;
    if (
      orgParam &&
      typeof orgParam === 'string' &&
      (!org || (org && org.code !== orgParam))
    ) {
      fetchOrganizationByCode(orgParam, globalDispatch);
    } else if (
      process.env.REACT_APP_DOMAIN !== host &&
      (!org || (org && org.domain && !host.includes(org.domain)))
    ) {
      const hostArr = host
        .split('.')
        .slice(0, host.includes('localhost') ? -1 : -2);
      if (hostArr.length > 0) {
        fetchOrganizationByDomain(hostArr[0], globalDispatch);
      }
    }
  }, [location]);
  return <div></div>;
};

export default withRouter(Org);
