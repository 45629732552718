import React, {
  FormEvent,
  FunctionComponent,
  useEffect,
  useState,
} from 'react';
import { withRouter } from 'react-router';
import { Form, FormField } from '../../common/types';
import InputTextForm from '../../components/UI/input/input-text-form';
import { Button, makeStyles } from '@material-ui/core';
import {
  EMAIL,
  isValid,
  REQUIRED,
  validateForm,
  validateFormField,
} from '../../components/UI/forms/form-utils';
import { useResetPasswordPinGenApi } from './registration-api';

const useStyles = makeStyles((theme) => ({
  formRoot: {
    '& > div': {
      marginBottom: '1rem',
    },
  },
}));

const defaultForm = {
  email: {
    value: '',
    label: "Enter your email address, and we'll send you a reset code.",
    validationTypes: [EMAIL, REQUIRED],
    htmlAttributes: {
      maxLength: 255,
    },
  },
};

const ResetPasswordTry: FunctionComponent<any> = ({ onContinue }) => {
  const [form, setForm] = useState<Form>(defaultForm);
  const { fetching, emailCode, success, error } = useResetPasswordPinGenApi();

  const classes = useStyles();

  const handleChange = (
    name: string,
    value: string,
    formField: FormField<any>
  ) => {
    const changedForm = {
      ...form,
      [name]: {
        ...form[name],
        value,
      },
    };
    setForm(changedForm);
  };

  useEffect(() => {
    if (success) {
      onContinue(form.email.value);
    }
  }, [success]);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    let validatedForm = validateForm(form);
    setForm(validatedForm);

    if (isValid(validatedForm)) {
      emailCode(form.email.value);
    }
  };

  const validateOnBlur = (name: string, formField: FormField<any>) => {
    setForm({ ...form, [name]: validateFormField(formField, form) });
  };

  if (fetching) {
    return <div>loading...</div>;
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.formRoot}>
        {Object.keys(form).map((ff) => (
          <div key={ff}>
            <InputTextForm
              onBlur={validateOnBlur}
              onChange={handleChange}
              formField={form[ff]}
              name={ff}
              htmlAttributes={form[ff].htmlAttributes}
            />
          </div>
        ))}
        <div>
          <Button type="submit" fullWidth variant="contained" color="primary">
            Reset Password
          </Button>
        </div>
      </div>
    </form>
  );
};

export default withRouter(ResetPasswordTry);
