import React, { FormEvent, FunctionComponent, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  Form,
  FormField,
  RegistrationActionType,
  RegistrationChild,
} from '../../common/types';
import { Button, Link, makeStyles } from '@material-ui/core';
import {
  EMAIL,
  FIELDS_MATCH,
  isValid,
  MAX_LENGTH,
  MIN_LENGTH,
  REQUIRED,
  validateForm,
  validateFormField,
} from '../../components/UI/forms/form-utils';
import { useRegistrationValue } from './registration-provider';
import { isDuplicateEmail } from '../../apis/user-apis';
import FormFields from '../../components/UI/forms/form-fields';
import { useRegistrationApi } from './registration-api';
import { Link as RouterLink } from 'react-router-dom';
import { useStateValue } from '../../hoc/state';

const useStyles = makeStyles((theme) => ({
  formRoot: {
    '& > div': {
      marginBottom: '1rem',
    },
  },

  codeText: {
    fontWeight: 'bold',
    fontSize: '2rem',
    color: '#545B64',
  },
  descriptionText: {
    fontSize: '.9rem',
    color: '#717171',
  },
  termsText: {
    color: '#656565',
    fontSize: '.75rem',
  },
}));

const defaultForm = {
  email: {
    value: '',
    label: 'Email',
    validationTypes: [EMAIL],
    htmlAttributes: {
      maxLength: 255,
      autoFocus: true,
    },
  },
  firstName: {
    value: '',
    label: 'First Name',
    validationTypes: [REQUIRED],
    htmlAttributes: {
      maxLength: 50,
    },
  },
  lastName: {
    value: '',
    label: 'Last Name',
    validationTypes: [REQUIRED],
    htmlAttributes: {
      maxLength: 50,
    },
  },
  password: {
    value: '',
    label: 'Password',
    htmlAttributes: { type: 'password', maxLength: 30 },
    validationTypes: [REQUIRED, MIN_LENGTH(6), MAX_LENGTH(30)],
  },
  confirmPassword: {
    value: '',
    label: 'Confirm Password',
    validationTypes: [FIELDS_MATCH('password')],
    htmlAttributes: { type: 'password' },
  },
};

const UserDetails: FunctionComponent<
  RegistrationChild & RouteComponentProps
> = ({ onContinue }) => {
  const [form, setForm] = useState<Form>(defaultForm);
  const { state, dispatch } = useRegistrationValue();
  const { isIPAuthorized, isPaymentRequired, registerNewUser } =
    useRegistrationApi();
  const { org } = useStateValue();

  const classes = useStyles();

  const handleChange = (
    name: string,
    value: string,
    formField: FormField<any>
  ) => {
    const changedForm = {
      ...form,
    };
    changedForm[name] = {
      ...changedForm[name],
      value,
    };
    setForm(changedForm);
  };

  const validateOnBlur = (name: string, formField: FormField<any>) => {
    const changedForm = {
      ...form,
    };
    changedForm[name] = validateFormField(formField, form);
    setForm(changedForm);
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    let validatedForm = validateForm(form);

    if (isValid(validatedForm)) {
      const isDuplicated = await isDuplicateEmail(form.email.value);
      if (isDuplicated) {
        setForm({
          ...validatedForm,
          email: { ...validatedForm.email, error: 'Email Exists' },
        });
      } else {
        const user = {
          firstName: form.firstName.value,
          lastName: form.lastName.value,
          email: form.email.value.toLowerCase(),
          password: form.password.value,
          org: org,
        };
        dispatch({
          type: RegistrationActionType.UPDATE_USER,
          payload: {
            user: user,
          },
        });
        if (isIPAuthorized || !isPaymentRequired) {
          await registerNewUser(user);
        }
        onContinue();
      }
    } else {
      setForm(validatedForm);
    }
  };

  const heading = org?.loginHeader || 'Sign up to get started';

  return (
    <form onSubmit={handleSubmit} className={classes.formRoot}>
      <div className={classes.codeText}>{heading}</div>
      {org?.loginDescription ? (
        <div className={classes.descriptionText}>{org?.loginDescription}</div>
      ) : null}
      <div style={{ textAlign: 'left' }}>
        <div>
          <Link component={RouterLink} to="/" variant="body2">
            Already Registered?
          </Link>
        </div>
      </div>
      <FormFields form={form} onChange={handleChange} onBlur={validateOnBlur} />
      <div>
        <Button type="submit" fullWidth variant="contained" color="primary">
          Continue
        </Button>
      </div>
      <div className={classes.termsText}>
        {'By clicking the “Continue” button, you agree to Wayfarer.io’s '}
        <Link
          href="https://www.thewayfarer.io/terms-of-use"
          variant="body2"
          target="_blank"
          style={{ fontSize: '.75rem' }}
        >
          {'Terms of Use'}
        </Link>
        {' and '}
        <Link
          href="https://www.thewayfarer.io/privacy-policy"
          variant="body2"
          target="_blank"
          style={{ fontSize: '.75rem' }}
        >
          {'Privacy Policy'}
        </Link>
      </div>
    </form>
  );
};

export default withRouter(UserDetails);
