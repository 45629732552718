import React, { createContext, useContext } from 'react';
import { injectStripe } from 'react-stripe-elements';

const StripeHookContext = createContext({});

const StripeHookProvider = injectStripe(({ stripe, elements, children }) => {
  return (
    <StripeHookContext.Provider value={[stripe, elements]}>
      {children}
    </StripeHookContext.Provider>
  );
});

export const useStripe = () => useContext(StripeHookContext);

export default StripeHookProvider;
