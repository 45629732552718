import React, { FunctionComponent } from 'react';
import { CustomBreakPoints, PathSlideParams } from '../../common/types';
import { Slide } from 'pure-react-carousel';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  slide: {
    marginLeft: '3px',
    cursor: 'pointer',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      marginRight: '3px',
      width: '100%',
    },
  },
  imageWrapper: {
    display: 'flex',
    position: 'relative',
    boxShadow: '0px 3px 6px #00000029',
    border: '1px solid #CBCBCB',
    [theme.breakpoints.up(CustomBreakPoints.mobile)]: {
      width: '300px',
      height: '169px',
    },
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      width: '100%',
      paddingTop: 'calc(56.25% - 1px)',
    },
  },
  image: {
    top: 0,
    right: 0,
    width: '100%',
    height: '100%',
    position: 'absolute',
    objectFit: 'cover',
  },
  header: {
    color: '#414042',
    fontSize: '.8rem',
    marginTop: '10px',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      marginTop: '.4rem',
    },
  },
  headerText: {
    color: '#414042',
    fontSize: '1rem',
    marginTop: '10px',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      marginTop: '.4rem',
    },
  },
}));

const PathSlide: FunctionComponent<PathSlideParams> = ({
  index,
  dashboard,
  onClick,
  mobile,
}) => {
  const classes = useStyles();

  const slideBody = (
    <div className={classes.slide} onClick={onClick}>
      <div className={classes.imageWrapper}>
        <img
          src={dashboard.image}
          alt={dashboard.name}
          className={classes.image}
        />
      </div>
      <div className={classes.header}>Path</div>
      <div className={classes.headerText}>{dashboard.name}</div>
    </div>
  );

  if (mobile) {
    return slideBody;
  }

  return <Slide index={index}>{slideBody}</Slide>;
};

export default PathSlide;
