import React, { Fragment, useState } from 'react';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import Moto from '@material-ui/icons/Warning';
import ModalButton from './modal-button';
import ReactDOM from 'react-dom';
import { CustomBreakPoints } from '../../../common/types';

const styles = (theme: Theme) =>
  createStyles({
    modalShade: {
      position: 'fixed',
      top: '0px',
      left: '0px',
      width: '100%',
      height: '100%',
      backgroundColor: '#2E2E2E',
      opacity: 0.9,
      zIndex: 50,
      [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
        opacity: 1,
        backgroundColor: '#63BEFF',
      },
    },
    modalBody: {
      margin: '0 auto',
      position: 'absolute',
      top: '-300px',
      left: '50%',
      marginLeft: '-176px',
      width: '352px',
      height: '',
      backgroundColor: '#63BEFF',
      zIndex: 51,
      borderRadius: '5px',
      '-webkit-box-shadow': '0px 3px 6px 0px rgba(0,0,0,0.16)',
      '-moz-box-shadow': '0px 3px 6px 0px rgba(0,0,0,0.16)',
      'box-shadow': '0px 3px 6px 0px rgba(0,0,0,0.16)',
      [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
        '-webkit-box-shadow': '0px 3px 6px 0px rgba(0,0,0,0)',
        '-moz-box-shadow': '0px 3px 6px 0px rgba(0,0,0,0)',
        'box-shadow': '0px 3px 6px 0px rgba(0,0,0,0)',
      },
      color: 'white',
      animation: '$change .25s linear forwards',
    },
    '@keyframes change': {
      '100%': {
        transform: 'translateY(390px)',
      },
    },
    modalContentHolder: {
      top: '65px',
      left: '0px',
      width: '100%',
      height: 'calc(100% - 65px)',
      //overflow: 'hidden',
      //overflowY: 'scroll',
    },

    modalContent: {
      width: '100%',
      height: '100%',
    },
    modalClose: {
      position: 'absolute',
      zIndex: 52,
      right: '57px',
      top: '50px',
      width: '25px',
      height: '25px',
      backgroundColor: '#2E2E2E',
      color: '#fff',
      fontWeight: 'bold',
      fontSize: '20px',
      textAlign: 'center',
      borderRadius: '2px',
      cursor: 'pointer',
      '-webkit-transition': '-webkit-transform .4s ease-in-out',
      transition: 'transform .4s ease-in-out',
      '&:hover': {
        opacity: 0.6,
      },
    },
    visible: {
      visibility: 'visible',
      opacity: 1,
      transition: 'opacity .1s linear',
    },
    hidden: {
      visibility: 'hidden',
      opacity: 0,
      transition: 'visibility 0s .5s, opacity .5s linear',
    },
    blur: {
      '-webkit-filter': 'blur(10px)',
      '-moz-filter': 'blur(10px)',
      '-o-filter': 'blur(10px)',
      '-ms-filter': 'blur(10px)',
      filter:
        "url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='svgMask'><feGaussianBlur stdDeviation='10' /></filter></svg>#svgMask\")",
    },
    topIconContainer: {
      textAlign: 'center',
      marginTop: '-30px',
    },
    topIconHolder: {
      height: '78px',
      width: '78px',
      margin: '0 auto',
    },
    icon: {
      color: 'white',
      marginTop: '6px',
      fontSize: '70px',
    },
    iconBottom: {
      color: '#fff',
      marginTop: '2.2px',
      fontSize: '20px',
    },
    mHolder: {
      padding: '20px',
    },
    mTitle: {
      fontSize: '20px',
      marginTop: '-10px',
      fontWeight: 600,
    },
    mBody: {
      paddingTop: '10px',
      fontSize: '13px',
    },
    tableIndicator: {
      margin: '0 auto',
      paddingTop: '30px',
      paddingBottom: '15px',
    },
    unStylelink: {
      textDecoration: 'none',
      cursor: 'pointer',
      color: 'white',
      fontWeight: 600,
    },
    backArrow: {
      backgroundImage: "url('images/back_arrow.png')",
      width: '16px',
      height: '16px',
      position: 'relative',
    },
    backArrowHolder: {
      width: '32px',
      height: '32px',
      padding: '8px',
      position: 'absolute',
      left: '15px',
      top: '15px',
      zIndex: 52,
      cursor: 'pointer',
      [theme.breakpoints.up(CustomBreakPoints.mobile)]: {
        display: 'none',
      },
    },
  });

interface Props extends WithStyles<typeof styles> {
  data: any;
  onCloseClick: any;
  onResetClick: any;
}

const DialogueResetWarning: React.FunctionComponent<Props> = (props) => {
  const { classes, onCloseClick, onResetClick } = props;
  const [tabIndex, setTabIndex] = useState(0);
  const tabRefs: Array<any> = [
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ];
  const handleModalADAKeyEvents = (
    key,
    e,
    tabRefs,
    callbackOne,
    callbackTwo
  ) => {
    if (key === 'tab') {
      tabRefs[tabIndex].current.focus();
      var tabIndexLoc = tabIndex;
      if (tabRefs.length - 1 === tabIndexLoc) {
        setTabIndex(0);
      } else {
        tabIndexLoc++;
        setTabIndex(tabIndexLoc);
      }
    }
    if (key === 'enter') {
      if (document.activeElement === ReactDOM.findDOMNode(tabRefs[1].current)) {
        callbackOne();
        return;
      }
      if (document.activeElement === ReactDOM.findDOMNode(tabRefs[2].current)) {
        callbackTwo();
        return;
      }
    }
    e.preventDefault();
  };

  return (
    <Fragment>
      <div className={classes.modalShade} onClick={onCloseClick} />
      <div className={classes.backArrowHolder} onClick={onCloseClick}>
        <div className={classes.backArrow} />
      </div>
      <div className={classes.modalBody}>
        <div className={classes.modalContentHolder}>
          <div className={classes.topIconContainer}>
            <div className={classes.topIconHolder}>
              <Moto className={classes.icon} fontSize="large" />
            </div>
            <div className={classes.mHolder}>
              <div
                className={classes.mBody}
                role="article"
                ref={tabRefs[0]}
                tabIndex={501}
              >
                Warning! Resetting will reset all previous answers for your{' '}
                <b>Entire Path.</b> This action cannot be undone.
              </div>
              <div style={{ minHeight: '30px' }} />
              <div
                role="button"
                ref={tabRefs[1]}
                tabIndex={502}
                aria-label="No, take me back"
              ></div>
              <ModalButton
                onClick={() => {
                  onCloseClick();
                }}
                data={{}}
                type="primary"
                text="No, take me back"
              />

              <div
                className={classes.mBody}
                style={{ marginTop: '5px' }}
                role="button"
                ref={tabRefs[2]}
                tabIndex={503}
                aria-label="Yes, I want to reset my path."
              >
                <a
                  href="#"
                  className={classes.unStylelink}
                  onClick={() => {
                    onResetClick();
                  }}
                >
                  Yes, I want to reset my path.
                </a>
              </div>

              <div className={classes.mBody}></div>
            </div>
          </div>
        </div>
      </div>
      <KeyboardEventHandler
        handleKeys={['tab', 'enter']}
        onKeyEvent={(key, e) =>
          handleModalADAKeyEvents(key, e, tabRefs, onCloseClick, onResetClick)
        }
        handleFocusableElements
      />
    </Fragment>
  );
};

export default withStyles(styles)(DialogueResetWarning);
