import React, { FunctionComponent } from 'react';
import {
  Dashboard,
  EventType,
  GlobalActionType,
  ModalType,
  PathSlidesParams,
} from '../../common/types';
import PathSlide from './path-slide';
import { makeStyles } from '@material-ui/core';
import { useStateValue } from '../../hoc/state';

const useStyles = makeStyles((theme) => ({
  mobilePaths: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  mobilePathsInner: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
  },
  pathsHeader: {
    color: '#347CB1',
    fontSize: '1.1rem',
    fontWeight: 'bold',
    marginTop: '2rem',
    marginBottom: '1rem',
  },
  pathSlideWrapper: {
    marginBottom: '.5rem',
  },
}));

const LandingPaths: FunctionComponent<PathSlidesParams> = ({ dashboards }) => {
  const { globalDispatch } = useStateValue();
  const classes = useStyles();

  const handleClick = (dashboard: Dashboard) => {
    globalDispatch({
      type: GlobalActionType.UPDATE_MODAL_GLOBAL,
      payload: {
        modalNew: {
          type: ModalType.PathDetails,
          open: true,
          data: dashboard,
        },
      },
    });
    globalDispatch({
      payload: {
        event: {
          type: EventType.DASHBOARD_SELECTION,
          data: {
            dashboardId: dashboard.id,
          },
        },
      },
      type: GlobalActionType.TRACK_EVENT,
    });
  };

  return (
    <div className={classes.mobilePaths}>
      <div className={classes.mobilePathsInner}>
        <div className={classes.pathsHeader}>{dashboards.name}</div>
        {dashboards.dashboards.map((dl, idx) => (
          <div className={classes.pathSlideWrapper}>
            <PathSlide
              mobile
              key={idx}
              index={idx}
              dashboard={dl}
              onClick={() => handleClick(dl)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default LandingPaths;
