import React, { useEffect } from 'react';
import { useStateValue } from '../../hoc/state';
import Wheel from '../../containers/wheel/wheel';
import { GlobalAction, GlobalActionType, PathParams } from '../../common/types';
import { useHistory, useParams } from 'react-router';

const WheelContainer = (props) => {
  const { currentDashboardId, globalDispatch } = useStateValue();
  const history = useHistory();
  const param = useParams<PathParams>();

  useEffect(() => {
    const updateDashboardIdAction: GlobalAction = {
      type: GlobalActionType.UPDATE_DASHBOARD_ID,
      payload: { currentDashboardId: +param.pathId },
    };
    globalDispatch(updateDashboardIdAction);
  }, [history.location]);

  return <>{currentDashboardId === +param.pathId && <Wheel {...props} />}</>;
};

export default WheelContainer;
