import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import axiosInst, { axiosWrapper } from '../../common/axios';
import {
  CustomBreakPoints,
  EventType,
  GlobalActionType,
  Result,
  ResultType,
  TaskSearchResult,
} from '../../common/types';
import { useHistory } from 'react-router';
import { Button, makeStyles } from '@material-ui/core';
import Body from '../layout/body';
import BodyGroup from '../layout/body-group';
import TaskCard from '../../components/UI/cards/task-card';
import queryString from 'query-string';
import { useStateValue } from '../../hoc/state';

const useStyles = makeStyles((theme) => ({
  searchWrapper: {
    color: '#333333',
    display: 'flex',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      width: '100%',
      justifyContent: 'center',
    },
  },
  search: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      width: '90%',
      alignItems: 'center',
    },
  },
  title: {
    marginTop: '2rem',
    marginBottom: '2rem',
    fontSize: '1.2rem',
    display: 'flex',
  },
  tagGroup: {
    marginBottom: '2rem',
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      justifyContent: 'center',
      marginBottom: '1rem',
    },
  },
  recordCountText: {
    marginBottom: '2rem',
    fontSize: '1rem',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      marginBottom: '1rem',
    },
  },
  separator: {
    marginBottom: '2rem',
    width: '100%',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      marginBottom: '1rem',
    },
  },
  taskWrapper: {
    marginBottom: '20px',
    display: 'flex',
  },
  buttonWrapper: {
    paddingRight: '.5rem',
    display: 'flex',
  },
  button: {
    border: '1px solid #CBCBCB',
    background: '#fff',
    borderRadius: '20px',
    boxShadow: 'none',
    color: '#717171',
    height: 40,
    padding: '0 20px',
    marginBottom: '.5rem',
    '&:hover': {
      background: '#CBCBCB',
    },
  },
  buttonSelected: {
    border: 'none',
    color: '#fff',
    backgroundColor: '#0095FF',
  },
}));

const TaskSearch: FunctionComponent = () => {
  const classes = useStyles();
  const isInitialMount = useRef(true);
  const [tags, setTags] = useState<string[]>([]);
  const [results, setResults] = useState<Result[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const history = useHistory();
  const { globalDispatch } = useStateValue();

  const processResponse = (response: TaskSearchResult, resetTags: boolean) => {
    isInitialMount.current = false;

    if (resetTags) {
      setTags(response.tags);
    }
    setResults(response.results);
  };

  const resetSearch = () => {
    let url: string;
    const querySearch = queryString.parse(location.search);

    if (querySearch.search || selectedTags.length > 0) {
      let searchString: string[] = [];
      if (querySearch.search) {
        searchString.push(`search=${querySearch.search}`);
        globalDispatch({
          payload: {
            event: {
              type: EventType.STRING_SEARCH,
              data: {
                string: querySearch.search,
              },
            },
          },
          type: GlobalActionType.TRACK_EVENT,
        });
      }
      if (selectedTags.length > 0) {
        searchString.push(`tags=${Array.from(selectedTags).join(',')}`);
        globalDispatch({
          payload: {
            event: {
              type: EventType.TAG_SEARCH,
              data: {
                tags: selectedTags,
              },
            },
          },
          type: GlobalActionType.TRACK_EVENT,
        });
      }
      url = `results/search/posts?${searchString.join('&')}`;
    } else {
      url = 'results/tasks';
    }

    axiosWrapper<TaskSearchResult>(
      axiosInst.get(url),
      ({ data }) => processResponse(data, tags.length === 0),
      (e, errorMessage) => {}
    );
  };

  const clearTags = () => {
    setSelectedTags([]);
    setTags([]);
    setResults([]);
  };

  useEffect(() => {
    if (!isInitialMount.current) {
      clearTags();
    }
  }, [history.location]);

  useEffect(() => {
    resetSearch();
  }, [selectedTags]);

  const handleTagClick = (tag: string, isAdd: boolean) => {
    if (isAdd) {
      setSelectedTags([...selectedTags, tag]);
    } else {
      setSelectedTags(selectedTags.filter((t) => t !== tag));
    }
  };

  if (isInitialMount.current) {
    return null;
  }

  let tagGroup: JSX.Element[] | null = null;
  let taskGroup: JSX.Element[] | null = null;

  if (tags) {
    tagGroup = tags.map((t, i) => {
      let buttonClasses = [classes.button];
      const selected = selectedTags.some((tag) => tag === t);
      if (selected) {
        buttonClasses.push(classes.buttonSelected);
      }
      return (
        <div className={classes.buttonWrapper} key={`${t}${i}`}>
          <Button
            className={buttonClasses.join(' ')}
            onClick={() => handleTagClick(t, !selected)}
            key={i}
          >
            {t}
          </Button>
        </div>
      );
    });
  }
  if (results.length > 0)
    taskGroup = results.map((t, i) => (
      <div className={classes.taskWrapper} key={i}>
        <TaskCard task={t} type={ResultType.TASK} />
      </div>
    ));

  return (
    <Body>
      <BodyGroup>
        <div className={classes.searchWrapper}>
          <div className={classes.search}>
            <div className={classes.title}>Explore Library Topics</div>
            <div className={classes.recordCountText}>
              <span style={{ fontWeight: 'bold' }}>{results.length}</span>
              &nbsp;Results
            </div>
            <div>{taskGroup}</div>
          </div>
        </div>
      </BodyGroup>
    </Body>
  );
};

export default TaskSearch;
