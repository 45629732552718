import React, { FunctionComponent, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import AccessCode from './access-code';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import UserDetails from './user-details';
import { useRegistrationApi } from './registration-api';
import VerificationCode from './registration-verification-code';
import PaymentStripeProvider from './payment-stripe-provider';
import { useStateValue } from '../../hoc/state';
import { CustomBreakPoints } from '../../common/types';
import queryString from 'query-string';

const useStyles = makeStyles((theme) => ({
  step: {
    width: '65%',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      width: '80%',
      minWidth: '80%',
    },
  },
  marginBottom: {
    marginBottom: '.5rem',
  },
}));

const routes = [
  { path: '/access-code', Component: AccessCode },
  { path: '/user-details', Component: UserDetails },
  {
    path: '/payment',
    Component: PaymentStripeProvider,
  },
  { path: '/verification-code', Component: VerificationCode },
];

const Registration: FunctionComponent<RouteComponentProps> = ({
  location,
  match,
}) => {
  const classes = useStyles();
  const { state, nextStep, init } = useRegistrationApi();
  const { isAuthenticated, user } = useStateValue();

  useEffect(() => {
    const parse = queryString.parse(location.search);
    let accessCode = parse.accessCode;
    if (accessCode && Array.isArray(accessCode)) {
      accessCode = accessCode.join('');
    }
    init(accessCode).then(() => {});
  }, []);

  if (isAuthenticated && user && user.emailVerified) {
    return <Redirect to="/" />;
  } else if (state.steps && state.currentStep != null) {
    const currentPath = `${match.url}/${state.steps[state.currentStep]}`;
    if (currentPath !== location.pathname) {
      return <Redirect to={currentPath} />;
    }
  } else {
    return null;
  }

  return (
    <div className={classes.step}>
      <div className={classes.marginBottom}>
        <Switch>
          {routes.map(({ path, Component }) => (
            <Route
              key={0}
              path={`${match.url}${path}`}
              render={(props) => <Component {...props} onContinue={nextStep} />}
            />
          ))}
        </Switch>
      </div>
    </div>
  );
};

export default withRouter(Registration);
