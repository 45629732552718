import React, { createRef, FunctionComponent, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { VerificationCodeParams } from '../../../common/types';

const useStyles = makeStyles((theme) => ({
  codeRoot: {
    textAlign: 'center',
    '& input': {
      fontSize: '2rem',
      width: '2.1rem',
      marginRight: '1rem',
      outline: '0',
      borderWidth: '0 0 2px',
      borderColor: '#0095FF',
      backgroundColor: 'rgba(0,0,0,0)',
      textAlign: 'center',
    },
    '& input:last-child': {
      marginRight: '0',
    },
  },
}));

const buildDefaultForm = (length: number): any[] => {
  const form: any[] = [];
  for (let i = 0; i < length; i++) {
    form[i] = { value: '', ref: null };
  }
  return form;
};

const buildValueFromForm = (form: any[], length): string | null => {
  const value: string = form
    .filter((f) => f.value)
    .map((f) => f.value)
    .reduce((prev, cur) => prev + cur, '');

  return value.length === length ? value : null;
};

const VerificationCode: FunctionComponent<VerificationCodeParams> = ({
  onChange,
  length = 4,
}) => {
  const classes = useStyles();
  const [form, setForm] = useState<any[]>(buildDefaultForm(length));
  const inputRef = useRef<any>(form.map(() => createRef()));

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>, index) => {
    const newForm = form.slice(0);
    newForm[index] = { ...form[index], value: e.target.value };
    setForm(newForm);
    if (index < Object.keys(form).length - 1 && e.target.value) {
      inputRef.current[++index].current.focus();
    } else if (!e.target.value && index > 0) {
      inputRef.current[--index].current.focus();
    }
    onChange(buildValueFromForm(newForm, length));
  };

  return (
    <div className={classes.codeRoot}>
      {form.map((key, i) => (
        <input
          key={i}
          autoFocus={i === 0}
          maxLength={1}
          ref={inputRef.current[i]}
          onChange={(event) => handleInput(event, i)}
        />
      ))}
    </div>
  );
};

export default VerificationCode;
