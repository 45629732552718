import React, { Fragment, useState } from 'react';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core';
import Moto from '@material-ui/icons/Motorcycle';
import ModalButton from './modal-button';

const styles = (theme: Theme) =>
  createStyles({
    modalShade: {
      position: 'fixed',
      top: '0px',
      left: '0px',
      width: '100%',
      height: '100%',
      backgroundColor: '#2E2E2E',
      opacity: 0.4,
      zIndex: 50,
    },
    modalBody: {
      margin: '0 auto',
      position: 'absolute',
      top: '-300px',
      left: '50%',
      marginLeft: '-176px',
      width: '352px',
      height: '',
      backgroundColor: '#63BEFF',
      zIndex: 51,
      borderRadius: '5px',
      '-webkit-box-shadow': '0px 3px 6px 0px rgba(0,0,0,0.16)',
      '-moz-box-shadow': '0px 3px 6px 0px rgba(0,0,0,0.16)',
      'box-shadow': '0px 3px 6px 0px rgba(0,0,0,0.16)',
      color: 'white',
      animation: '$change .3s linear forwards',
    },
    '@keyframes change': {
      '100%': {
        transform: 'translateY(390px)',
      },
    },
    modalContentHolder: {
      top: '65px',
      left: '0px',
      width: '100%',
      height: 'calc(100% - 65px)',
      //overflow: 'hidden',
      //overflowY: 'scroll',
    },

    modalContent: {
      width: '100%',
      height: '100%',
    },
    modalClose: {
      position: 'absolute',
      zIndex: 52,
      right: '57px',
      top: '50px',
      width: '25px',
      height: '25px',
      backgroundColor: '#2E2E2E',
      color: '#fff',
      fontWeight: 'bold',
      fontSize: '20px',
      textAlign: 'center',
      borderRadius: '2px',
      cursor: 'pointer',
      '-webkit-transition': '-webkit-transform .4s ease-in-out',
      transition: 'transform .4s ease-in-out',
      '&:hover': {
        opacity: 0.6,
      },
    },
    visible: {
      visibility: 'visible',
      opacity: 1,
      transition: 'opacity .1s linear',
    },
    hidden: {
      visibility: 'hidden',
      opacity: 0,
      transition: 'visibility 0s .5s, opacity .5s linear',
    },
    blur: {
      '-webkit-filter': 'blur(10px)',
      '-moz-filter': 'blur(10px)',
      '-o-filter': 'blur(10px)',
      '-ms-filter': 'blur(10px)',
      filter:
        "url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='svgMask'><feGaussianBlur stdDeviation='10' /></filter></svg>#svgMask\")",
    },
    topIconContainer: {
      textAlign: 'center',
      marginTop: '-30px',
    },
    topIconHolder: {
      backgroundColor: '#fff',
      border: '9px solid #63BEFF',
      borderRadius: '50%',
      height: '78px',
      width: '78px',
      margin: '0 auto',
    },
    icon: {
      color: '#63BEFF',
      marginTop: '6px',
      fontSize: '40px',
    },
    iconBottom: {
      color: '#fff',
      marginTop: '2.2px',
      fontSize: '20px',
    },
    mHolder: {
      padding: '20px',
    },
    mTitle: {
      fontSize: '20px',
      marginTop: '-10px',
      fontWeight: 600,
    },
    mBody: {
      paddingTop: '10px',
      fontSize: '13px',
    },
    tableIndicator: {
      margin: '0 auto',
      paddingTop: '30px',
      paddingBottom: '15px',
    },
    unStylelink: {
      textDecoration: 'none',
      cursor: 'pointer',
      color: 'white',
      fontWeight: 600,
    },
  });

interface Props extends WithStyles<typeof styles> {
  onClick: any;
  data: any;
  onCloseClick: any;
}

const DialogueNoResultsWarning: React.FunctionComponent<Props> = (props) => {
  const { classes, onClick, data, onCloseClick } = props;
  const [isVideoVisible, setVideoVisible] = useState(false);
  const titleText = data.name;
  // console.log(data);

  return (
    <Fragment>
      <div className={classes.modalShade} onClick={onCloseClick} />
      <div className={classes.modalBody}>
        <div className={classes.modalContentHolder}>
          <div className={classes.topIconContainer}>
            <div className={classes.topIconHolder}>
              <Moto className={classes.icon} fontSize="large" />
            </div>
            <div className={classes.mHolder}>
              <div className={classes.mTitle}>{titleText}</div>
              <div className={classes.mBody}>
                Congratulations! Based on your answers, you're ready to move on
                to the next section.
              </div>
              <div style={{ minHeight: '30px' }} />
              <ModalButton
                onClick={onClick}
                data={data}
                type="primary"
                text="Continue"
              />
              <div className={classes.mBody} style={{ marginTop: '10px' }}>
                <a
                  href="#"
                  className={classes.unStylelink}
                  onClick={() => {
                    onCloseClick();
                  }}
                >
                  Start Over
                </a>
              </div>
              <div className={classes.mBody} style={{ marginTop: '5px' }}>
                &nbsp;
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default withStyles(styles)(DialogueNoResultsWarning);
