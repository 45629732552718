import { useStateValue } from '../hoc/state';
import axiosInst, { axiosWrapper } from '../common/axios';
import {
  DashboardContext,
  EventType,
  GlobalAction,
  GlobalActionType,
  ResultProgressAction,
} from '../common/types';
import { useEffect, useState } from 'react';

const useDashboardApi = () => {
  const {
    globalDispatch,
    dashboardContext,
    currentDashboardId,
    user,
    globalPercentageValues,
  } = useStateValue();
  const [resultProgressAction, setResultProgressAction] =
    useState<ResultProgressAction | null>(null);

  const checkupCompleted = (checkupCode: string) => {
    globalDispatch({
      payload: {
        event: {
          type: EventType.CHECKUP_COMPLETE,
          data: {
            checkupCode: checkupCode,
          },
        },
      },
      type: GlobalActionType.TRACK_EVENT,
    });
  };

  const dashboardSelected = (
    dashboardId: string | number,
    isFirst: boolean
  ) => {
    globalDispatch({
      payload: {
        event: {
          type: EventType.DASHBOARD_SELECTION,
          data: {
            dashboardId: dashboardId,
          },
        },
      },
      type: GlobalActionType.TRACK_EVENT,
    });
  };

  const saveResultProgress = (
    checkupCode: string,
    id: number | null,
    resultCode: string | null,
    complete: boolean
  ) => {
    const resultProgress: ResultProgressAction = {
      checkupCode,
      resultCode,
      complete,
    };
    setResultProgressAction(resultProgress);
    const action: GlobalAction = {
      type: GlobalActionType.SAVE_RESULT_PROGRESS,
      payload: { resultProgress },
    };
    globalDispatch(action);
    if (complete) {
      globalDispatch({
        payload: {
          event: {
            type: EventType.RESULT_COMPLETE,
            data: {
              resultId: id,
              resultCode: resultCode,
              checkupCode: checkupCode,
            },
          },
        },
        type: GlobalActionType.TRACK_EVENT,
      });
    }
  };

  const fetchDashboardContext = (
    reset = false,
    dashboardId = currentDashboardId
  ) => {
    axiosWrapper<DashboardContext>(
      axiosInst.get(
        reset ? `dashboard/${dashboardId}/reset` : `dashboard/${dashboardId}`
      ),
      (resp) => {
        globalDispatch({
          type: GlobalActionType.FETCH_DASHBOARD_CONTEXT,
          payload: { dashboardContext: resp.data },
        });
      },
      (e, errorMessage) => {
        if (e.data && e.data.statusCode === 500) {
          globalDispatch({
            type: GlobalActionType.FETCH_DASHBOARD_ERROR,
            payload: {},
          });
        }
      },
      null,
      globalDispatch
    );
  };

  const updateCurrentDashboard = (dashboardId: number) => {
    const updateDashboardIdAction: GlobalAction = {
      type: GlobalActionType.UPDATE_DASHBOARD_ID,
      payload: { currentDashboardId: dashboardId },
    };
    globalDispatch(updateDashboardIdAction);
    // const userEmail = user ? user.email : '';
    // localStorage.setItem(
    //   'currentDashboardId',
    //   userEmail + '*$*678!' + String(dashboardId),
    // );
    fetchDashboardContext(false, dashboardId);
  };

  const fetchDashboardsAll = () => {
    axiosWrapper<DashboardContext>(
      axiosInst.get(`dashboard/all`),
      (resp) => {
        globalDispatch({
          type: GlobalActionType.FETCHED_ALL_DASHBOARDS,
          payload: { dashboardsList: resp.data },
        });
      },
      (e, errorMessage) => {
        if (e.data && e.data.statusCode === 500) {
          globalDispatch({
            type: GlobalActionType.FETCHED_DASHBOARDS_ERROR,
            payload: {},
          });
        }
      },
      null,
      globalDispatch
    );
  };

  const processSaveError = () => {
    setResultProgressAction(null);
    if (resultProgressAction) {
      globalDispatch({
        type: GlobalActionType.SHOW_ERROR_MODAL,
        payload: {},
      });
      const action: GlobalAction = {
        type: GlobalActionType.SAVE_RESULT_PROGRESS,
        payload: {
          resultProgress: {
            ...resultProgressAction,
            complete: !resultProgressAction.complete,
          },
        },
      };
      globalDispatch(action);
    }
  };

  const dashboardCompleted = () => {
    globalDispatch({
      payload: {
        event: {
          type: EventType.DASHBOARD_COMPLETED,
          data: {
            dashboardId: currentDashboardId,
          },
        },
      },
      type: GlobalActionType.TRACK_EVENT,
    });
    axiosWrapper<any>(
      axiosInst.get<any>(`dashboard/${currentDashboardId}/complete`),
      () => {},
      () => {}
    );
  };

  const resetDashboard = () => {
    axiosWrapper<any>(
      axiosInst.get<any>(`dashboard/${currentDashboardId}/reset`),
      (resp) => {
        window.location.reload();
      },
      (e, errorMessage) => window.location.reload()
    );
  };

  useEffect(() => {
    if (dashboardContext && resultProgressAction) {
      axiosWrapper<any>(
        axiosInst.post<any>(
          'dashboard',
          dashboardContext.userDashboardProgress.progress
        ),
        () => setResultProgressAction(null),
        () => processSaveError()
      );
      if (dashboardContext) {
        const checkupComplete =
          dashboardContext.userDashboardProgress.progress.checkups[
            resultProgressAction.checkupCode
          ].resultList.every(
            (resultCode) =>
              dashboardContext.userDashboardProgress.progress.checkups[
                resultProgressAction.checkupCode
              ].results[resultCode].completed
          );
        let dashboardComplete = false;
        for (const checkup in dashboardContext.userDashboardProgress.progress
          .checkups) {
          dashboardComplete =
            dashboardContext.userDashboardProgress.progress.checkups[
              checkup
            ].resultList.every(
              (resultCode) =>
                dashboardContext.userDashboardProgress.progress.checkups[
                  checkup
                ].results[resultCode].completed
            );
        }
        if (checkupComplete) {
          checkupCompleted(resultProgressAction.checkupCode);
        }
        if (dashboardComplete) {
          dashboardCompleted();
        }
      }
    }
  }, [dashboardContext]);

  return {
    checkupCompleted,
    saveResultProgress,
    updateCurrentDashboard,
    fetchDashboardContext,
    dashboardCompleted,
    fetchDashboardsAll,
    dashboardSelected,
  };
};

export default useDashboardApi;
