import { GlobalActionType } from '../../../common/types';

export const closeModalState = {
  type: GlobalActionType.UPDATE_MODAL_GLOBAL,
  payload: {
    modalNew: {
      open: false,
    },
  },
};
