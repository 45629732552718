import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core';
import { useStateValue } from '../../../hoc/state';
import { GlobalActionType, ModalT } from '../../../common/types';

const useStyles = makeStyles((theme) => ({
  modalShade: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    backgroundColor: '#2E2E2E',
    opacity: 0.5,
    zIndex: 50,
  },
  modalBody: {
    position: 'absolute',
    top: '72px',
    left: '0px',
    right: '0px',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '1200px',
    height: '85%',
    backgroundColor: '#F9F9F9',
    zIndex: 51,
    borderRadius: '5px',
    '-webkit-box-shadow': '0px 3px 6px 0px rgba(0,0,0,0.16)',
    '-moz-box-shadow': '0px 3px 6px 0px rgba(0,0,0,0.16)',
    'box-shadow': '0px 3px 6px 0px rgba(0,0,0,0.16)',
  },
  modalContentHolder: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    overflowY: 'scroll',
  },

  modalContent: {
    padding: '33px 65px',
  },
  modalClose: {
    position: 'absolute',
    zIndex: 52,
    right: '57px',
    top: '50px',
    width: '25px',
    height: '25px',
    backgroundColor: '#2E2E2E',
    color: '#fff',
    fontWeight: 'bold',
    fontSize: '20px',
    textAlign: 'center',
    borderRadius: '2px',
    cursor: 'pointer',
    '-webkit-transition': '-webkit-transform .4s ease-in-out',
    transition: 'transform .4s ease-in-out',
    '&:hover': {
      opacity: 0.6,
    },
  },
  visible: {
    visibility: 'visible',
    opacity: 1,
    transition: 'opacity .1s linear',
  },

  hidden: {
    visibility: 'hidden',
    opacity: 0,
    transition: 'visibility 0s .5s, opacity .5s linear',
  },
}));

const Modal: React.FunctionComponent = () => {
  const { globalDispatch, modal } = useStateValue();

  const allowClose = modal ? modal.allowClose : true;

  const classes = useStyles();
  const isVisibile = modal && modal.on ? classes.visible : classes.hidden;

  const closeModalObj: ModalT = {
    on: false,
    content: null,
    allowClose: true,
  };
  const closeModal = () => {
    globalDispatch({
      type: GlobalActionType.UPDATE_MODAL,
      payload: { modal: closeModalObj },
    });
  };

  return (
    <Fragment>
      <div
        className={classes.modalShade + ' ' + isVisibile}
        onClick={() => {
          if (allowClose) closeModal();
        }}
      />
      <div className={classes.modalBody + ' ' + isVisibile}>
        {allowClose ? (
          <div
            className={classes.modalClose}
            onClick={() => {
              closeModal();
            }}
          >
            X
          </div>
        ) : null}
        <div className={classes.modalContentHolder}>
          <div className={classes.modalContent}>
            <div style={{ color: 'black' }}>
              {modal && modal.content ? <modal.content /> : null}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Modal;
