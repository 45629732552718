import React, {
  FunctionComponent,
  SyntheticEvent,
  useEffect,
  useState,
} from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { InputProperties } from '../../../common/types';

interface StyleProperties {
  width?: string;
}

const useStyles = makeStyles<Theme, StyleProperties>((theme) => ({
  input: {
    border: '1px solid #cacaca',
    borderRadius: '4px',
    backgroundColor: 'rgba(0,0,0,0)',
    fontSize: '1rem',
    padding: '.5rem',
    fontFamily: 'inherit',
    color: '#424770',
    '&:focus': {
      outline: 'none',
    },
    width: (props) => props.width,
  },
  inputError: {
    border: '1px solid #f44336',
  },
  label: {
    marginBottom: '.3rem',
    fontSize: '.8rem',
  },
  error: {
    color: '#f44336',
    marginTop: '.2rem',
    fontSize: '.8rem',
  },
}));

const InputTextForm: FunctionComponent<InputProperties> = ({
  width = '100%',
  formField,
  name,
  onChange,
  onBlur,
  htmlAttributes = {},
}) => {
  const classes = useStyles({ width });
  const [errorInternal, setErrorInternal] = useState(formField.error);

  useEffect(() => {
    setErrorInternal(formField.error);
  }, [formField.error]);

  const labelDiv = formField.label ? (
    <div className={classes.label}>{formField.label}</div>
  ) : null;

  const errorDiv = errorInternal ? (
    <div className={classes.error}>{errorInternal}</div>
  ) : null;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(name, e.target.value, formField);
    }
  };

  const validateOnBlur = (e: SyntheticEvent) => {
    if (onBlur) {
      onBlur(name, formField);
    }
  };

  const inputClasses = [classes.input];
  if (errorInternal) {
    inputClasses.push(classes.inputError);
  }

  return (
    <div>
      {labelDiv}
      <div>
        <input
          type="text"
          {...htmlAttributes}
          className={inputClasses.join(' ')}
          onChange={handleChange}
          onBlur={validateOnBlur}
          value={formField.value}
        />
      </div>
      {errorDiv}
    </div>
  );
};

export default InputTextForm;
