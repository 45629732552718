import { makeStyles } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { BodyGroupProps, CustomBreakPoints } from '../../common/types';

const useStyles = makeStyles((theme) => ({
  row: {
    width: '100%',
  },
  rowInner: {
    width: '1200px',
    margin: '0 auto',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      width: '100%',
    },
  },
}));

const BodyGroup: FunctionComponent<BodyGroupProps> = ({
  background,
  className,
  scrollRef,
  children,
}) => {
  const classes = useStyles();

  const backgroundColor: string = background || '#fff';

  let rootClasses = classes.row;
  if (className) {
    rootClasses = rootClasses + ' ' + className;
  }

  return (
    <div className={rootClasses} style={{ backgroundColor }} ref={scrollRef}>
      <div className={classes.rowInner}>{children}</div>
    </div>
  );
};

export default BodyGroup;
