import React, { FunctionComponent, useState } from 'react';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from 'react-stripe-elements';
import { makeStyles } from '@material-ui/core';
import { CreditCardFormParams } from '../../common/types';

const useStyles = makeStyles((theme) => ({
  expCvcGroup: {
    display: 'flex',
  },

  inputWrapper: {
    padding: '.5rem',
    border: '1px solid #cacaca',
    borderRadius: '4px',
    width: '100%',
  },

  label: {
    marginBottom: '.3rem',
    fontSize: '.8rem',
  },

  error: {
    color: '#f44336',
    marginTop: '.2rem',
    fontSize: '.8rem',
  },
}));

const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#f44336',
      },
    },
  };
};

const CreditCardForm: FunctionComponent<CreditCardFormParams> = ({
  onFormChange,
}) => {
  const classes = useStyles();
  const [cardError, setCardError] = useState();
  const [expError, setExpError] = useState();
  const [cvcError, setCvcError] = useState();
  const [allComplete, setAllComplete] = useState([false, false, false]);

  const handleError = (index, complete, error, changeFunction) => {
    const newComplete = allComplete.slice(0);
    newComplete[index] = complete;
    setAllComplete(newComplete);

    if (error) {
      changeFunction(error.message);
    } else {
      changeFunction(null);
    }

    onFormChange(newComplete.every((v) => v));
  };

  const handleCardChange = ({ error, complete }) => {
    handleError(0, complete, error, setCardError);
  };

  const handleExpChange = ({ error, complete }) => {
    handleError(1, complete, error, setExpError);
  };

  const handleCvcChange = ({ error, complete }) => {
    handleError(2, complete, error, setCvcError);
  };

  return (
    <>
      <div>
        <div className={classes.label}>Card Number</div>
        <div className={classes.inputWrapper}>
          <CardNumberElement {...createOptions()} onChange={handleCardChange} />
        </div>
        {cardError ? <div className={classes.error}>{cardError}</div> : null}
      </div>
      <div className={classes.expCvcGroup}>
        <div style={{ width: '50%', marginRight: '.7rem' }}>
          <div className={classes.label}>Expiration</div>
          <div className={`${classes.inputWrapper}`}>
            <CardExpiryElement
              {...createOptions()}
              onChange={handleExpChange}
            />
          </div>
          {expError ? <div className={classes.error}>{expError}</div> : null}
        </div>
        <div style={{ width: '50%' }}>
          <div className={classes.label}>CVC</div>
          <div className={classes.inputWrapper}>
            <CardCvcElement {...createOptions()} onChange={handleCvcChange} />
          </div>
          {cvcError ? <div className={classes.error}>{cvcError}</div> : null}
        </div>
      </div>
    </>
  );
};

export default CreditCardForm;
