import React, {
  createContext,
  FunctionComponent,
  useContext,
  useReducer,
} from 'react';
import { RegistrationContext } from '../../common/types';

import registrationReducer from './registration-reducer';
import Registration from './registration';

export const Context = createContext<RegistrationContext>({
  state: {},
  dispatch: () => {},
});

const RegistrationProvider: FunctionComponent = () => {
  const [state, dispatch] = useReducer(registrationReducer, {
    state: {},
    dispatch: () => {},
  });

  return (
    <Context.Provider value={{ ...state, dispatch }}>
      <Registration />
    </Context.Provider>
  );
};

export const useRegistrationValue = () => useContext(Context);

export default RegistrationProvider;
