import React, {
  FormEvent,
  FunctionComponent,
  SyntheticEvent,
  useState,
} from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Button, Link, makeStyles } from '@material-ui/core';
import { RegistrationChild } from '../../common/types';
import {
  useRegistrationApi,
  useResetPasswordPinGenApi,
  useVerificationCodeApi,
} from './registration-api';
import VerificationCode from '../../components/UI/input/verification-code';

const useStyles = makeStyles((theme) => ({
  formRoot: {
    '& > div': {
      marginBottom: '1rem',
    },
  },

  codeText: {
    fontWeight: 'bold',
    fontSize: '2rem',
    color: '#545B64',
  },

  resendCode: {
    fontSize: '.875rem',
  },

  resendCodeText: {
    display: 'inline-block',
    marginRight: '.15rem',
  },

  descriptionText: {
    fontSize: '.9rem',
    color: '#717171',
  },

  error: {
    color: '#f44336',
    fontSize: '.875rem',
  },

  codeSentSuccess: {
    color: '#4caf50',
    fontSize: '.875rem',
  },
}));

const RegistrationVerificationCode: FunctionComponent<
  RegistrationChild & RouteComponentProps
> = () => {
  const classes = useStyles();
  const [code, setCode] = useState<string | null>(null);
  const {
    verifyCode,
    error,
    fetching: verifyFetching,
  } = useVerificationCodeApi();
  const { getEmail } = useRegistrationApi();
  const {
    success,
    emailCode,
    fetching,
    error: codeError,
  } = useResetPasswordPinGenApi();

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (code) {
      await verifyCode(code);
    }
  };

  const handleNewKey = async (event: SyntheticEvent) => {
    event.preventDefault();
    await emailCode(getEmail);
  };

  const errorDiv = error ? <div className={classes.error}>{error}</div> : null;
  const codeErrorDiv = codeError ? (
    <div className={classes.error}>{error}</div>
  ) : null;
  const codeSuccessDiv = success ? (
    <div className={classes.codeSentSuccess}>
      Check your Email. New Code Sent!
    </div>
  ) : null;
  const sendingCode = fetching ? (
    'Sending New Code'
  ) : (
    <Link href="#" onClick={handleNewKey} variant="body2">
      Click Here
    </Link>
  );

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.formRoot}>
        <div className={classes.codeText}>Email Verification</div>
        <div className={classes.descriptionText}>
          {`We sent a code to ${getEmail}.  It is valid for 20 minutes.  Please enter it below to verify your email address.`}
        </div>
        <VerificationCode onChange={setCode} />
        {errorDiv}
        <div>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={verifyFetching}
          >
            VERIFY
          </Button>
        </div>
        {codeErrorDiv}
        {codeSuccessDiv}
        <div className={classes.resendCode}>
          <div className={classes.resendCodeText}>Need a new code?</div>
          <div style={{ display: 'inline-block' }}>{sendingCode}</div>
        </div>
      </div>
    </form>
  );
};

export default withRouter(RegistrationVerificationCode);
