import { ModalNewContent, Result } from '../../../common/types';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router';
import axiosInst, { axiosWrapper } from '../../../common/axios';
import ResultPanel from '../../../containers/module-viewer/result-panel/result-panel';

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: '5px',
    margin: '0 auto',
    overflow: 'auto',
    width: '1200px',
    height: '85vh',
  },
}));

const TaskModal: FunctionComponent<ModalNewContent> = ({
  backgroundClicked,
  content,
}) => {
  const history = useHistory();
  const [result, setResult] = useState<Result | null>(null);

  const closeDialog = () => {
    history.goBack();
  };

  useEffect(() => {
    if (backgroundClicked) {
      closeDialog();
    }
  }, [backgroundClicked]);

  useEffect(() => {
    axiosWrapper<Result>(
      axiosInst.get(`/results/code/${content.data}`),
      ({ data }) => setResult(data),
      (e, errorMessage) => {}
    );
  }, [content]);

  const classes = useStyles();

  return (
    <div className={classes.content}>
      <div>{result ? <ResultPanel result={result} /> : null}</div>
    </div>
  );
};

export default TaskModal;
