import React, { FormEvent, FunctionComponent, useState } from 'react';
import {
  isValid,
  REQUIRED,
  validateForm,
  validateFormField,
} from '../../../components/UI/forms/form-utils';
import { CustomBreakPoints, Form, FormField } from '../../../common/types';
import FormFields from '../../../components/UI/forms/form-fields';
import { Button, Link, makeStyles } from '@material-ui/core';
import {
  Link as RouterLink,
  Redirect,
  RouteComponentProps,
} from 'react-router-dom';
import { useLoginApi } from '../../../apis/user-apis';
import { useStateValue } from '../../../hoc/state';

const defaultForm = {
  email: {
    value: '',
    label: 'Email',
    validationTypes: [REQUIRED],
    htmlAttributes: {
      maxLength: 255,
    },
  },
  password: {
    value: '',
    label: 'Password',
    htmlAttributes: { type: 'password', maxLength: 30 },
    validationTypes: [REQUIRED],
  },
};

const useStyles = makeStyles((theme) => ({
  formRoot: {
    width: '65%',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      width: '80%',
      minWidth: '80%',
    },
  },

  form: {
    '& > div': {
      marginBottom: '1rem',
    },
  },
  codeText: {
    fontWeight: 'bold',
    fontSize: '2rem',
    color: '#545B64',
  },

  descriptionText: {
    fontSize: '.9rem',
    color: '#717171',
    lineHeight: '1.6rem',
  },

  error: {
    color: '#f44336',
    marginTop: '.2rem',
    fontSize: '.8rem',
  },
  termsText: {
    color: '#656565',
    fontSize: '.75rem',
    marginTop: '1rem',
  },
}));

const Login: FunctionComponent<RouteComponentProps> = (props) => {
  const [form, setForm] = useState<Form>(defaultForm);
  const classes = useStyles();
  const { doLogin, error, state } = useLoginApi();
  const { isAuthenticated, user, dashboardsList, org } = useStateValue();

  const handleChange = (
    name: string,
    value: string,
    formField: FormField<any>
  ) => {
    const changedForm = {
      ...form,
    };
    changedForm[name] = {
      ...changedForm[name],
      value,
    };
    setForm(changedForm);
  };

  const validateOnBlur = (name: string, formField: FormField<any>) => {
    const changedForm = {
      ...form,
    };
    changedForm[name] = validateFormField(formField, form);
    setForm(changedForm);
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    let validatedForm = validateForm(form);

    if (isValid(validatedForm)) {
      doLogin(form.email.value, form.password.value);
    }
    setForm(validatedForm);
  };

  if (isAuthenticated && dashboardsList.length > 0) {
    let redirectLocation = `/path/${dashboardsList[0].dashboards[0].id}`;
    if (props.location.state && props.location.state.from) {
      redirectLocation = props.location.state.from;
    } else if (user && user.organization && user.organization.showLandingPage) {
      redirectLocation = '/dashboard';
    }
    return <Redirect to={redirectLocation} />;
  }

  const signup =
    org && org.domain === 'kclibrary.thewayfarer.io' ? (
      <div>
        {' '}
        <Link href="https://signup.thewayfarer.io/kclibrary" variant="body2">
          {' '}
          {"Don't have an account? Sign Up"}{' '}
        </Link>{' '}
      </div>
    ) : null;
  const errorDiv = error ? <div className={classes.error}>{error}</div> : null;

  return (
    <div className={classes.formRoot}>
      <form className={classes.form} onSubmit={handleSubmit}>
        <div className={classes.codeText}>Sign in</div>
        <div className={classes.descriptionText}>
          {'Log in to continue your journey.'}
        </div>
        <FormFields
          form={form}
          onChange={handleChange}
          onBlur={validateOnBlur}
        />
        {errorDiv}
        <div>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={state.isLoading}
          >
            Sign In
          </Button>
        </div>
      </form>
      <div style={{ textAlign: 'left' }}>
        <div>
          <Link component={RouterLink} to="/reset-password" variant="body2">
            Forgot password?
          </Link>
        </div>
        {/*<div>
          <Link component={RouterLink} to="/register" variant="body2">
            {"Don't have an account? Sign Up"}
          </Link>
        </div>*/}
        {signup}
      </div>
      <div className={classes.termsText}>
        {'By signing in, you agree to Wayfarer.io’s '}
        <Link
          href="https://www.thewayfarer.io/terms-of-use"
          variant="body2"
          target="_blank"
          style={{ fontSize: '.75rem' }}
        >
          {'Terms of Use'}
        </Link>
        {' and '}
        <Link
          href="https://www.thewayfarer.io/privacy-policy"
          variant="body2"
          target="_blank"
          style={{ fontSize: '.75rem' }}
        >
          {'Privacy Policy'}
        </Link>
      </div>
    </div>
  );
};

export default Login;
