import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core';
import {
  CustomBreakPoints,
  DownloadLinkResult,
  EventType,
  GlobalActionType,
  Result,
  ResultResourcesProps,
  TitleDescriptionImage,
} from '../../../common/types';
import ResultResourceItem from './result-resoure-item';
import axiosInst, { axiosWrapper } from '../../../common/axios';
import { useStateValue } from '../../../hoc/state';

const useStyles = makeStyles((theme) => ({
  itemWrapper: {
    marginBottom: '1.5rem',
    display: 'flex',
  },
  resultCard: {
    display: 'inline-flex',
    flexDirection: 'column',
    [theme.breakpoints.up(CustomBreakPoints.mobile)]: {
      padding: '24px 35px 0 35px',
      border: '1px solid #CBCBCB',
      borderRadius: '5px',
      backgroundColor: '#fbfbfb',
    },
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      display: 'flex',
    },
  },
  header: {
    color: '#545b64',
    fontSize: '1.1rem',
    fontWeight: 'bold',
    marginBottom: '1rem',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      marginTop: '1rem',
      display: 'flex',
    },
  },
}));

const hasDownloads = (result: Result) => {
  return result.resultDownloads && result.resultDownloads.length > 0;
};

const hasResources = (result: Result) => {
  return result.resultLinks && result.resultLinks.length > 0;
};

const ResultResources: FunctionComponent<ResultResourcesProps> = ({
  result,
}) => {
  const classes = useStyles();
  const { globalDispatch } = useStateValue();

  const tile: TitleDescriptionImage = {
    title: 'Linkedin',
    description:
      'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium.',
    image: 'https://legal-gps-images.s3.us-east-2.amazonaws.com/Linkedin.png',
  };

  const tile2: TitleDescriptionImage = {
    title: 'More Information',
    description: 'You can learn more about this topic by clicking here.',
    image:
      'https://legal-gps-images.s3.us-east-2.amazonaws.com/legalgpspath/lgps_resource.png',
  };

  const handleClick = (id: number) => async (e) => {
    globalDispatch({
      payload: {
        event: {
          type: EventType.RESOURCE_EXTERNAL_LINK,
          data: {
            id: id,
          },
        },
      },
      type: GlobalActionType.TRACK_EVENT,
    });
  };
  const handleDownloadClick = (id: number) => async (e) => {
    e.preventDefault();
    globalDispatch({
      payload: {
        event: {
          type: EventType.RESOURCE_DOWNLOAD,
          data: {
            id: id,
          },
        },
      },
      type: GlobalActionType.TRACK_EVENT,
    });
    axiosWrapper<DownloadLinkResult>(
      axiosInst.get(`download/url/${id}`),
      ({ data }) => {
        const downloadFrame = document.createElement('iframe');
        downloadFrame.setAttribute('src', data.url);
        downloadFrame.setAttribute('class', 'hiddenText');
        document.body.appendChild(downloadFrame);
      },
      (e, errorMessage) => {}
    );
  };

  const resources: JSX.Element[] = [];

  if (result.resultLinks.length > 0) {
    result.resultLinks.forEach((rl) => {
      if (rl.link) {
        resources.push(
          <ResultResourceItem
            titleDescriptionImage={rl.link}
            url={rl.link.url}
            onclick={handleClick(rl.link.id)}
          />
        );
      }
    });
  }

  if (result.resultDownloads.length > 0) {
    result.resultDownloads.forEach((rl) => {
      if (rl.download) {
        resources.push(
          <ResultResourceItem
            titleDescriptionImage={rl.download}
            onclick={handleDownloadClick(rl.download.id)}
          />
        );
      }
    });
  }

  if (resources.length === 0) {
    resources.push(
      <ResultResourceItem
        titleDescriptionImage={tile}
        url={'http://www.linkedin.com'}
      />
    );
    resources.push(
      <ResultResourceItem
        titleDescriptionImage={tile2}
        url={'https://www.legalgps.com/T20KCD_a#task'}
      />
    );
  }

  return (
    <div className={classes.resultCard}>
      <div className={classes.header}>Resources</div>
      {resources.map((e, idx) => (
        <div className={classes.itemWrapper} key={idx}>
          {e}
        </div>
      ))}
    </div>
  );
};

export default ResultResources;
