import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useStateValue } from '../../hoc/state';
import 'pure-react-carousel/dist/react-carousel.es.css';
import LandingHeader from './landing-header';
import LandingPaths from './landing-paths';
import { TaskSearchResult } from '../../common/types';
import BodyGroup from '../layout/body-group';
import Body from '../layout/body';
import LandingPathsMobile from './landing-paths-mobile';

const Landing: React.FunctionComponent<RouteComponentProps> = (props) => {
  const { dashboardsList, user, screenDimensions } = useStateValue();
  const [tasks, setTasks] = useState<TaskSearchResult | null>(null);

  const organization = user ? user.organization : null;

  /*useEffect(() => {
    axiosWrapper<TaskSearchResult>(
      axiosInst.get(`results/tasks`),
      ({ data }) => setTasks(data),
      (e, errorMessage) => {}
    );
  }, []);*/

  return (
    <Body>
      <BodyGroup background="#2A5371" key="header">
        <div>
          {organization ? <LandingHeader organization={organization} /> : null}
        </div>
      </BodyGroup>
      <BodyGroup key="paths">
        <div style={{ marginTop: '30px' }}>
          {dashboardsList.map((dl) =>
            screenDimensions?.isMobile ? (
              <LandingPathsMobile dashboards={dl} />
            ) : (
              <LandingPaths dashboards={dl} />
            )
          )}
        </div>
      </BodyGroup>
    </Body>
  );
};

export default Landing;
