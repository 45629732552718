import axiosInst from '../common/axios';
import { RegistrationAccessCode } from '../common/types';

export const fetchProductPlanByAccessCodeById = async (
  accessCode: string
): Promise<RegistrationAccessCode> => {
  const response = await axiosInst.get<RegistrationAccessCode>(
    `register/access-code/${accessCode}`
  );
  return response.data;
};
