import { makeStyles } from '@material-ui/core';
import React, { FunctionComponent, useEffect, useState } from 'react';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import Home from '@material-ui/icons/Home';
import { relative } from 'path';
import ReactDOM from 'react-dom';
import useDashboardApi from '../../../apis/dashboard-apis';

const useStyles = makeStyles((theme) => ({
  numberHolder: {
    display: 'block',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 4,
  },

  trophy: {
    background: 'url("/images/trophy100x4.png")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  },
  canvasHolder: {
    marginTop: '0px',
    textAlign: 'center',
    position: 'relative',
    zIndex: 1,
    opacity: 0,
    animation: '$opacityZ 1s 1s linear forwards',
  },
  '@keyframes opacityZ': {
    '100%': {
      opacity: 1,
    },
  },
  canvas: {
    [theme.breakpoints.down('sm')]: {
      minWidth: '150px',
      width: '150px',
      height: '150px',
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '150px',
      width: '150px',
      height: '150px',
    },
    [theme.breakpoints.up(1360)]: {
      minWidth: '150px',
      width: '150px',
      height: '150px',
    },
  },
  number: {
    fontFamily: 'Montserrat',
    display: 'inline-block',
    width: '100%',
    textAlign: 'center',
    verticalAlign: 'middle',
    margin: '0 auto',
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
    color: '#2E2E2E',
    fontWeight: 'bold',
    transition: 'all 3s linear',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.7rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '3.7rem',
    },
    [theme.breakpoints.up(1360)]: {
      fontSize: '4.2rem',
    },
  },
  percent: {
    fontFamily: 'Montserrat',
    fontWeight: 900,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.3rem',
    },
    [theme.breakpoints.up(1360)]: {
      fontSize: '1.6rem',
    },
  },
  section_button: {
    textAlign: 'center',
    left: '50%',
    marginTop: '-50px',
    zIndex: 6,
    position: 'absolute',
    minHeight: '30px',
    transform: 'translate(-50%, 0%)',
  },
  icon: {
    color: '#e5e5e5',
    cursor: 'pointer',
    transition: 'all .2s ease-in-out',
    '&:hover': {
      transform: 'scale(1.1)',
      color: '#0095ff',
      fontSize: '43px',
      marginTop: '5px',
    },
    marginTop: '10px',
    background: 'white',
    border: '0px solid white',
    borderRadius: '50% 50%',
  },
  icon_hidden: {
    display: 'none',
  },
  numTable: {
    margin: '0 auto',
    marginTop: '-55px',
    zIndex: 30,
    backgroundColor: '#dbdbdb',
    border: '0px solid #a0a0a0',
    borderRadius: '6px',
    paddingTop: '4px',
    paddingLeft: '4px',
    paddingRight: '4px',
  },
  numBox: {
    background: '#a0a0a0',
    padding: '3px 6px',
    border: '0px solid #b9b9b9',
    borderRadius: '6px',
    fontWeight: 'bold',
    fontSize: '11px',
    marginLeft: '1px',
    marginRight: '1px',
    marginTop: '2px',
  },
  numRed: {
    color: '#ea6657',
    '-webkit-box-shadow': '1px 2px 3px 1px #FF9494',
    'box-shadow': '1px 2px 3px 1px #FF9494',
  },

  numGreen: {
    color: '#74ff69',
    '-webkit-box-shadow': '1px 2px 3px 1px #5DFF2C',
    'box-shadow': '1px 2px 3px 1px #5DFF2C',
  },
  vaultdoorouter: {
    position: 'relative',
    width: '195px',
    height: '195px',
    borderRadius: '6%',
    textAlign: 'center',
    marginTop: '-5px',
    overflow: 'hidden',
  },
  vaultdoorinner: {
    position: 'absolute',
    margin: '0px',
    width: '175px',
    height: '175px',
    background: 'none',
    borderRadius: '50%',
    cursor: 'pointer',
    marginTop: '-2px',
  },
  vaultdoorlockshadow: {
    position: 'absolute',
    width: '170px',
    height: '90px',
    background:
      '-webkit-linear-gradient(rgba(160, 160, 160, 0.2), transparent)',
    margin: '85px 45px',
    transform: 'rotate(-45deg)',
    transition: 'all .05s ease-out',
  },
  vaultdoorlockwrapper: { position: 'absolute', width: '80px', height: '80px' },
  vaultdoorlock: { background: '#b9b9b9' },
  vaultdoorcircle: {
    position: 'absolute',
    width: '155px',
    height: '155px',
    margin: '20px',
    borderRadius: '50%',
  },
  vaultdoorpistons: {
    position: 'absolute',
    width: '170px',
    height: '170px',
    margin: '90px 12.5px',
    background: 'none',
  },
  piston: {
    position: 'absolute',
    background: '#b9b9b9',
    width: '170px',
    height: '15px',
    borderRadius: '4px',
    transition: 'all .05s ease-out',
  },
  piston2: { transform: 'rotate(45deg)' },
  piston3: { transform: 'rotate(90deg)' },
  piston4: { transform: 'rotate(135deg)' },
  vaultdoorhandleshadow: {
    position: 'absolute',
    background: '#a0a0a0',
    width: '75px',
    height: '75px',
    margin: '60px',
    borderRadius: '50%',
  },
  vaultdoorhandlelongshadow: {
    position: 'absolute',
    background: '-webkit-linear-gradient(#a0a0a0, transparent)',
    width: '94px',
    height: '94px',
    margin: '82.5px',
    transform: 'rotate(-45deg)',
  },
  vaultdoorhandle: {
    position: 'absolute',
    border: 'solid 12px #d6d6d6',
    width: '93px',
    height: '93px',
    margin: '50px',
    borderRadius: '50%',
    transition: 'all .3s ease-in-out',
  },
  handlebar: {
    position: 'absolute',
    background: '#d6d6d6',
    height: '11px',
    width: '70px',
    margin: '30px 0',
  },
  bar1: { transform: 'rotate(45deg)' },
  bar2: { transform: 'rotate(135deg)' },
  vaultdoorlockshadowActive: {
    width: '150px',
    margin: '75px 45px',
    transition: 'all .2s ease-out',
  },
  pistonActive: {
    width: '155px',
    marginLeft: '7.5px',
    marginRight: '7.5px',
    transition: 'all .2s ease-out',
  },
  vaultdoorhandleActive: {
    transform: 'rotate(-180deg)',
    transition: 'all .7s ease-in-out',
  },

  led_red: {
    margin: '0 auto',
    width: '14px',
    height: '14px',
    backgroundColor: '#F00',
    borderRadius: '50%',
    position: 'relative',
    marginTop: '5px',
  },
  led_green: {
    margin: '0 auto',
    width: '14px',
    height: '14px',
    backgroundColor: '#72fd68',
    borderRadius: '50%',
    position: 'relative',
    marginTop: '5px',
  },

  led_blink: { animation: '$blinkRed 0.5s infinite' },
  '@keyframes blinkRed': {
    from: { 'background-color': '#F00' },
    '50%': {
      'background-color': '#A00',
      'box-shadow':
        'rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 0',
    },
    to: { 'background-color': '#F00' },
  },
  lockedPic: {
    margin: '0 auto',
    height: '15px',
    width: '60px',
    background: 'url("/images/locked.png")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  },
  unlockedPic: {
    margin: '0 auto',
    height: '16px',
    width: '60px',
    background: 'url("/images/open.png")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  },
}));

interface ProgressProps {
  percent: number;
  name: string;
  back: Function;
  showBack: boolean;
  setBackHover: Function;
  wheelProc: any;
  setStartModalData: Function;
  setWarningVisible: Function;
  setVaultVisible: Function;
}

const startColor = '#d85a50';
const endColor = '#74ff69';

const opts = {
  angle: 0.12,
  colorStart: startColor,
  colorStop: startColor,
  generateGradient: false,
  highDpiSupport: true,
  limitMax: false,
  limitMin: false,
  lineWidth: 0.05,
  radiusScale: 1,
  strokeColor: '#e2e2e2',
};

const ProgressGuageWheelTwo: FunctionComponent<ProgressProps> = ({
  name,
  percent,
  back,
  showBack,
  setBackHover,
  wheelProc,
  setStartModalData,
  setWarningVisible,
  setVaultVisible,
}) => {
  const [isClickTriggered, setClickTriggered] = useState(false);
  //NOTE: below not used...yet. for two dimensions we need to handle home click
  const [tabIndex, setTabIndex] = useState(0);
  const classes = useStyles();
  const iconClassName = `${classes.icon} ${
    showBack ? classes.icon_hidden : ''
  }`;

  const isVaultLocked = !wheelProc.children
    ? true
    : wheelProc.children[wheelProc.children.length - 1].isLocked;
  const dashboardApi = useDashboardApi();

  useEffect(() => {
    if (!isVaultLocked) {
      dashboardApi.dashboardCompleted();
    }
  }, [isVaultLocked]);

  const tabRefs: Array<any> = [React.createRef()];
  const handleModalADAKeyEvents = (key, e, tabRefs, callback) => {
    if (key === 'enter') {
      if (document.activeElement === ReactDOM.findDOMNode(tabRefs[0].current)) {
        callback();
        return;
      }
    }
    e.preventDefault();
  };

  const onVaultClick = () => {
    const lastWheelItem = wheelProc.children[wheelProc.children.length - 1];
    const vaultIsLocked = lastWheelItem.isLocked;
    if (vaultIsLocked) {
      setClickTriggered(true);
      setTimeout(() => {
        setClickTriggered(false);
      }, 300);
      setTimeout(() => {
        setStartModalData(lastWheelItem);
        setWarningVisible(true);
      }, 300);
    } else {
      setClickTriggered(true);
      //simulates a 'try click
      setTimeout(() => {
        setClickTriggered(false);
      }, 2300);
      setTimeout(() => {
        setStartModalData(lastWheelItem);
        setVaultVisible(true);
      }, 800);
    }
  };

  const vaultdoorlockshadow = isClickTriggered
    ? classes.vaultdoorlockshadowActive
    : '';
  const piston = isClickTriggered ? classes.pistonActive : '';
  const vaultdoorhandle = isClickTriggered ? classes.vaultdoorhandleActive : '';

  return (
    <>
      <div className={classes.canvasHolder} tabIndex={700} ref={tabRefs[0]}>
        <div className={classes.vaultdoorouter} onClick={onVaultClick}>
          <div className={classes.vaultdoorinner}>
            <div
              className={
                classes.vaultdoorlockshadow + ' ' + vaultdoorlockshadow
              }
            ></div>
            <div className={classes.vaultdoorlockwrapper}>
              <div
                className={
                  classes.vaultdoorlock + ' ' + classes.vaultdoorcircle
                }
              ></div>
              <div
                className={
                  classes.vaultdoorlock + ' ' + classes.vaultdoorpistons
                }
              >
                <div className={classes.piston + ' ' + piston}></div>
                <div
                  className={
                    classes.piston + ' ' + classes.piston2 + ' ' + piston
                  }
                ></div>
                <div
                  className={
                    classes.piston + ' ' + classes.piston3 + ' ' + piston
                  }
                ></div>
                <div
                  className={
                    classes.piston + ' ' + classes.piston4 + ' ' + piston
                  }
                ></div>
              </div>
            </div>
            <div className={classes.vaultdoorhandleshadow}></div>
            <div className={classes.vaultdoorhandlelongshadow}></div>
            <div className={classes.vaultdoorhandle + ' ' + vaultdoorhandle}>
              <div className={classes.handlebar + ' ' + classes.bar1}></div>
              <div className={classes.handlebar + ' ' + classes.bar2}></div>
            </div>
          </div>
        </div>
      </div>
      {!isVaultLocked && <div className={classes.unlockedPic} />}
      {isVaultLocked && <div className={classes.lockedPic} />}

      <div className={classes.section_button}>
        <Home
          className={iconClassName}
          fontSize="large"
          onClick={() => back()}
          onMouseEnter={() => setBackHover(true)}
          onMouseLeave={() => setBackHover(false)}
        />
      </div>
      <KeyboardEventHandler
        handleKeys={['enter']}
        onKeyEvent={(key, e) =>
          handleModalADAKeyEvents(key, e, tabRefs, onVaultClick)
        }
        handleFocusableElements
      />
    </>
  );
};

export default ProgressGuageWheelTwo;
