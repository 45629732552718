import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import ResultPanel from '../module-viewer/result-panel/result-panel';
import axiosInst, { axiosWrapper } from '../../common/axios';
import {
  AdditionalResultCards,
  AssetSearch,
  AssetSearchResponse,
  DraftJsBlock,
  EventType,
  GlobalActionType,
  Result,
  ResultParams,
} from '../../common/types';
import { useLocation, useParams } from 'react-router';
import { makeStyles } from '@material-ui/core';
import {
  getCodeFromBlock,
  getCodesFromBlocks,
  isResourceBlock,
  isTaskBlock,
} from '../../common/draftJsUtils';
import BodyGroup from '../layout/body-group';
import { useStateValue } from '../../hoc/state';

const useStyles = makeStyles((theme) => ({
  row: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
  },
}));

interface TaskResourceIds {
  tasks: DraftJsBlock[];
  resources: DraftJsBlock[];
}

const ResultViewer: FunctionComponent = () => {
  const { globalDispatch } = useStateValue();
  const [taskCards, setTaskCards] = useState<AdditionalResultCards | null>(
    null
  );
  const [selected, setSelected] = useState<Result | null>(null);
  const [taskResources, setTaskResources] = useState<TaskResourceIds | null>(
    null
  );
  const params = useParams<ResultParams>();
  const classes = useStyles();
  let location = useLocation();
  const parentDiv = useRef<HTMLDivElement>(null);

  const buildTaskCards = (tasksLinks: AssetSearchResponse) => {
    if (selected && taskResources) {
      let additionalResultCards: AdditionalResultCards = {};
      for (const block of taskResources.tasks) {
        const code = getCodeFromBlock(block, selected.layout.entityMap);
        const task = tasksLinks.results.find((t) => t.code === code);
        if (task) {
          additionalResultCards = {
            ...additionalResultCards,
            [block.entityRanges[0].key]: task,
          };
        }
      }
      for (const block of taskResources.resources) {
        const code = getCodeFromBlock(block, selected.layout.entityMap);
        const task = tasksLinks.links.find((t) => t.code === code);
        if (task) {
          additionalResultCards = {
            ...additionalResultCards,
            [block.entityRanges[0].key]: {
              titleDescriptionImage: task,
              url: task.url,
            },
          };
        }
      }
      setTaskCards(additionalResultCards);
    }
  };

  const postLoadPage = (result: Result) => {
    globalDispatch({
      payload: {
        event: {
          type: EventType.RESULT_SELECTION,
          data: {
            resultCode: result.code,
          },
        },
      },
      type: GlobalActionType.TRACK_EVENT,
    });
    setSelected(result);

    if (parentDiv.current) {
      parentDiv.current.scrollTop = 0;
    }
  };

  useEffect(() => {
    if (selected) {
      const tasks = selected.layout.blocks.filter((b) => isTaskBlock(b.text));
      const resources = selected.layout.blocks.filter((b) =>
        isResourceBlock(b.text)
      );
      setTaskResources({
        tasks,
        resources,
      });
    }
  }, [selected]);

  useEffect(() => {
    if (
      selected &&
      taskResources &&
      (taskResources.tasks.length > 0 || taskResources.resources.length > 0)
    ) {
      const request: AssetSearch = {
        resultCodes: getCodesFromBlocks(
          taskResources.tasks,
          selected.layout.entityMap
        ),
        linkCodes: getCodesFromBlocks(
          taskResources.resources,
          selected.layout.entityMap
        ),
      };
      axiosWrapper<AssetSearchResponse>(
        axiosInst.post(`results/assets`, request),
        ({ data }) => buildTaskCards(data),
        (e, errorMessage) => {}
      );
    } else {
      setTaskCards({});
    }
  }, [taskResources]);

  useEffect(() => {
    setTaskCards(null);
    setTaskResources(null);
    setSelected(null);
    axiosWrapper<Result>(
      axiosInst.get(`results/code/${params.resultCode}`),
      ({ data }) => postLoadPage(data),
      (e, errorMessage) => {}
    );
  }, [location]);

  if (!(selected && taskCards)) {
    return null;
  }

  return (
    <BodyGroup className={classes.row} scrollRef={parentDiv}>
      <ResultPanel result={selected} additionalCards={taskCards} />
    </BodyGroup>
  );
};

export default ResultViewer;
