import React, { FunctionComponent } from 'react';

const ErrorModalContent: FunctionComponent = () => {
  return (
    <div>
      <h1>You Got Us</h1>
      <p>Our Appologies. An Error Has Occurred. Please Try again</p>
    </div>
  );
};

export default ErrorModalContent;
