import React, { FunctionComponent, memo } from 'react';
import { ResultPanelItemProps } from '../../../common/types';
import { makeStyles } from '@material-ui/core';
import ResultPanelItemStyledText from '../../../components/universal/universal-styled-text';

const useStyles = makeStyles((theme) => ({
  articleStandardContent: {
    lineHeight: '1.688rem',
    paddingBottom: '1.3rem',
  },
  codeBlock: {
    backgroundColor: '#f9f9f9',
    padding: '1rem',
  },
}));

export const ResultPanelCodeBlock: FunctionComponent<ResultPanelItemProps> = ({
  block,
  entityMap,
}) => {
  const classes = useStyles();

  return (
    <div key={block.key} className={classes.articleStandardContent}>
      <div className={classes.codeBlock}>
        <ResultPanelItemStyledText block={block} entityMap={entityMap} />
      </div>
    </div>
  );
};

export default memo(ResultPanelCodeBlock);
