import axios, { AxiosResponse } from 'axios';

let refreshTokenRequest;

const axiosInst = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

const refreshToken = () => {
  const userData: any = {};
  userData.refreshToken = sessionStorage.getItem('refreshToken');

  return axiosInst.post('/authenticate', userData);
};

const resetAuthTokenRequest = () => {
  setupInterceptors();
  refreshTokenRequest = null;
};

function getRefreshToken() {
  if (!refreshTokenRequest) {
    refreshTokenRequest = refreshToken();
    // refreshTokenRequest.then(resetAuthTokenRequest, resetAuthTokenRequest);
  }

  return refreshTokenRequest;
}

export const isTokenError = (error): boolean => {
  return (
    error.config &&
    error.response &&
    error.response.data &&
    error.response.data.attributes &&
    error.response.data.attributes.type === 'TokenError'
  );
};

export const setupInterceptors = () => {
  const axiosResponseInterceptor = axiosInst.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (isTokenError(error)) {
        axiosInst.interceptors.response.eject(axiosResponseInterceptor);

        return getRefreshToken()
          .then((resp) => {
            sessionStorage.setItem('token', resp.data.token);
            sessionStorage.setItem('refreshToken', resp.data.refreshToken);
            error.config.headers.Authorization =
              'Bearer ' + sessionStorage.getItem('token');
            return axiosInst(error.config);
          })
          .catch((refreshError) => {
            resetAuthTokenRequest();
            throw refreshError;
          });
      } else {
        throw error;
      }
    }
  );
};

axiosInst.interceptors.request.use((config) => {
  const token = sessionStorage.getItem('token');
  if (config && config.headers) {
    config.headers.Authorization = token ? `bearer ${token}` : '';
  }
  return config;
});

setupInterceptors();

const buildErrorFromResponse = (error) => {
  if (!error) {
    return 'Unknown Error';
  }
  if (error.status === 422) {
    return 'Validation Error';
  }
  const { message, id } = error.data;
  return message + (id ? ` - ${id}` : null);
};

export const axiosWrapper = <T>(
  promise: Promise<AxiosResponse<T>>,
  successCallback: (response: AxiosResponse<T>) => void,
  failCallback,
  finallyCallback?,
  dispatch?
) => {
  promise
    .then((resp) => successCallback(resp))
    .catch((e) => {
      if (isTokenError(e)) {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('refreshToken');
        window.document.location.href = '/';
      } else {
        failCallback(e.response, buildErrorFromResponse(e.response));
      }
    })
    .finally(finallyCallback ? finallyCallback() : () => {});
};

export default axiosInst;
