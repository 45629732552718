import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core';
import { CustomBreakPoints, ResultVideoProps } from '../../../common/types';

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: '.5rem',
    marginBottom: '1.7rem',
    display: 'flex',
    position: 'relative',
    width: '654px',
    paddingTop: '56.25%',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      width: '100%',
    },
  },
  image: {
    top: 0,
    right: 0,
    width: '100%',
    height: '100%',
    position: 'absolute',
    objectFit: 'cover',
  },
}));

const ResultImage: FunctionComponent<ResultVideoProps> = ({ video }) => {
  const classes = useStyles();
  return (
    <div className={classes.content}>
      <img className={classes.image} src={video} alt="path" />
    </div>
  );
};

export default ResultImage;
