import * as d3 from 'd3';

window['mouseOutTransitionBlocker'] = false;

//tsc buildSVG.ts
class SVGBuilder {
  private width: number;
  private radius: number;
  private d3: any;
  private data: Object;
  //private svg: Object;
  private outerCircle: any;
  private parent: any;
  private label: any;
  private label2: any;
  private label3: any;
  private path: any;
  private root: any;
  private g: any;
  private params: any;
  private determineClickAction: Function;
  private triggerLockedWarning: Function;
  private triggerStartWarning: Function;
  private navigateToCheckup: Function;
  private updateBreadCrumbs: Function;
  private tabIndexTracker;

  constructor(
    data: Object,
    width: number = 932,
    determineClickAction: Function,
    triggerLockedWarning: Function,
    triggerStartWarning: Function,
    navigateToCheckup: Function,
    updateBreadCrumbs: Function,
    params: any
  ) {
    this.width = width;
    this.radius = this.width / 6;
    this.data = data;
    this.determineClickAction = determineClickAction;
    this.triggerLockedWarning = triggerLockedWarning;
    this.triggerStartWarning = triggerStartWarning;
    this.navigateToCheckup = navigateToCheckup;
    this.updateBreadCrumbs = updateBreadCrumbs;
    this.params = params;
    this.tabIndexTracker = 0;
    window['mouseOutTransitionBlocker'] = false;
  }

  /**************************************** ********************
   // when we partition our data, we need values to be weighted correctly (d3 heirarchy stuff),
   // we however want all pie slices to be the same size, which this method does two levels deep. Any other data ammendments
   // that only affect the client can go here.
   ***************************************************************/

  public static preProcessData(
    baseAmount: number,
    myData: any,
    myProgress: any,
    myDashboard,
    lockingCallback: any
  ) {
    const dashboardCheckups = myDashboard.checkups;
    const progressCheckups = myProgress.progress.checkups;

    function findHasAnswersAndNoResults(id: string) {
      if (
        progressCheckups[id].answers.length > 0 &&
        progressCheckups[id].resultList.length === 0
      ) {
        return true;
      } else {
        return false;
      }
    }

    function totalUnits(id): any {
      return Object.values(progressCheckups[id].results).reduce(
        (total: any, cur: any) => {
          return total + 1;
        },
        0
      );
    }

    function unitsCompletedCalc(id): any {
      return Object.values(progressCheckups[id].results).reduce(
        (total: any, cur: any) => {
          if (cur.completed === true) {
            return total + 1;
          } else {
            return total;
          }
        },
        0
      );
    }

    function calcPercentComplete(id) {
      let length = progressCheckups[id].resultList.length;
      let unitsCompleted = unitsCompletedCalc(id);
      let percentComplete = Math.floor((unitsCompleted / length) * 100);
      return percentComplete;
    }

    function calcCompositePercentComplete(nodeHeld) {
      let compositelength = 0;
      let compositeUnitsCompleted = 0;
      nodeHeld.forEach((element) => {
        compositelength += totalUnits(element.code);
        compositeUnitsCompleted += unitsCompletedCalc(element.code);
      });
      const percentComplete = Math.floor(
        (compositeUnitsCompleted / compositelength) * 100
      );
      return percentComplete;
    }

    let firstLevelChildrenLength = myData.children.length;
    let firstLevelValues = baseAmount / firstLevelChildrenLength;
    let prevHasAnswersAndNoResults = false;
    let prevPercentageComplete = 0;
    let newMyData = myData.children.map((node) => {
      let nodeHeld = null;
      if (node.children && node.children.length) {
        var secondLevelChildrenLength = node.children.length;
        nodeHeld = node.children.map((node2) => {
          const isParentVault = node.centerOfWheel === true;
          return {
            ...node2,
            value: isParentVault
              ? 0
              : firstLevelValues / secondLevelChildrenLength,
            percentageComplete: calcPercentComplete(node2.code),
            //for now children are always unlocked
            isLocked: false,
            //once we have nested questionaries with results, revisit this
            completeNoResults: false,
          };
        });
        //top level result with children
        let percentageComplete = calcCompositePercentComplete(nodeHeld);
        let isLocked = prevPercentageComplete === 100 ? false : node.isLocked;
        isLocked = prevHasAnswersAndNoResults ? false : isLocked;
        //let completeNoResults = prevHasAnswersAndNoResults;

        if (
          progressCheckups[node.code] &&
          isLocked != progressCheckups[node.code].locked
        ) {
          progressCheckups[node.code].locked = isLocked;
          lockingCallback(node);
        }

        //cleanup looping vars before returning
        prevPercentageComplete = percentageComplete;
        prevHasAnswersAndNoResults = findHasAnswersAndNoResults(node.code);

        return {
          ...node,
          children: nodeHeld,
          percentageComplete: percentageComplete,
          isLocked: isLocked,
          //now this is really the current value(not the prev)
          completeNoResults: prevHasAnswersAndNoResults,
        };
      } else {
        //top level result without children
        let percentageComplete = calcPercentComplete(node.code);
        let isLocked = prevPercentageComplete === 100 ? false : node.isLocked;
        isLocked = prevHasAnswersAndNoResults ? false : isLocked;
        //let completeNoResults = prevHasAnswersAndNoResults;

        if (isLocked != progressCheckups[node.code].locked) {
          progressCheckups[node.code].locked = isLocked;
          lockingCallback(node);
        }

        //cleanup looping vars before returning
        prevPercentageComplete = percentageComplete;
        prevHasAnswersAndNoResults = findHasAnswersAndNoResults(node.code);
        // console.log(prevHasAnswersAndNoResults);
        return {
          ...node,
          value: firstLevelValues,
          percentageComplete: percentageComplete,
          isLocked: isLocked,
          //now this is really the current value(not the prev)
          completeNoResults: prevHasAnswersAndNoResults,
        };
      }
    });
    return { ...myData, children: newMyData };
  }

  private partition(d3: any, data: Object) {
    const root = d3
      .hierarchy(data)
      .sum((d) => d.value)
      .sort((a, b) => b.value - a.value);
    return d3.partition().size([2 * Math.PI, root.height + 1])(root);
  }

  private arc(c: any, r?: number) {
    return d3
      .arc()
      .startAngle((d) => d.x0)
      .endAngle((d) => d.x1)
      .padAngle((d) => Math.min((d.x1 - d.x0) / 2, 0.005))
      .padRadius((r || this.radius) * 0)
      .innerRadius((d) => d.y0 * (r || this.radius))
      .outerRadius((d) =>
        Math.max(d.y0 * (r || this.radius), d.y1 * (r || this.radius) + 40)
      )(c);
  }

  private arcVisible(d: any) {
    return d.y1 <= 2 && d.y0 >= 1 && d.x1 > d.x0;
  }

  private labelVisible(d: any) {
    return d.y1 <= 2 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03;
  }

  private labelVisible3(d: any) {
    return d.y1 <= 2 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03
      ? 0.1
      : 0;
  }

  private labelVisible2(d: any) {
    return d.y1 <= 2 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03
      ? 0.1
      : 0;
  }

  private labelTransform(d: any, r?: number) {
    let radius: number = r || this.radius;
    const x: number = (((d.x0 + d.x1) / 2) * 180) / Math.PI;
    const y: number = ((d.y0 + d.y1) / 2) * radius + 140;
    return `rotate(${x - 90}) translate(${y},0) rotate(${
      x < 180 ? 0 : 180
    }) rotate(${x < 180 ? 90 : 270}) rotate(${x > 90 && x < 270 ? 180 : 0})`;
  }

  private labelTransform2(d: any, r?: number, level?: number) {
    let radius: number = r || this.radius;
    const x: number = (((d.x0 + d.x1) / 2) * 180) / Math.PI;
    const y: number = ((d.y0 + d.y1) / 2) * radius + 62;
    return `rotate(${x - 90}) translate(${y},0) rotate(${
      x < 180 ? 0 : 180
    }) rotate(${x < 180 ? 90 : 270})`;
  }

  private labelTransform3(d: any, r?: number) {
    let radius: number = r || this.radius;
    const x: number = (((d.x0 + d.x1) / 2) * 180) / Math.PI;
    const y: number = ((d.y0 + d.y1) / 2) * radius + 7;
    return `rotate(${x - 90}) translate(${y},0) rotate(${
      x < 180 ? 0 : 180
    }) rotate(${x < 180 ? 90 : 270}) rotate(${x > 90 && x < 270 ? 180 : 0})`;
  }

  //svg gradients must be predefined, so we generalize them
  private generalizePercentageComplete(realPercentageComplete: Number) {
    if (realPercentageComplete >= 100) {
      return '1';
    }
    if (realPercentageComplete >= 80) {
      return '8';
    }
    if (realPercentageComplete >= 60) {
      return '6';
    }
    if (realPercentageComplete >= 40) {
      return '4';
    }
    if (realPercentageComplete >= 20) {
      return '2';
    }
    return '0';
  }

  //adds the angle and the amount complete from the db;
  private gradientAssign(d: any) {
    let simX: number = (((d.x0 + d.x1) / 2) * 180) / Math.PI;
    simX = parseInt(simX.toString());

    const levelCompleteString: string = d.data.percentageComplete
      ? d.data.percentageComplete
      : '0';
    this.generalizePercentageComplete(parseInt(levelCompleteString));
    let levelCompletePostFix = this.generalizePercentageComplete(
      parseInt(levelCompleteString)
    );

    //if locked make it grey
    if (d.data.isLocked) {
      return 'url(#svgGradient-grey)';
    }
    //hack to make vault green if unlocked (defualts locked above as it should)
    if (d.data.name === 'Legal Vault') {
      return 'url(#svgGradient-1)';
    }

    if (d.data.completeNoResults) {
      return 'url(#svgGradient-1)';
    }

    //if 100% of 0% we don't care about the angle
    if (levelCompletePostFix === '1' || levelCompletePostFix === '0') {
      return 'url(#svgGradient-' + levelCompletePostFix + ')';
    }
    return 'url(#svgGradient' + simX + '-' + levelCompletePostFix + ')';
  }

  private addLinearGradients(i: number, offset: number, defs: any) {
    offset = Math.round(10 * offset) / 10;

    var offsetLabel = parseInt(offset.toString().replace('.', '')).toString();
    var anglePI = i * (Math.PI / 180);
    var gradient = defs
      .append('linearGradient')
      .attr('id', 'svgGradient' + i + '-' + offsetLabel)
      .attr('gradientUnits', 'objectBoundingBox')
      .attr('x1', Math.round(50 + Math.sin(anglePI) * 50) + '%')
      .attr('y1', Math.round(50 + Math.cos(anglePI) * 50) + '%')
      .attr('x2', Math.round(50 + Math.sin(anglePI + Math.PI) * 50) + '%')
      .attr('y2', Math.round(50 + Math.cos(anglePI + Math.PI) * 50) + '%');

    gradient
      .append('stop')
      .attr('class', 'start')
      .attr('offset', 0)
      .attr('stop-color', '#acff7e')
      .attr('stop-opacity', 1);

    gradient
      .append('stop')
      .attr('class', 'start')
      .attr('offset', offset)
      .attr('stop-color', '#74ff69')
      .attr('stop-opacity', 1);

    gradient
      .append('stop')
      .attr('class', 'end')
      .attr('offset', offset)
      .attr('stop-opacity', 0);
  }

  public clicked = (p: any = this.root, isInternalClick: boolean = true) => {
    //not sure why this isn't being auto assigned from above param decleration, but double check below
    if (!p) {
      p = this.root;
    }
    //if we are passing in a number to this method, we need to find the right object
    if (typeof p === 'string') {
      this.root.each(function (node) {
        if (node.data.code == p) {
          p = node;
        }
      });
      //if no node was found to assign to p, assume the id doesn't exist and put us back to main menu
      if (typeof p === 'string') {
        this.updateBreadCrumbs(null, ['Main Menu']);
        return false;
      }
    }
    window['mouseOutTransitionBlocker'] = true;

    if (p.data.isLocked || p.data.completeNoResults) {
      this.triggerLockedWarning(p.data);
      return false;
    }

    if (p.depth === 1 && isInternalClick) {
      this.triggerStartWarning(p.data);
      return false;
    }

    if (!p.data.children || p.data.children.length === 0) {
      this.determineClickAction(p.data.code);
      return false;
    }

    //check for a 'drill down depth of 0' against the object, if so hide the back button
    var breadcrumb = d3.select('#breadCrumb');
    if (p.depth === 0) {
      this.updateBreadCrumbs(null, ['Main Menu']);
    } else {
      this.updateBreadCrumbs(p.data.code, (d) =>
        p
          .ancestors()
          .map((d) => d.data.name)
          .reverse()
      );
    }

    //just a cool animation with our outer circle
    this.outerCircle
      .transition()
      .duration(500)
      .attr('opacity', 0)
      .attr('stroke-width', 700)
      .attr('r', 0)
      .attr('stroke', '#74ff69')
      .transition()
      .duration(500)
      .attr('opacity', 1)
      .attr('r', this.radius + this.radius + 48)
      .attr('stroke-width', 18)
      .transition()
      .duration(500)
      .attr('stroke', '#d2d2d2')
      .attr('r', this.radius + this.radius + 43)
      .attr('stroke-width', 8)
      .on('end', function () {
        //we are just kinda piggy backing off of this animation to reinstate the text effects.
        window['mouseOutTransitionBlocker'] = false;
      });
    this.parent.datum(p.parent || this.root);

    this.root.each(
      (d) =>
        (d.target = {
          x0:
            Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) *
            2 *
            Math.PI,
          x1:
            Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) *
            2 *
            Math.PI,
          y0: Math.max(0, d.y0 - p.depth),
          y1: Math.max(0, d.y1 - p.depth),
        })
    );

    const t = this.g.transition().duration(750);

    // Transition the data on all arcs, even the ones that aren’t visible,
    // so that if this transition is interrupted, entering arcs will start
    // the next transition from the desired position.

    //a bunch of bullshit rebindings to avoid this context issues.
    const arcVisible = this.arcVisible;
    const arc = this.arc;
    const labelVisible = this.labelVisible;
    const labelVisible2 = this.labelVisible2;
    const labelVisible3 = this.labelVisible3;
    const labelTransform = this.labelTransform;
    const labelTransform2 = this.labelTransform2;
    const labelTransform3 = this.labelTransform3;
    const radius = this.radius;

    this.path
      .transition(t)
      .tween('data', (d) => {
        const i = d3.interpolate(d.current, d.target);
        return (t) => (d.current = i(t));
      })
      .filter(function (d) {
        return +this.getAttribute('fill-opacity') || true;
      })
      //only shows nodes with children, option here to toggle visibility if no children i.e. ((d.children ? 1 : 0.4))
      .attr('fill-opacity', (d) =>
        arcVisible(d.target) ? (d.children ? 1 : 1) : 0
      )
      .attr('stroke-opacity', (d) =>
        arcVisible(d.target) ? (d.children ? 0.17 : 0.17) : 0
      )
      .attr('display', (d) => (this.arcVisible(d.target) ? '' : 'none'))
      .attrTween('d', (d) => () => arc(d.current, radius));

    this.label
      .filter(function (d) {
        return +this.getAttribute('fill-opacity') || labelVisible(d.target);
      })
      .transition(t)
      .attr('fill-opacity', (d) => +labelVisible(d.target))
      .attrTween('transform', (d) => () => labelTransform(d.current, radius));

    this.label2
      .filter(function (d) {
        return +this.getAttribute('fill-opacity') || labelVisible2(d.target);
      })
      .transition(t)
      .attr('fill-opacity', (d) => +labelVisible2(d.target))
      .attrTween('transform', (d) => () => labelTransform2(d.current, radius));

    this.label3
      .filter(function (d) {
        return +this.getAttribute('fill-opacity') || labelVisible3(d.target);
      })
      .transition(t)
      .attr('fill-opacity', (d) => +labelVisible3(d.target))
      .attrTween('transform', (d) => () => labelTransform3(d.current, radius));
  };

  public tabIterator() {
    this.tabIndexTracker += 1;
    return this.tabIndexTracker;
  }

  //Main functionality below
  public renderWheel() {
    const width = this.width;
    const radius = this.radius;
    const data = this.data;

    this.root = this.partition(d3, data);

    this.root.each((d) => (d.current = d));

    //this replaces the original observable notebooks 'DOM' functionality
    var svgPre = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svgPre.setAttribute('width', width.toString());
    svgPre.setAttribute('height', width.toString());
    svgPre.setAttribute('viewBox', '0,0,' + width + ',' + width);

    const svg = d3
      .select(svgPre)
      .style('width', '100%')
      .style('height', 'auto')
      .style('font', '10px sans-serif')
      .attr('class', 'pie__white_radient_dropshadow');

    this.g = svg
      .append('g')
      .attr('transform', `translate(${width / 2},${width / 2})`);

    var defs = svg.append('defs');

    //100 percent static gradient definition
    var gradient100 = defs
      .append('linearGradient')
      .attr('id', 'svgGradient-1')
      .attr('gradientUnits', 'objectBoundingBox')
      .attr('x1', '0%')
      .attr('y1', '0%')
      .attr('x2', '100%')
      .attr('y2', '100%');

    gradient100
      .append('stop')
      .attr('class', 'start')
      .attr('offset', 1)
      .attr('stop-color', '#74ff69')
      .attr('stop-opacity', 1);

    gradient100
      .append('stop')
      .attr('class', 'end')
      .attr('offset', 1)
      .attr('stop-opacity', 0);

    //0 percent static gradient definition
    var gradient0 = defs
      .append('linearGradient')
      .attr('id', 'svgGradient-0')
      .attr('gradientUnits', 'objectBoundingBox')
      .attr('x1', '100%')
      .attr('y1', '100%')
      .attr('x2', '100%')
      .attr('y2', '100%');

    /*gradient0.append("stop")
      .attr('class', 'start')
      .attr("offset", 0)
      .attr("stop-color", "#74ff69")
      .attr("stop-opacity", 0);

    gradient0.append("stop")
      .attr('class', 'end')
      .attr("offset", 1)
      .attr("stop-opacity", 0);*/

    //100 percent static gradient definition
    var gradientGrey = defs
      .append('linearGradient')
      .attr('id', 'svgGradient-grey')
      .attr('gradientUnits', 'objectBoundingBox')
      .attr('x1', '0%')
      .attr('y1', '0%')
      .attr('x2', '100%')
      .attr('y2', '100%');

    gradientGrey
      .append('stop')
      .attr('class', 'start')
      .attr('offset', 1)
      .attr('stop-color', '#ADADAD')
      .attr('stop-opacity', 1);

    gradientGrey
      .append('stop')
      .attr('class', 'end')
      .attr('offset', 1)
      .attr('stop-opacity', 0);

    //add definitions for all our possible linear gradients
    var i1 = 0;
    var offset = 0.2;
    while (offset <= 0.8) {
      while (i1 < 360) {
        this.addLinearGradients(i1, offset, defs);
        i1++;
      }
      offset += 0.2;
      i1 = 0;
    }

    //add circle for visual indication of outside stroke
    this.outerCircle = this.g
      .append('circle')
      .attr('id', 'outerCircle')
      .attr('r', 0)
      .attr('stroke-width', 200)
      .attr('fill-opacity', 0)
      .attr('stroke', '#d2d2d2');

    this.path = this.g
      .append('g')
      .selectAll('path')
      .data(this.root.descendants().slice(1))
      .join('path')
      .attr('tabindex', (d) => this.tabIterator())
      .attr('fill', (d) => this.gradientAssign(d.current))
      .attr('fill-saved', (d) => this.gradientAssign(d.current))
      .attr('fill-opacity', (d) =>
        this.arcVisible(d.current) ? (d.children ? 1 : 1) : 0
      )
      .attr('display', (d) => (this.arcVisible(d.current) ? '' : 'none'))
      //same rules as arcVisible are fine for this.
      .attr('stroke-opacity', (d) => (this.arcVisible(d.current) ? 0.17 : 0))
      .attr('d', (d) => this.arc(d.current))
      .attr('title', (d) => d.current.name)
      .attr('role', 'nav')
      .attr('aria-label', (d) => d.current.name)
      .attr('stroke-width', 2)
      .attr('class', 'pie__white_radient2')
      .attr('stroke', '#e2e2e2')
      .attr('id', (d) => 'domId' + d.current.data.code);

    //we may want to filter these bindings based off specific conditions in the future.
    this.path
      .filter((d) => d.data.percentageComplete != 101)
      .style('cursor', 'pointer')
      .on('keyup', (d, e) => {
        if (d3.event.keyCode === 13) {
          this.clicked(d);
        }
      })
      .on('click', (d) => this.clicked(d))
      .on('mouseover', function (d, i) {
        d3.select(this).attr('fill', '#0095ff');
        if (d3.select(this).attr('fill-opacity') == 1) {
          d3.select('#iconz' + d.data.code).attr('fill-opacity', 0.3);
          d3.select('#levelText' + d.data.code).attr('fill-opacity', 0.3);
        }

        if (!window['mouseOutTransitionBlocker']) {
          d3.select('#labelID' + d.data.code)
            .transition()
            .attr('font-size', '1.6rem')
            .attr('fill', '#000000')
            .duration(300);
        }
      })
      .on('mouseout', function (d, i) {
        var fillStringRef = d3.select(this).attr('fill-saved');
        d3.select(this).attr('fill', fillStringRef);
        if (d3.select(this).attr('fill-opacity') == 1) {
          d3.select('#iconz' + d.data.code).attr('fill-opacity', 0.1);
          d3.select('#levelText' + d.data.code).attr('fill-opacity', 0.1);
        }
        if (!window['mouseOutTransitionBlocker']) {
          d3.select('#labelID' + d.data.code)
            .transition()
            .attr('font-size', '1.4rem')
            .attr('fill', '#555555')
            .duration(600);
        } else {
          d3.select('#labelID' + d.data.code)
            .attr('font-size', '1.2rem')
            .attr('fill', '#555555');
        }
      });

    this.path.append('title').text(
      (d) =>
        `${d
          .ancestors()
          .map((d) => d.data.name)
          .reverse()
          .join('/')}\n`
    );

    this.label = this.g
      .append('g')
      .attr('pointer-events', 'none')
      .attr('text-anchor', 'middle')
      .style('user-select', 'none')
      .selectAll('text')
      .data(this.root.descendants().slice(1))
      .join('text')
      .attr('font-weight', '600')
      .attr('letter-spacing', '2.5px')
      .attr('dy', '0.35em')
      .attr('font-size', '1.4rem')
      .attr('fill-opacity', (d) => +this.labelVisible(d.current))
      .attr('transform', (d) => this.labelTransform(d.current))
      .attr('fill', '#555555')
      .attr('style', 'font-family:FranklinGothic, Franklin Gothic, sans-serif;')
      .attr('id', (d) => 'labelID' + d.data.code)
      .text((d) => d.data.name);

    // label2 handles the icons
    this.label2 = this.g
      .append('g')
      .attr('pointer-events', 'none')
      .attr('text-anchor', 'middle')
      .style('user-select', 'none')
      .selectAll('text')
      .data(this.root.descendants().slice(1))
      .join('text')
      .attr('dy', '.65em')
      .attr('font-size', '60px')
      .attr('id', (d) => 'iconz' + d.data.code)
      .attr('fill-opacity', (d) => +this.labelVisible2(d.current))
      .attr('transform', (d) => this.labelTransform2(d.current))
      .attr('style', 'font-family:FontAwesome;')
      .attr('fill', (d) => {
        return d.data.isLocked ? '#000' : '#55555';
      })
      .text((d) => {
        // console.log(d.data);
        return d.data.icon;
        //return d.data.icon || '\uf135';
      });

    this.label3 = this.g
      .append('g')
      .attr('pointer-events', 'none')
      .attr('text-anchor', 'middle')
      .style('user-select', 'none')
      .selectAll('text')
      .data(this.root.descendants().slice(1))
      .join('text')
      .attr('font-weight', '600')
      .attr('letter-spacing', '2.5px')
      .attr('dy', '0.35em')
      .attr('font-size', '1.0rem')
      .attr('fill-opacity', (d) => +this.labelVisible3(d.current))
      .attr('transform', (d) => this.labelTransform3(d.current))
      .attr('fill', '#55555')
      .attr('style', 'font-family:FranklinGothic, Franklin Gothic, sans-serif;')
      .attr('id', (d) => 'levelText' + d.data.code);
    //.text(d => (d.data.level === null ? '' : (d.data.level === 4) ? "My Vault" : "Level " + (d.data.level)));

    // this is the inner cirle, which right now, is just a circle. was 28
    this.parent = this.g
      .append('circle')
      .datum(this.root)
      .attr('r', this.radius + 39)
      .attr('fill', '#ffffff');

    this.parent = this.g
      .append('circle')
      .datum(this.root)
      .attr('r', this.radius + 22)
      .attr('fill', '#dbdbdb');

    // this is the 'BreadCrumnb text' #breadCrumb
    //this.g.append("text")
    //	.datum(this.root)
    //	.attr("dy", "1.55em")
    //	.attr('font-size', "50" )
    //	.attr("style","font-family:FranklinGothic-Heavy, Frankin Gothic, sans-serif;")
    //	.attr("opacity", ".7")
    //	.attr("id", "breadCrumb")
    //	.attr("fill", "#55555")
    //	.style('text-anchor', 'middle')
    //	.text("Main Menu")

    // this is the 'back button' #backButton
    /*
    this.g.append("text")
      .datum(this.root)
      .attr("dy", "4.35em")
      .attr("dx", "-.55em")
      .attr('font-size', "30px" )
      .attr("style","font-family:FontAwesome;")
      .attr("opacity", "0")
      .attr("id", "backButton")
      .attr("pointer-events", "all")
      .attr("fill", "#e5e5e5")
      .text("\uf04a")
      .style('cursor', 'pointer')
      .on('mouseover', function(d,i) {
        d3.select(this).transition()
          //.ease('cubic-out')
          //.duration('200')
          .attr('font-size', "32px")
          .attr('fill', '#0095ff');
        })
      .on('mouseout', function(d,i) {
        d3.select(this).transition()
          //.ease('cubic-out')
          //.duration('200')
          .attr('font-size', "30px")
          .attr('fill', '#e5e5e5');
      }).on("click", this.clicked);
    */
    this.outerCircle
      .transition()
      .delay(500)
      .duration(500)
      .attr('r', radius + radius + 54)
      .attr('stroke-width', 35)
      .transition()
      .duration(500)
      .attr('stroke', '#d2d2d2')
      .attr('r', radius + radius + 43)
      .attr('stroke-width', 8);

    if (this.params.checkupId) {
      this.clicked(this.params.checkupId, false);
    }

    return svg.node();
  }

  public greyOutPieSlice(id: number) {
    let pieSelection = this.path.filter((d) => d.data.code === id);
    pieSelection
      .attr('fill-opacity', '0')
      .attr('fill', 'white')
      .transition()
      .duration(500)
      .attr('fill', '#74ff69')
      .attr('fill-opacity', '1');
    pieSelection
      .style('cursor', 'default')
      .on('click', () => {})
      .on('mouseover', function (d, i) {
        //rebind another behavior or just do nothing
      })
      .on('mouseout', function (d, i) {
        //rebind another behavior or just do nothing
      });
  }

  // a public method to get the x and y position of any node
  public getCordinants(code: number) {
    const doc: Document = window.document;
    const domElement = doc.getElementById('domId' + code);
    if (domElement) {
      return {
        x: domElement.getClientRects()[0].left,
        y: domElement.getClientRects()[0].top,
      };
    }
    return { x: 0, y: 0 };
  }
}

export default SVGBuilder;
