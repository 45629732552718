import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core';
import BodyGroup from '../layout/body-group';
import {
  ActionItemsProps,
  CustomBreakPoints,
  ResultType,
} from '../../common/types';
import TaskCard from '../../components/UI/cards/task-card';
import ActionItemsCardHeader from './action-items-card-header';

const useStyles = makeStyles((theme) => ({
  bodyGroup: {
    height: '100%',
    overflow: 'auto',
  },
  itemCardWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up(CustomBreakPoints.mobile)]: {
      marginTop: '1rem',
    },
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      width: '100%',
    },
  },
  itemCard: {
    borderRadius: '5px',
    display: 'inline-flex',
    flexDirection: 'column',
    [theme.breakpoints.up(CustomBreakPoints.mobile)]: {
      marginBottom: '2rem',
      border: '1px solid #CBCBCB',
      backgroundColor: '#fbfbfb',
    },
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      width: '100%',
      alignItems: 'center',
    },
  },
  itemGroup: {
    [theme.breakpoints.up(CustomBreakPoints.mobile)]: {
      padding: '0 4.5rem 3rem 4.5rem',
    },
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      width: '90%',
    },
  },
  itemWrapper: {
    marginBottom: '1.5rem',
  },
}));

const ActionItems: FunctionComponent<ActionItemsProps> = ({
  results,
  checkupCode,
}) => {
  const classes = useStyles();

  let itemGroup: JSX.Element[] | null = null;
  if (results.length > 0) {
    itemGroup = results.map((t, i) => (
      <div className={classes.itemWrapper} key={i}>
        <TaskCard
          task={t.result}
          titleColor={!t.checkupResult.completed ? '#545b64' : '#969b9e'}
          type={ResultType.RESULT}
          completed={t.checkupResult.completed}
        />
      </div>
    ));
  }

  if (results.length == 0) {
    return null;
  }

  return (
    <BodyGroup className={classes.bodyGroup}>
      <div className={classes.itemCardWrapper}>
        <div className={classes.itemCard}>
          <ActionItemsCardHeader results={results} checkupCode={checkupCode} />
          <div className={classes.itemGroup}>{itemGroup}</div>
        </div>
      </div>
    </BodyGroup>
  );
};

export default ActionItems;
