import {
  Container,
  CssBaseline,
  Link,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { useStateValue } from '../../../hoc/state';

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const NotFound: React.FunctionComponent<RouteComponentProps> = (props) => {
  const classes = useStyles();

  const { isAuthenticated } = useStateValue();

  if (!isAuthenticated) {
    return (
      <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    );
  }

  const body = (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h4">
          Sorry, looks like you went the wrong way. <br />
          Let us guide you back!
        </Typography>
        <br />
        <div>
          Need help? Head to the{' '}
          <Link href="https://www.thewayfarer.io/" variant="body2">
            LegalGPS Homepage
          </Link>{' '}
          or feel free to{' '}
          <Link
            href="mailto:support@thewayfarer.io"
            target="_blank"
            variant="body2"
          >
            Contact Us
          </Link>{' '}
          if you have a question.
        </div>
      </div>
    </Container>
  );
  return body;
};

export default withRouter(NotFound);
