import React, { FunctionComponent } from 'react';
import UniversalStyledText from '../../../components/universal/universal-styled-text';
import { DraftJs } from '../../../common/types';

interface QuestionFormattedTextParams {
  text: DraftJs;
}

const QuestionFormattedText: FunctionComponent<QuestionFormattedTextParams> = ({
  text,
}) => {
  return (
    <>
      {text.blocks.map((block) => (
        <p key={block.key}>
          <UniversalStyledText block={block} entityMap={text.entityMap} />
        </p>
      ))}
    </>
  );
};

export default QuestionFormattedText;
