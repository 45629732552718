import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core';
import {
  ActionItemsCardHeaderProps,
  CustomBreakPoints,
} from '../../common/types';
import { useStateValue } from '../../hoc/state';

const useStyles = makeStyles((theme) => ({
  imageHolder: {
    width: '946px',
    height: '180px',
    position: 'relative',
    backgroundSize: 'contain',
    marginBottom: '1.5rem',
    backgroundImage: `url('https://legal-gps-images.s3.us-east-2.amazonaws.com/legalgpspath/action-items.png')`,
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      width: '100%',
      height: '200px',
    },
  },
  imageOverlay: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%;',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(52,124,177,0.9)',
  },
  overlayContent: {
    fontSize: '2rem',
    fontWeight: 'bold',
  },
  headerInner: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      flexDirection: 'column',
    },
  },
  headerLeft: {
    width: '65%',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '4.5rem',
    paddingRight: '2rem',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      width: '100%',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      marginBottom: '1.5rem',
    },
  },
  headerLeftH1: {
    fontSize: '1rem',
    marginBottom: '.3rem',
    fontWeight: 'bold',
  },
  headerLeftH2: {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: '.9rem',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      fontSize: '1.6rem',
      marginBottom: '.4rem',
    },
  },
  headerRight: {
    width: '35%',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      width: '100%',
    },
  },
  headerRightContent: {
    paddingRight: '4.5rem',
    display: 'flex',
    [theme.breakpoints.up(CustomBreakPoints.mobile)]: {
      justifyContent: 'flex-end',
    },
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      width: '100%',
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
  },
  headerRightContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      flexDirection: 'row',
      width: '100%',
    },
  },
  timeToCompleted: {
    display: 'flex',
    [theme.breakpoints.up(CustomBreakPoints.mobile)]: {
      marginBottom: '.5rem',
    },
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      fontSize: '.9rem',
      width: '60%',
    },
  },
  completedCount: {
    display: 'flex',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      fontSize: '.9rem',
      width: '40%',
    },
  },
  headerComputed: {
    paddingLeft: '.4rem',
  },
}));

const ActionItemsCardHeader: FunctionComponent<ActionItemsCardHeaderProps> = ({
  results,
  checkupCode,
}) => {
  const classes = useStyles();
  const { dashboardContext } = useStateValue();

  let title = 'Lorem ipsum dolor';
  let description =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod magna aliqua. Ut enim ad minim ullamco. see more';

  if (dashboardContext) {
    let checkup = dashboardContext.currentDashboard.checkups[checkupCode];
    if (checkup.unlockTitle) {
      title = checkup.unlockTitle;
      description = checkup.unlockDescription;
    }
  }

  const remainingTime = results
    .filter((r) => !r.checkupResult.completed)
    .map((r) => r.result.completionTime)
    .reduce((previousValue, currentValue) => previousValue + +currentValue, 0);

  const completedCount = results.filter(
    (r) => r.checkupResult.completed
  ).length;

  return (
    <div className={classes.imageHolder}>
      <div className={classes.imageOverlay}>
        <div className={classes.headerInner}>
          <div className={classes.headerLeft}>
            <div className={classes.headerLeftH1}>Task List</div>
            <div className={classes.headerLeftH2}>{title}</div>
            <div>{description}</div>
          </div>
          <div className={classes.headerRight}>
            <div className={classes.headerRightContent}>
              <div className={classes.headerRightContentWrapper}>
                <div className={classes.timeToCompleted}>
                  <i
                    className="fa fa-lg fa-clock-o"
                    aria-hidden="true"
                    style={{ color: '#EEDA6D' }}
                  />
                  <span className={classes.headerComputed}>
                    Time to Complete: {remainingTime} min
                  </span>
                </div>
                <div className={classes.completedCount}>
                  <i
                    className="fa fa-lg fa-check-square-o"
                    aria-hidden="true"
                    style={{ color: '#4AD140' }}
                  />
                  <span className={classes.headerComputed}>
                    {completedCount}/{results.length} Completed
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionItemsCardHeader;
