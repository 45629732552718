import React, { FunctionComponent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { RegistrationChild } from '../../common/types';
import { StripeProvider } from '../../components/payment/stripe-provider';
import Payment from './payment';
import StripeElements from '../../components/payment/stripe-element';

const PaymentStripeProvider: FunctionComponent<
  RegistrationChild & RouteComponentProps
> = ({ onContinue }) => {
  return (
    <StripeProvider>
      <StripeElements>
        <Payment onContinue={onContinue} />
      </StripeElements>
    </StripeProvider>
  );
};

export default withRouter(PaymentStripeProvider);
