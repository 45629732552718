import { makeStyles } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    height: '100%',
    backgroundColor: '#fff',
  },
}));

const Body: FunctionComponent = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.content}>{children}</div>;
};

export default Body;
