import React, {
  FunctionComponent,
  MouseEvent,
  useEffect,
  useState,
} from 'react';
import { makeStyles, Popover } from '@material-ui/core';
import { Result } from '../../common/types';
import axiosInst, { axiosWrapper } from '../../common/axios';

interface ProfileParams {
  text: string;
  code: string;
}

const useStyles = makeStyles((theme) => ({
  definitionWrapper: {
    overflow: 'hidden',
    height: '100%',
  },
  titleWrapper: {
    height: '3.5rem',
    padding: '0 2.5rem ',
    display: 'table',
    backgroundColor: '#0095FF',
    color: '#fff',
    width: '100%',
  },
  name: {
    color: '#0095FF',
    fontWeight: 'bold',
    fontSize: '1.25rem',
    display: 'block',
    width: '100%',
    paddingTop: '.5rem',
    paddingBottom: '.5rem',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '1.1rem',
    display: 'block',
    width: '100%',
    paddingBottom: '1.25rem',
    fontFamily: 'Lora, sans-serif',
  },
  text: {
    textAlign: 'center',
    fontSize: '.8rem',
    paddingBottom: '1.25rem',
    lineHeight: '1.4rem',
  },
  definition: {
    height: '100%',
    // height: 'calc(100% - 3.5rem)',
    fontFamily: 'Roboto, sans-serif',
    padding: '1.5rem 1rem',
    textAlign: 'center',
  },
  profilePicture: {
    width: '100px',
    height: '100px',
  },
  blueButt: {
    clear: 'both',
    display: 'flex',
    cursor: 'pointer',
    width: '100%',
    height: '40px',
    color: 'white',
    border: '4px solid #0094ff',
    borderRadius: '4px',
    background: '#0094ff',
    margin: '0 auto',
    alignItems: 'center',
    justifyContent: 'center',
  },
  blueButtText: {
    fontWeight: 'bold',
    fontSize: '1.1rem',
  },
}));

const Profile: FunctionComponent<ProfileParams> = ({ text, code }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLAnchorElement | null>(
    null
  );
  const [result, setResult] = useState<Result | null>(null);
  const classes = useStyles();

  const handleOpen = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const scheduleAMeeting = (url: string) => {
    window.open(url, '_blank');
  };

  useEffect(() => {
    axiosWrapper<Result>(
      axiosInst.get(`/results/code/${code}`),
      ({ data }) => {
        setResult(data);
      },
      (e, errorMessage) => {
        //console.log(errorMessage);
      }
    );
  }, [code]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <a
        href="#"
        onClick={handleOpen}
        target="_blank"
        rel="noopener noreferrer"
      >
        {text}
      </a>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            width: '18rem',
            height: 'auto',
            maxHeight: '100%',
            overflow: 'hidden',
          },
        }}
      >
        {result ? (
          <>
            <div className={classes.definition}>
              <img
                src={result.videos[0]}
                alt=""
                className={classes.profilePicture}
              />

              <div className={classes.name}>{result.title}</div>
              <div className={classes.title}>{result.description}</div>
              <div className={classes.text}>{result.layout.blocks[0].text}</div>
              <div
                className={classes.blueButt}
                onClick={(e) => scheduleAMeeting(result.videos[1])}
              >
                <div className={classes.blueButtText}>Schedule A Meeting</div>
              </div>
            </div>
          </>
        ) : (
          'Loading'
        )}
      </Popover>
    </>
  );
};

export default Profile;
