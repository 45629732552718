import React, { FunctionComponent, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router';
import {
  ActionItemDescriptor,
  ActionItemHeaderButtonType,
  ActionItemsHeaderProps,
  CustomBreakPoints,
  ResultType,
} from '../../common/types';
import BodyGroup from '../layout/body-group';
import ActionItemButton from '../../components/UI/buttons/action-item-button';
import { useStateValue } from '../../hoc/state';

const useStyles = makeStyles((theme) => ({
  actionItemsHeader: {
    height: '60px',
    minHeight: '60px',
    color: '#545b64',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up(CustomBreakPoints.mobile)]: {
      boxShadow: '0px 3px 5px 0px rgba(0,0,0,0.19)',
      marginBottom: '8px',
    },
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      height: '40px',
      minHeight: '40px',
    },
  },
  headerInner: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up(CustomBreakPoints.mobile)]: {
      justifyContent: 'space-between',
    },
  },
  headerLeft: {
    display: 'flex',
    [theme.breakpoints.up(CustomBreakPoints.mobile)]: {
      marginRight: 'auto',
    },
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      flex: 1,
    },
  },
  headerCenter: {
    display: 'flex',
  },
  headerRight: {
    display: 'flex',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      flex: 1,
      justifyContent: 'flex-end',
    },
  },
  headerRightInner: {
    display: 'flex',
    [theme.breakpoints.up(CustomBreakPoints.mobile)]: {
      marginLeft: '1rem',
    },
  },
  backLink: {
    alignSelf: 'center',
    textDecoration: 'none',
    padding: '10px 30px',
    color: '#545b64',
    '&:hover': {
      textDecoration: 'none',
      color: '#545b64',
      backgroundColor: '#CBCBCB',
    },
    '&:visited': {
      textDecoration: 'none',
      color: '#545b64',
    },
  },
  backText: {
    paddingLeft: '.5rem',
    maxWidth: '15rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
}));

const ActionItemsHeader: FunctionComponent<ActionItemsHeaderProps> = ({
  pathId,
  resultId,
  resultType,
  results,
  onCompletedCLick,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [result, setResult] = useState<ActionItemDescriptor | null | undefined>(
    null
  );
  const [showButton, setShowButton] = useState(false);
  const [nextCode, setNextCode] = useState<string | null>(null);
  const { screenDimensions, globalDispatch } = useStateValue();

  useEffect(() => {
    if (resultId && resultType === ResultType.RESULT) {
      const index = results.findIndex((r) => r.result.code === resultId);
      setResult(results[index]);
      setShowButton(true);
      if (index !== results.length - 1) {
        setNextCode(results[index + 1].result.code);
      } else {
        setNextCode(null);
      }
    } else {
      setResult(null);
      setShowButton(false);
      setNextCode(null);
    }
  }, [results, resultId, resultType]);

  const handleButtonClick = () => {
    if (result) {
      onCompletedCLick(result.result, !result.checkupResult.completed);
    }
  };

  const locationSplit = location.pathname.split('/');

  let buttonType = ActionItemHeaderButtonType.OUTLINED;
  let buttonText = 'MARK COMPLETE';
  if (result && result.checkupResult.completed) {
    buttonType = ActionItemHeaderButtonType.COMPLETED;
    buttonText = 'COMPLETED';
  }

  let backGroupText;
  let backGroupLink;

  if (!resultType) {
    backGroupText = 'PATH';
    backGroupLink = `/path/${pathId}`;
  } else if (resultType === ResultType.TASK) {
    const found = results.find((r) => r.result.code === resultId);
    if (found) {
      const limit = 30;
      const title =
        found.result.title.length < limit
          ? found.result.title
          : `${found.result.title.substring(0, limit)}...`;
      backGroupText = `BACK TO ${title}`;
    } else {
      backGroupText = 'ACTION ITEM';
    }
    backGroupLink = `/${locationSplit[1]}/${locationSplit[2]}/result/${resultId}`;
  } else {
    backGroupText = screenDimensions?.isMobile ? 'LIST' : 'TASK LIST';
    backGroupLink = `/${locationSplit[1]}/${locationSplit[2]}`;
  }

  const backgroundColor = screenDimensions?.isMobile ? '#2a5371' : '#fbfbfb';

  return (
    <BodyGroup
      className={classes.actionItemsHeader}
      background={backgroundColor}
    >
      <div className={classes.headerInner}>
        <div className={classes.headerLeft}>
          <ActionItemButton
            text={backGroupText}
            type={
              screenDimensions?.isMobile
                ? ActionItemHeaderButtonType.MOBILE
                : ActionItemHeaderButtonType.OUTLINED
            }
            onClick={() => history.push(backGroupLink)}
            showArrow
          />
        </div>
        <div className={classes.headerCenter}>
          {showButton && (
            <ActionItemButton
              text={buttonText}
              type={buttonType}
              onClick={handleButtonClick}
              showCheck
            />
          )}
        </div>
        <div className={classes.headerRight}>
          {nextCode && (
            <div className={classes.headerRightInner}>
              <ActionItemButton
                text="NEXT"
                showArrowEnd={screenDimensions?.isMobile}
                type={
                  screenDimensions?.isMobile
                    ? ActionItemHeaderButtonType.MOBILE
                    : ActionItemHeaderButtonType.BLUE
                }
                onClick={() =>
                  history.push(
                    `/${locationSplit[1]}/${locationSplit[2]}/result/${nextCode}`
                  )
                }
              />
            </div>
          )}
        </div>
      </div>
    </BodyGroup>
  );
};

export default ActionItemsHeader;
