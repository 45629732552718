import { Dispatch, Reducer, useEffect, useReducer, useState } from 'react';
import axiosInst, { axiosWrapper } from '../common/axios';
import {
  EventType,
  GlobalAction,
  GlobalActionType,
  LoginState,
  RegisterUserRequest,
  User,
} from '../common/types';
import { useStateValue } from '../hoc/state';
import { AxiosResponse } from 'axios';
import useDashboardApi from './dashboard-apis';

export interface LoginAction {
  type: LoginActionType;
  userData?: { email: string; password: string };
}

export enum LoginActionType {
  LOGIN_INIT,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
}

const loginReducer = (state: LoginState, action: LoginAction) => {
  switch (action.type) {
    case LoginActionType.LOGIN_INIT:
      return { ...state, isLoading: true };
    case LoginActionType.LOGIN_SUCCESS:
      return { ...state, isLoading: false };
    case LoginActionType.LOGIN_FAILURE:
      return { ...state, isLoading: false };
    default:
      throw new Error();
  }
};

export const loginSuccessCallback = (
  resp: AxiosResponse<User>,
  globalDispatch: Dispatch<GlobalAction>
) => {
  const user = resp.data;
  sessionStorage.setItem('token', user.token);
  sessionStorage.setItem('refreshToken', user.refreshToken);
  globalDispatch({
    payload: {
      user,
    },
    type: GlobalActionType.LOGIN_SUCCESS,
  });
  globalDispatch({
    payload: {
      event: {
        type: EventType.LOGIN,
        data: {
          email: user.email,
        },
      },
    },
    type: GlobalActionType.TRACK_EVENT,
  });
};

export const useLoginApi = () => {
  const { globalDispatch } = useStateValue();
  const [userData, setUserData] = useState<any>();
  const [error, setError] = useState<string>();
  const { fetchDashboardsAll } = useDashboardApi();

  const [state, dispatch] = useReducer<Reducer<LoginState, LoginAction>>(
    loginReducer,
    {
      isLoading: false,
    }
  );

  const doLogin = (email: string, password: string) => {
    setUserData({ email, password });
  };

  const loginFailureCallback = (e) => {
    dispatch({ type: LoginActionType.LOGIN_FAILURE });
  };

  useEffect(() => {
    if (userData) {
      dispatch({ type: LoginActionType.LOGIN_INIT });
      axiosWrapper<User>(
        axiosInst.post<User>('authenticate', userData),
        (resp) => {
          loginSuccessCallback(resp, globalDispatch);
          fetchDashboardsAll();
        },
        (e, errorMessage) => {
          setError('Invalid User or Password');
          loginFailureCallback(e);
        }
      );
    }
  }, [userData]);

  return { state, doLogin, error };
};

export const acceptedTerms = (globalDispatch) => {
  axiosWrapper(
    axiosInst.get('my/terms'),
    (resp) => {
      const acceptTermsAction: GlobalAction = {
        type: GlobalActionType.TERMS_ACCEPTED,
        payload: {},
      };
      const updateModalAction: GlobalAction = {
        type: GlobalActionType.UPDATE_MODAL,
        payload: { modal: { on: false, content: null } },
      };
      globalDispatch(acceptTermsAction);
      globalDispatch(updateModalAction);
    },
    (error, errorMessage) => {}
  );
};

export const loginWithRefreshToken = (
  token: string,
  globalDispatch: Dispatch<GlobalAction>
) => {
  const userData: any = {};
  userData.refreshToken = token;

  axiosWrapper(
    axiosInst.post('authenticate', userData),
    (resp: AxiosResponse<User>) => loginSuccessCallback(resp, globalDispatch),
    (error, errorMessage) => {
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('refreshToken');
      globalDispatch({
        type: GlobalActionType.CLEAR_USER,
        payload: {},
      });
      globalDispatch({
        type: GlobalActionType.AUTH_VERIFIED,
        payload: {},
      });
    }
  );
};

export const verifyVerificationCode = async (
  email: string,
  code: string
): Promise<AxiosResponse<User>> => {
  const response = await axiosInst.post<User>(
    '/register/validate/verification-code',
    { email, code }
  );
  return response;
};

export const resetPasswordPinGen = async (email: string): Promise<any> => {
  const response = await axiosInst.post<any>(
    'register/verification-code-for-pw-reset',
    { email }
  );
  return response.data;
};

export const resetPassword = async (
  email: string,
  code: string,
  password: string
): Promise<any> => {
  const response = await axiosInst.post<any>('register/pw-reset', {
    email,
    code,
    password,
  });
  return response.data;
};

export const registerUser = async (
  registerRequest: RegisterUserRequest
): Promise<any> => {
  const response = await axiosInst.post<any>('/register', registerRequest);
  return response.data;
};

export const isDuplicateEmail = async (email: string): Promise<boolean> => {
  const response = await axiosInst.post<boolean[]>('/register/validate/email', {
    email,
  });

  return response.data[0];
};

export const fetchIPAuthCheck = async (): Promise<any> => {
  const response = await axiosInst.get<any>('/register/validate/ip-check');
  return response.data;
};

export const syncEvents = (events, globalDispatch: Dispatch<GlobalAction>) => {
  if (events) {
    axiosWrapper(
      axiosInst.post<any>('my/events', events),
      (resp) => {},
      (error, errorMessage) => {}
    );
  }
  globalDispatch({ type: GlobalActionType.SYNCED_EVENTS, payload: {} });
};
