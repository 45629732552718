import React from 'react';
import './App.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { StateProvider } from './hoc/state';
import AuthenticatedLayout from './hoc/AuthenticatedLayout';

import RegistrationProvider from './containers/registration/registration-provider';
import ResetPassword from './containers/registration/reset-password';
import WheelContainer from './containers/wheel/wheelContainer';
import PublicLayout from './containers/layout/public-layout';
import Login from './containers/auth/login/login';
import Org from './hoc/Org';
import Landing from './containers/landing/landing';
import Modal from './components/UI/modal/modal';
import ModalGlobal from './components/UI/modal/modal-global';
import NotFound from './containers/auth/errors/not-found';
import ResultViewer from './containers/result/result';
import ActionItemGroup from './containers/action-items/action-item-group';
import TaskSearch from './containers/searchResult/task-search';
import CustomThemeProvider from './theme/theme';

const routes = [
  { path: '/', exact: true, Component: Login, Layout: PublicLayout },
  { path: '/login', Component: Login, Layout: PublicLayout },
  { path: '/register', Component: RegistrationProvider, Layout: PublicLayout },
  { path: '/reset-password', Component: ResetPassword, Layout: PublicLayout },
  {
    path: '/dashboard',
    Component: Landing,
    Layout: AuthenticatedLayout,
  },
  {
    path: '/path/:pathId',
    Component: WheelContainer,
    Layout: AuthenticatedLayout,
  },
  {
    path: '/search-results',
    Component: TaskSearch,
    Layout: AuthenticatedLayout,
  },
  { path: '/wheel', Component: WheelContainer, Layout: AuthenticatedLayout },
  {
    path: '/action-items/:checkupCode/:resultType?',
    Component: ActionItemGroup,
    Layout: AuthenticatedLayout,
  },
  {
    path: '/result/:resultCode',
    Component: ResultViewer,
    Layout: AuthenticatedLayout,
  },
  {
    path: '/task/:resultCode',
    Component: ResultViewer,
    Layout: AuthenticatedLayout,
  },
];

const App = () => {
  return (
    <BrowserRouter>
      <StateProvider>
        <Org />
        <CustomThemeProvider>
          <Switch>
            {routes.map(({ path, exact, Layout, Component }) => (
              <Route
                key={0}
                exact={!!exact}
                path={path}
                render={(props) => {
                  return (
                    <>
                      {Layout ? (
                        <Layout {...props}>
                          <Component {...props} />
                        </Layout>
                      ) : (
                        <Component {...props} />
                      )}
                      <Modal />
                      <ModalGlobal />
                    </>
                  );
                }}
              />
            ))}
            <Route component={NotFound} />
          </Switch>
        </CustomThemeProvider>
      </StateProvider>
    </BrowserRouter>
  );
};

export default App;
