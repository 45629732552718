import {
  Form,
  FormField,
  FormFieldValidationType,
  FormFieldValidator,
} from '../../../common/types';

const requiredValidator = (formField: FormField<any>): string | null => {
  let error: string | null = null;

  if (!formField.value) {
    error = 'Required';
  }
  return error;
};

const minLengthValidator = (
  formField: FormField<any>,
  values: any,
  form: Form
): string | null => {
  let error: string | null = null;

  if (formField.value && formField.value.length < values) {
    error = `Must be ${values} characters or more `;
  }
  return error;
};

const maxLengthValidator = (
  formField: FormField<any>,
  values: any,
  form: Form
): string | null => {
  let error: string | null = null;

  if (formField.value && formField.value.length > values) {
    error = `Must be ${values} characters or less`;
  }
  return error;
};

const fieldsMatchValidator = (
  formField: FormField<any>,
  values: any,
  form: Form
): string | null => {
  let error: string | null = null;

  if (formField.value !== form[values].value) {
    error = 'Needs to Match';
  }
  return error;
};

const emailValidator = (formField: FormField<any>): string | null => {
  let error: string | null = null;
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!re.test(formField.value)) {
    error = 'Please Enter a Valid Email';
  }

  return error;
};

export const REQUIRED: FormFieldValidator = {
  type: FormFieldValidationType.REQUIRED,
  validator: requiredValidator,
};

export const EMAIL: FormFieldValidator = {
  type: FormFieldValidationType.EMAIL,
  validator: emailValidator,
};

export const FIELDS_MATCH = (relatedName: string): FormFieldValidator => {
  return {
    type: FormFieldValidationType.FIELDS_MATCH,
    values: relatedName,
    validator: fieldsMatchValidator,
  };
};

export const MIN_LENGTH = (min: number): FormFieldValidator => {
  return {
    type: FormFieldValidationType.MIN_LENGTH,
    values: min,
    validator: minLengthValidator,
  };
};

export const MAX_LENGTH = (min: number): FormFieldValidator => {
  return {
    type: FormFieldValidationType.MAX_LENGTH,
    values: min,
    validator: maxLengthValidator,
  };
};

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null;
}

export const validateFormField = (
  formField: FormField<any>,
  form: Form
): FormField<any> => {
  let errors: string[] = [];
  if (formField.validationTypes) {
    errors = formField.validationTypes
      .map((vt) => vt.validator(formField, vt.values, form))
      .filter(notEmpty);
  }
  return { ...formField, error: errors.length ? errors.join(', ') : null };
};

export const validateForm = (form: Form): Form => {
  return Object.keys(form)
    .map((key) => {
      return { key, formField: validateFormField(form[key], form) };
    })
    .reduce((prev, { key, formField }) => {
      return { ...prev, [key]: formField };
    }, {});
};

export const isValid = (form: Form): boolean => {
  return !Object.keys(form).find((k) => form[k].error != null);
};
