import React, { FunctionComponent } from 'react';
import {
  DraftJsBlock,
  DraftJsEntityMap,
  EntityRange,
  InlineStyleRange,
} from '../../common/types';
import { match, RouteComponentProps, withRouter } from 'react-router-dom';
import Definition from '../UI/definition';
import {
  buildInternalLink,
  getCodeFromUrl,
  isDefinitionLink,
  isImageLink,
  isInternalLink,
  isProfileLink,
} from '../../common/draftJsUtils';
import { Location } from 'history';
import Profile from '../UI/profile';
import { makeStyles } from '@material-ui/core';
import ResultLink from '../../containers/module-viewer/result-panel/result-link';

const useStyles = makeStyles((theme) => ({
  imageSize: {
    height: '100%',
    width: '100%',
  },
}));

interface UniversalStyledTextProps {
  block: DraftJsBlock;
  entityMap: DraftJsEntityMap;
}

const buildStyleRanges = (
  inlineRanges: InlineStyleRange[],
  entityRanges: EntityRange[]
): number[] => {
  const rangeSet = new Set<number>();
  inlineRanges.forEach((r) => {
    rangeSet.add(r.offset);
  });
  entityRanges.forEach((r) => {
    rangeSet.add(r.offset);
  });
  return Array.from(rangeSet).sort((a, b) => a - b);
};

const findUrlByKey = (key: string, entityMap: DraftJsEntityMap): string => {
  return entityMap[key].data.url;
};

const buildExternalLink = (text: string, url: string, range: EntityRange) => {
  return (
    <ResultLink
      url={url}
      text={text.substr(range.offset, range.length)}
      external
    />
  );
};

const buildDefinitionLink = (text: string, url: string, range: EntityRange) => {
  const code = getCodeFromUrl(url);
  return (
    <Definition text={text.substr(range.offset, range.length)} code={code} />
  );
};

const buildProfileLink = (text: string, url: string, range: EntityRange) => {
  const code = getCodeFromUrl(url);
  return <Profile text={text.substr(range.offset, range.length)} code={code} />;
};

const buildImageLink = (text: string, url: string, range: EntityRange) => {
  const { imageSize } = useStyles();
  const code = getCodeFromUrl(url);
  return (
    <img
      className={imageSize}
      src={`https://legal-gps-images.s3.us-east-2.amazonaws.com/${code}`}
    />
  );
};

const buildLinks = (
  text: string,
  range: EntityRange,
  entityMap: DraftJsEntityMap,
  match: match<{}>,
  location: Location
) => {
  let url = findUrlByKey(`${range.key}`, entityMap);

  if (isInternalLink(url)) {
    return buildInternalLink(
      text.substr(range.offset, range.length),
      url,
      match,
      location
    );
  } else if (isProfileLink(url)) {
    return buildProfileLink(text, url, range);
  } else if (isDefinitionLink(url)) {
    return buildDefinitionLink(text, url, range);
  } else if (isImageLink(url)) {
    return buildImageLink(text, url, range);
  }
  return buildExternalLink(text, url, range);
};

const buildElements = (
  text: string,
  ranges: number[],
  inlineRanges: InlineStyleRange[],
  entityRanges: EntityRange[],
  entityMap: DraftJsEntityMap,
  match: match<{}>,
  location: Location
) => {
  const elements: any[] = [];
  let start = 0;
  for (const range of ranges) {
    if (range > start) {
      elements.push(text.substr(start, range - start));
    }
    entityRanges
      .filter((r) => r.offset === range)
      .forEach((r) => {
        elements.push(buildLinks(text, r, entityMap, match, location));
        const end = r.offset + r.length;
        if (start < end) {
          start = end;
        }
      });
  }
  if (ranges.length > 0 && start !== text.length) {
    elements.push(text.substr(start));
  }

  return elements;
};

const UniversalStyledText: FunctionComponent<
  RouteComponentProps & UniversalStyledTextProps
> = ({ location, match, block, entityMap }) => {
  const ranges = buildStyleRanges(block.inlineStyleRanges, block.entityRanges);

  return (
    <>
      {ranges.length > 0 ? (
        buildElements(
          block.text,
          ranges,
          block.inlineStyleRanges,
          block.entityRanges,
          entityMap,
          match,
          location
        ).map((el, idx) => {
          return (
            <React.Fragment key={`${block.key}text${idx}`}>
              <span>{el}</span>
            </React.Fragment>
          );
        })
      ) : (
        <span>{block.text}</span>
      )}
    </>
  );
};

export default withRouter(UniversalStyledText);
