import React, { FunctionComponent, memo } from 'react';
import { makeStyles } from '@material-ui/core';
import { ResultVideoProps } from '../../../common/types';
import ReactPlayer from 'react-player';

const useStyles = makeStyles((theme) => ({
  content: {
    paddingBottom: '1rem',
  },
  playerWrapper: {
    position: 'relative',
    paddingTop: '56.25%',
  },
  reactPlayer: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
  },
}));

const ResultVideo: FunctionComponent<ResultVideoProps> = ({
  video,
  videoExternal,
}) => {
  const url = !!video
    ? `https://legalgps.wistia.com/medias/${video}`
    : videoExternal;

  const classes = useStyles();
  return (
    <div className={classes.content}>
      <div className={classes.playerWrapper}>
        <div className={classes.reactPlayer}>
          <ReactPlayer url={url} controls={true} width="100%" height="100%" />
        </div>
      </div>
    </div>
  );
};

export default memo(ResultVideo);
