import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core';
import {
  CompletionTimeOverlayProps,
  CustomBreakPoints,
} from '../../../common/types';

const useStyles = makeStyles((theme) => ({
  compTimeWrapper: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    bottom: 0,
    right: 0,
    zIndex: 10,
  },
  compTime: {
    padding: '.2rem .5rem .2rem .5rem',
    backgroundColor: '#347cb1',
    display: 'inline-flex',
    color: '#fff',
    fontSize: '.8rem',
    [theme.breakpoints.down(CustomBreakPoints.mobile)]: {
      fontSize: '.7rem',
    },
  },
}));

const CompletionTimeOverlay: FunctionComponent<CompletionTimeOverlayProps> = ({
  time,
  className,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.compTimeWrapper}>
      <div className={classes.compTime + (className ? ' ' + className : '')}>
        {time} min
      </div>
    </div>
  );
};

export default CompletionTimeOverlay;
